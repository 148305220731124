import React, { useState } from "react";
import {
  FaBackspace,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import toucanLogo from "../assets/web_logo_toucan.png";
import { Dialog } from "primereact/dialog";
import IletisimPage from "./IletisimPage";
import { useSelector } from "react-redux";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [mapVisible, setMapVisible] = useState(false);
  const { webData } = useSelector((state) => state.sacomAuth);

  return (
    <footer id="footer">
      <div className="footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="text-white text-center pb-4 cursor-pointer"
                onClick={() => setMapVisible(true)}
              >
                <h2>İletişim</h2>
                <h6>{webData && webData.iletisim && webData.iletisim.adres}</h6>
                <h5 className="text-white">
                  {webData &&
                    webData.iletisim &&
                    webData.iletisim.ilce + " / " + webData.iletisim.il}
                </h5>
                <h5 className="text-white">
                {webData &&
                    webData.iletisim &&
                    webData.iletisim.telefon}
                </h5>
              </div>
              <div className="footer_social pt-3 text-center">
                {webData.socialMedia && webData.socialMedia.facebookUrl && (
                  <Link to={webData.socialMedia.facebookUrl} target="_blank">
                    <FaFacebook
                      className="footer_social_icon"
                      title={webData.socialMedia.facebookUrl}
                    />
                  </Link>
                )}
                {webData.socialMedia && webData.socialMedia.instagramUrl && (
                  <Link to={webData.socialMedia.instagramUrl} target="_blank">
                    <FaInstagram
                      className="footer_social_icon"
                      title={webData.socialMedia.instagramUrl}
                    />
                  </Link>
                )}
                {webData.socialMedia && webData.socialMedia.twitterUrl && (
                  <Link to={webData.socialMedia.twitterUrl} target="_blank">
                    <FaTwitter
                      className="footer_social_icon"
                      title={webData.socialMedia.twitterUrl}
                    />
                  </Link>
                )}
                {webData.socialMedia && webData.socialMedia.youtubeUrl && (
                  <Link to={webData.socialMedia.youtubeUrl} target="_blank">
                    <FaYoutube
                      className="footer_social_icon"
                      title={webData.socialMedia.youtubeUrl}
                    />
                  </Link>
                )}
                {webData.socialMedia && webData.socialMedia.linkedinURL && (
                  <Link to={webData.socialMedia.linkedinURL} target="_blank">
                    <FaLinkedin
                      className="footer_social_icon"
                      title={webData.socialMedia.linkedinURL}
                    />
                  </Link>
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <hr className="my-1 text-white mt-2" />
              <div className="container">
                <div className="text-white footer_copy">
                  <div className="col footer_bolum">
                    Avukat {webData && webData.setup && webData.setup.adiSoyadi}{" "}
                    &copy; {currentYear}
                  </div>
                  <div className="col footer_bolum">
                    <span>
                       || Created by{" "}
                      <a
                        href="https://www.linkedin.com/in/szaferulgur/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fenerbahce">SZÜ</span>
                      </a>{" "}
                      <img
                        className="cursor-pointer"
                        src={toucanLogo}
                        alt="Serhat Zafer Ülgür React JS Node JS Web Uygulaması Türkiye Ankara Osmaniye Software Developer"
                        style={{ width: "25px" }}
                        onClick={() =>
                          window.open(
                            "https://github.com/SZaferUlgur",
                            "_blank"
                          )
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header="Haritalı İletişim Bilgilerimiz"
        headerStyle={{ textAlign: "center" }}
        visible={mapVisible}
        style={{ width: "50vw" }}
        onHide={() => setMapVisible(false)}
        maximized
      >
         <div className="container">
          <button
            className="btn btn-outline-secondary"
            onClick={() => setMapVisible(false)}
          >
            <FaBackspace /> Geri Dön
          </button>
        </div>
        <IletisimPage />
      </Dialog>
      <div className="hide_menu">
        <h1>avukat</h1>
        <h1>çanakkale</h1>
        <h1>hukuk</h1>
        <h1>miras</h1>
        <h1>davası</h1>
        <h1>en iyi</h1>
        <h1>tapu</h1>
        <h1>tescil</h1>
        <h1>kadastro</h1>
        <h1>medeni</h1>
        <h1>boşanma</h1>
        <h1>usül</h1>
        <h1>velayet</h1>
        <h1>nafaka</h1>
        <h1>tedbir</h1>
        <h1>ihtiyadi</h1>
        <h1>haciz</h1>
        <h1>tazminatı</h1>
        <h1>manevi</h1>
        <h1>maddi</h1>
        <h1>cezai</h1>
        <h1>şart</h1>
        <h1>sözleşme</h1>
        <h1>arabulucu</h1>
        <h1>uzlaştırma</h1>
        <h1>izalei</h1>
        <h1>şüyuu</h1>
        <h1>ortaklığın</h1>
        <h1>giderilemsi</h1>
        <h1>satış</h1>
        <h1>suretiyle</h1>
        <h1>kira</h1>
        <h1>kiracılık</h1>
        <h1>kontratı</h1>
        <h1>alım</h1>
        <h1>satım</h1>
        <h1>trampa</h1>
        <h1>takas</h1>
        <h1>beyanı</h1>
        <h1>reddi</h1>
        <h1>mahrum</h1>
        <h1>bırakılma</h1>
        <h1>ıskat</h1>
        <h1>soybağı</h1>
        <h1>nesep</h1>
        <h1>babalık</h1>
        <h1>iptal</h1>
        <h1>tam yargı</h1>
        <h1>belgesinin</h1>
        <h1>alınamsı</h1>
        <h1>tahliye</h1>
        <h1>uyuşmazlık</h1>
        <h1>kıta</h1>
        <h1>mülkiyeti</h1>
        <h1>vesayet</h1>
        <h1>kayum</h1>
        <h1>kayyum</h1>
        <h1>yönetici</h1>
        <h1>temsil</h1>
        <h1>kişilik</h1>
        <h1>altına</h1>
        <h1>sona</h1>
        <h1>atanma</h1>
        <h1>seçer</h1>
        <h1>hakim</h1>
        <h1>hakimi</h1>
        <h1>terek</h1>
        <h1>tenkis</h1>
        <h1>şirketi</h1>
        <h1>mümessil</h1>
        <h1>defter</h1>
        <h1>artırım</h1>
        <h1>tespit</h1>
        <h1>tevdi</h1>
        <h1>mahal</h1>
        <h1>tayini</h1>
        <h1>alacak</h1>
        <h1>menfi</h1>
        <h1>müspet</h1>
        <h1>olası</h1>
        <h1>muhtemel</h1>
        <h1>mevcut</h1>
        <h1>önalım</h1>
        <h1>ger</h1>
        <h1>istirdat</h1>
        <h1>rücü</h1>
        <h1>ödenenin</h1>
        <h1>geri</h1>
        <h1>istenmesi</h1>
        <h1>teslim</h1>
        <h1>haksız</h1>
        <h1>fiil</h1>
        <h1>kötüniyet</h1>
        <h1>ispatı</h1>
        <h1>iyiniyetin</h1>
        <h1>suistimali</h1>
        <h1>ayıplı</h1>
        <h1>iadesi</h1>
        <h1>misliyle</h1>
        <h1>değiştirilmesi</h1>
        <h1>ayıba</h1>
        <h1>karşı</h1>
        <h1>tekeffül</h1>
        <h1>tefekkülün</h1>
        <h1>semen</h1>
        <h1>iadesi</h1>
        <h1>bedelin</h1>
        <h1>tezyidi</h1>
        <h1>nüfus</h1>
        <h1>kadının</h1>
        <h1>kağıdının</h1>
        <h1>düzeltimesi</h1>
        <h1>isim</h1>
        <h1>değiştirme</h1>
        <h1>yaş</h1>
        <h1>kazi</h1>
        <h1>rüşt</h1>
        <h1>evlenme</h1>
        <h1>delil</h1>
        <h1>istemesi</h1>
        <h1>el</h1>
        <h1>kamulaştırmasız</h1>
        <h1>müdahele</h1>
        <h1>meni</h1>
        <h1>bedeli</h1>
        <h1>tespiti</h1>
        <h1>ipka</h1>
        <h1>muvazaa</h1>
        <h1>vasiyetname</h1>
        <h1>edilir</h1>
        <h1>mi</h1>
        <h1>vasinin</h1>
        <h1>görevleri</h1>
        <h1>azli</h1>
        <h1>değiştirilmesi</h1>
        <h1>iade</h1>
        <h1>şufa</h1>
        <h1>önalım</h1>
        <h1>vefa</h1>
        <h1>iştira</h1>
        <h1>geçit</h1>
        <h1>irtifakı</h1>
        <h1>ecri</h1>
        <h1>ortaklığı</h1>
        <h1>hisse</h1>
        <h1>tüzüğü</h1>
        <h1>ana</h1>
        <h1>kambiyo</h1>
        <h1>senedi</h1>
        <h1>senedine</h1>
        <h1>dayalı</h1>
        <h1>icrası</h1>
        <h1>unsurları</h1>
        <h1>vasfı</h1>
        <h1>bono</h1>
        <h1>takibi</h1>
        <h1>değerli</h1>
        <h1>kağıt</h1>
        <h1>kıymetli</h1>
        <h1>evrak</h1>
        <h1>çek</h1>
        <h1>senet</h1>
        <h1>tahakkukta</h1>
        <h1>paranın</h1>
        <h1>işçi</h1>
        <h1>kıdem</h1>
        <h1>yıllık</h1>
        <h1>izin</h1>
        <h1>milli</h1>
        <h1>dini</h1>
        <h1>günlere</h1>
        <h1>mesai</h1>
        <h1>ihtar</h1>
        <h1>akdinin</h1>
        <h1>feshi</h1>
        <h1>rücu</h1>
        <h1>avukat</h1>
        <h1>ceza</h1>
        <h1>en</h1>
        <h1>iyi</h1>
        <h1>Çanakkale</h1>
        <h1>hukuk</h1>
        <h1>miras</h1>
        <h1>boşanma</h1>
        <h1>aile</h1>
        <h1>Biga</h1>
        <h1>nafaka</h1>
        <h1>ecrimisil</h1>
        <h1>haksız</h1>
        <h1>işgal</h1>
        <h1>tazminatı</h1>
        <h1>tedbir</h1>
        <h1>ihtiyadi</h1>
        <h1>haciz</h1>
        <h1>ticari</h1>
        <h1>alacak</h1>
        <h1>adi</h1>
        <h1>fatura</h1>
        <h1>çek</h1>
        <h1>bono</h1>
        <h1>sebepsiz</h1>
        <h1>zenginleşme</h1>
        <h1>şirket</h1>
        <h1>çocuk</h1>
        <h1>zaman</h1>
        <h1>aşımı</h1>
        <h1>istinaf</h1>
        <h1>temyiz</h1>
        <h1>itiraz</h1>
        <h1>iptali</h1>
        <h1>sözleşme</h1>
        <h1>mahkeme</h1>
        <h1>vekalet</h1>
        <h1>ücreti</h1>
        <h1>baro</h1>
        <h1>kararına</h1>
        <h1>kamulaştırma</h1>
        <h1>bedeli</h1>
        <h1>tezyidi</h1>
        <h1>kamulaştırmasız</h1>
        <h1>el</h1>
        <h1>acele</h1>
        <h1>önlenmesi</h1>
        <h1>vasiyetin</h1>
        <h1>vasi</h1>
        <h1>atanması</h1>
        <h1>ziynet</h1>
        <h1>takı</h1>
        <h1>mal</h1>
        <h1>rejimi</h1>
        <h1>katkı</h1>
        <h1>payı</h1>
        <h1>sebepleri</h1>
        <h1>özel</h1>
        <h1>aldatma</h1>
        <h1>sadakatsizlik</h1>
        <h1>şiddetli</h1>
        <h1>geçimsizlik</h1>
        <h1>evlilik</h1>
        <h1>birliğinin</h1>
        <h1>temelinden</h1>
        <h1>sarsılması</h1>
        <h1>pek</h1>
        <h1>fena</h1>
        <h1>muamele</h1>
        <h1>cana</h1>
        <h1>kast</h1>
        <h1>hayasızca</h1>
        <h1>hayat</h1>
        <h1>sürme</h1>
        <h1>adli</h1>
        <h1>yardım</h1>
        <h1>nasıl</h1>
        <h1>alınır</h1>
        <h1>mülkiyet</h1>
        <h1>iddiası</h1>
        <h1>tescil</h1>
        <h1>muvaaza</h1>
        <h1>hiffet</h1>
        <h1>müzayaka</h1>
        <h1>tescile</h1>
        <h1>zorlama</h1>
        <h1>boşanmadan</h1>
        <h1>sonra</h1>
        <h1>hakların</h1>
        <h1>zilyet</h1>
        <h1>alacağı</h1>
        <h1>eğitim</h1>
        <h1>yardımı</h1>
        <h1>yüksek</h1>
        <h1>öğrenim</h1>
        <h1>babanın</h1>
        <h1>kızına</h1>
        <h1>torunla</h1>
        <h1>kişisel</h1>
        <h1>ilişki</h1>
        <h1>kurulması</h1>
        <h1>yargıtayın</h1>
        <h1>bozması</h1>
        <h1>kaldırması</h1>
        <h1>düzeltmesi</h1>
        <h1>emsal</h1>
        <h1>daire</h1>
        <h1>içtihat</h1>
        <h1>düzeltme</h1>
        <h1>kamu</h1>
        <h1>yararına</h1>
        <h1>adalet</h1>
        <h1>bakanlığı</h1>
        <h1>hakimi</h1>
        <h1>savcı</h1>
        <h1>takipsizlik</h1>
        <h1>kovuşturmaya</h1>
        <h1>olmadığına</h1>
        <h1>dair</h1>
        <h1>usulsüzlük</h1>
        <h1>görevi</h1>
        <h1>ihmal</h1>
        <h1>suiistimal</h1>
        <h1>kocayı</h1>
        <h1>iş</h1>
        <h1>cinayet</h1>
        <h1>dolandırıcılık</h1>
        <h1>yağma</h1>
        <h1>adam</h1>
        <h1>kaçırma</h1>
        <h1>tecavüz</h1>
        <h1>cinsel</h1>
        <h1>taciz</h1>
        <h1>sarkıntılık</h1>
        <h1>hakaret</h1>
        <h1>tehdit</h1>
        <h1>sövme</h1>
        <h1>ihbar</h1>
        <h1>suçluyu</h1>
        <h1>evrakta</h1>
        <h1>sahtecilik</h1>
        <h1>resmi</h1>
        <h1>nitelikte</h1>
        <h1>gasp</h1>
        <h1>hırsızlık</h1>
        <h1>banka</h1>
        <h1>dolandırıcılığı</h1>
        <h1>kredi</h1>
        <h1>kartı</h1>
        <h1>banka</h1>
        <h1>hakim</h1>
        <h1>savcı</h1>
        <h1>kararına</h1>
        <h1>itiraz</h1>
        <h1>kovuşturmaya</h1>
        <h1>yer</h1>
        <h1>usulsüzlük</h1>
        <h1>görevi</h1>
        <h1>ihmal</h1>
        <h1>suç</h1>
        <h1>suiistimal</h1>
        <h1>kocayı</h1>
      </div>
    </footer>
  );
};

export default Footer;
