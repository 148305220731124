import { MUSTERI_URL } from '../constants';
import apiSlice from '../apiSlice';

export const musteriApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMusteris: builder.query({
      query: () => ({
        url: MUSTERI_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['tblmusteri'],
    }),
    getMusteriById: builder.query({
      query: (userID) => ({
        url: `${MUSTERI_URL}/getuser/${userID}`,
      }),
      keepUnusedDataFor: 5,
      invalidatesTags: ['tblmusteri'],
    }),
    createMusteri: builder.mutation({
      query: (data) => ({
        url: MUSTERI_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['tblmusteri'],
    }),
    deleteMusteriById: builder.mutation({
      query: (musteriID) => ({
        url: `${MUSTERI_URL}/${musteriID}`,
        method: 'DELETE',
      }),
      providesTags: ['tblmusteri'],
    }),
  }),
});

export const {
  useGetMusterisQuery,
  useGetMusteriByIdQuery,
  useCreateMusteriMutation,
  useDeleteMusteriByIdMutation,
} = musteriApiSlice;