import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { truncateWord } from "../Utility";
import { useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";

const CevapsizPage = () => {
  const navigate = useNavigate();
  const { aktifPageTable } = useSelector((state) => state.sacomAuth);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div className="orders">
      <div className="header">
        <i className="bx bx-receipt"></i>
        <h3>Cevaplanmayan Sorular</h3>
      </div>
      <table>
        <thead>
          <tr>
            <th>Tarih</th>
            <th>Kategori</th>
            <th>Başlık</th>
            <th>Ödeme Durumu</th>
          </tr>
        </thead>
        <tbody>
          {aktifPageTable && aktifPageTable.length > 0 && (
            <>
              {aktifPageTable.slice(first, first + rows).map((soru) => (
                <tr
                  key={soru.soruID}
                  onClick={() => navigate(`/app/notans/edit/${soru.soruID}`)}
                >
                  <td>{moment(soru.tarih).format("DD.MM.YYYY")}</td>
                  <td style={{ fontSize: "14px" }}>{soru.kategori}</td>
                  <td title={soru.baslik}>{truncateWord(soru.baslik, 20)}</td>
                  <td>
                    <span className="status pending">{soru.payState !== "success" ? "Bekliyor" : "Ödeme Ok"}</span>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      <div className="card">
        {aktifPageTable && aktifPageTable.length > 0 && (
          <Paginator
            first={first}
            rows={rows}
            totalRecords={aktifPageTable && aktifPageTable.length}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default CevapsizPage;
