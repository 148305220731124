import React, { useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUploadSoruImageMutation } from "../../redux/web/soruSlice";
import { useNavigate } from "react-router-dom";

const UploadDekont = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const { hukukUser, navID } = useSelector((state) => state.sacomAuth);
  const [uploadSoruImage ] = useUploadSoruImageMutation()
  const [dosya, setDosya] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "application/pdf" ||
        selectedFile.type === "application/msword" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setFile(URL.createObjectURL(selectedFile));
      setDosya(selectedFile);
    } else {
      toast.error("Lütfen bir JPEG, PNG, PDF veya DOC(x) dosyası seçin.");
      e.target.value = null;
    }
  };
  
  const uploadFileHandler = async () => {
    try {
      const soruID = navID.soruID;
      const userID = hukukUser.userID;
      const token = hukukUser && hukukUser.token;
      const dekont = "dekont"
      const formData = new FormData();
      formData.append("file", dosya);
      // Diğer verileri de formData'ya ekleyebilirsiniz, örneğin:
      formData.append("soruID", soruID);
      formData.append("userID", userID);
      formData.append("token", token);
      formData.append("dekont", dekont);
      // uploadSoruImage fonksiyonuna formData'yı doğrudan iletebilirsiniz.
      const res = await uploadSoruImage(formData).unwrap();
      toast.success(res.filePath && "Belge Başarıyla Yüklenmiştir");
      navigate(-1)
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="row col-12">
      <div className="col-12 row">
        <div className="col-lg-3 col-md-4 col-sm-5">
          <label htmlFor="baslik" className="form-label">
           <strong>Ödendi (EFT Dekontu) Belgesi</strong>
          </label>
        </div>
        <div className="col-lg-8 col-md-8 col-12">
          <input
            type="file"
            className="form-control"
            id="baslik"
            onChange={(e) => handleFileChange(e)}
            placeholder="Konu Başlığı..."
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-lg-2 col-md-4 col-12">
          <button
            type="button"
            className="btn btn-primary btn-block mt-1 odeme_belge_buton"
            disabled={!file}
            onClick={() => uploadFileHandler()}
          >
            <FaCloudUploadAlt /> Ödendi Belgesini Yükle
          </button>
        </div>
      </div>
      <div className="col-12 mt-2 justify-content-center d-flex">
          <section className="image-fluid">
            {file ? (
              <img
                src={file}
                alt={file}
                style={{
                  width: "280px",
                  objectFit: "contain",
                }}
              />
            ) : null}
          </section>
      </div>
    </div>
  );
};

export default UploadDekont;
