import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { FaGlobe, FaKey } from "react-icons/fa";
import buroLogo from "../assets/logo.webp";
import { useSelector } from "react-redux";
import { useUserCheckGetSoruByIDMutation, useUserCheckSoruTeyitMutation } from "../redux/auth/userApiSlice";
import {toast} from "react-toastify"
import ChangePassTeyit from "./ChangePassTeyit";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [teyitMesaj, setTeyitMesaj] = useState(false);
  const { webData } = useSelector((state) => state.sacomAuth);
  const [ userCheckGetSoruByID, {data: checkTable}] = useUserCheckGetSoruByIDMutation()
  const [userCheckSoruTeyit, {data: teyitTable}] = useUserCheckSoruTeyitMutation()
  const [pcScreen, setPCScreen] = useState(false)
  const [data, setData] = useState({
    mailText: t("genel.butonaTiklayin"),
    toMail: "",
    subjText: t("genel.sifreDegistirme"),
    confirmMailText: uuidv4(),
    captchaDeger: uuidv4(),
    teyit: ""
  });
  const {toMail, teyit} = data

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    setData((prevState) => ({
      ...prevState,
      toMail: values.email,
    }));
    return errors;
  };

  const formikMail = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleEmailSubmit = async () => {
    // check soru cevabı ?
    try {
      await userCheckSoruTeyit(data).unwrap();
      toast.success("Üyelik Cevabınız Kontrol Ediliyor", { autoClose: 5000 });
      setTeyitMesaj(true)
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if(teyitMesaj && teyitTable && teyitTable.message) {
      if(teyitTable && !teyitTable.isCheck) {
        toast.error(teyitTable.message)
      } else {
        toast.success(teyitTable.message)
        setPCScreen(true)  
      }
      setTeyitMesaj(false)
    }
  },[teyitMesaj, teyitTable])

  const mailKontrolSoruGetir = async () => {
    try {
      await userCheckGetSoruByID(data).unwrap();
      toast.success("Mail Kontrol Ediliyor", { autoClose: 5000 });
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="reset_pass">
      <section className="sifirlama">
        <div className="col-lg-4 col-md-4 col-12 text-center mb-3">
          <img src={buroLogo} alt="" style={{ width: "150px" }} />
        </div>
        <span className="col-lg-4 col-md-4 col-12 d-flex justify-content-center">
          {t("genelPage.sistemdeKayitli")}{" "}
          <span className="mavi">{t("genelPage.eMailinizi")}</span>{" "}
          {t("genelPage.giriniz")}
        </span>
        <hr className="my-2 col-lg-4 col-md-4 col-12" />
        <div className="col-lg-4 col-md-4 col-12 d-flex flex-row gap-1">
          <input
            type="text"
            id="email"
            className="form-control"
            placeholder="Sistemdeki Mail Adresiniz.."
            onChange={formikMail.handleChange}
            onBlur={formikMail.handleBlur}
            value={formikMail.values.email}
          />
          <button
            className="btn btn-outline-success col-3"
            onClick={() => mailKontrolSoruGetir()}
          >
            Soruyu Getir
          </button>
        </div>
        <div className="col">
          {formikMail.touched.email && formikMail.errors.email ? (
            <span
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                color: "red",
              }}
            >
              {t("genelPage.gecerliEMailGir")}
            </span>
          ) : null}
        </div>
        <div className="mt-2" style={{ display: `${checkTable && checkTable.isFound ? "" : "none"}` }}>
          <div className="sorunuz">
            <label className="mb-2" htmlFor="sorunuz">Üyelik Sorunuz : </label>
            <span>
              <strong>{checkTable && checkTable.soru}</strong>
            </span>
          </div>
          <div className="col col-lg-4 col-md-4 col-12">
            <input
              className="form-control"
              type="text"
              name="teyit"
              value={teyit}
              onChange={(e) => setData((prevState) => ({
                ...prevState,
                teyit: e.target.value,
              }))}
              placeholder="Cevabınız.."
            />
          </div>
        </div>
        <div className="d-flex flex-row gap-1 col-12">
          <button
            className="btn btn-secondary mt-2 col-lg-2 col-md-3 col-6"
            type="button"
            onClick={() => handleEmailSubmit()}
          >
            <FaKey /> {t("genelPage.cevapOnayla")}
          </button>
          <button
            className="btn btn-primary mt-2 col-lg-2 col-md-3 col-6"
            type="button"
            onClick={() => navigate("/")}
          >
            <FaGlobe /> {"Web Sayfasına Dönün"}
          </button>
        </div>
        <div className="pass-change-screen" style={{ display: `${pcScreen ? "" : "none"}`}}>
          <ChangePassTeyit email={toMail}/>
        </div>
        <div className="iletisim mt-3 col-lg-4 col-md-3 col-12 text-center">
          <h2>İletişim</h2>
          <h6>{webData && webData.iletisim && webData.iletisim.adres}</h6>
          <h5 className="text-white">
            {webData &&
              webData.iletisim &&
              webData.iletisim.ilce + " / " + webData.iletisim.il}
          </h5>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
