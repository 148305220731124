import React, { useEffect } from "react";
import { FaBackspace } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  dialogClose,
  payTrPayPage,
  sepetGuncelle,
} from "../../redux/auth/authSlice";
import { usePayTROdemePageMutation } from "../../redux/web/payTRSlice";
import payKartPNG from "../../assets/paytr.png";
import { useGetTutaryIdQuery } from "../../redux/web/fiyatSlice";
import Spinner from "../../web/Spinner";
import { useGetDovizsQuery } from "../../redux/web/dovizSlice";

const PayTROdeme = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  let soruID = params.id;
  const { payHtml } = useSelector((state) => state.sacomAuth);
  const { data: editTable, isLoading } = useGetTutaryIdQuery(soruID);
  const { data: dovizTable } = useGetDovizsQuery();
  const [
    payTROdemePage,
    { data: htmlData, isLoading: addLoading, error: addError },
  ] = usePayTROdemePageMutation();

  const odemePageGo = async () => {
    let email = editTable.email;
    let musteriID = editTable.musteriID;
    let soruID = editTable.soruID;
    let paraBirimi = "TL";
    let adiSoyadi = editTable.adiSoyadi;
    let adres = editTable.adres;
    let dil = editTable.dil;
    let telefon = editTable.telefon;
    let totalUsd = (
      parseFloat(editTable.tutar) / parseFloat(dovizTable.alisKuru)
    ).toFixed(2);
    let genelToplam = editTable.tutar;
    let urunAdi = editTable.baslik;
    let tutar = editTable.tutar;
    let miktar = 1;
    const customer = {
      musteriID,
      soruID,
      email,
      paraBirimi,
      adiSoyadi,
      adres,
      dil,
      telefon,
      totalUsd,
      genelToplam,
    };
    const sepet = { urunAdi, tutar, miktar };
    let data = { customer, sepet };
    try {
      await payTROdemePage(data).unwrap();
      dispatch(sepetGuncelle(data));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    if (addLoading || addError) {
      toast.success("Ödeme Sayfası Bekleniyor");
    } else {
      dispatch(dialogClose("hide"));
      navigate("/app/odeme/paypage");
    }
  };

  useEffect(() => {
    if (payHtml === "" && htmlData && htmlData.renderedHTML) {
      dispatch(payTrPayPage(htmlData.renderedHTML));
    }
  }, [payHtml, htmlData, dispatch]);

  return (
    <div className="paytr_odeme">
      {isLoading && <Spinner />}
      <button
        className="btn btn-outline-danger col-lg-2 col-md-3 col-sm-4"
        onClick={() => navigate(-1)}
      >
        <FaBackspace /> Geri Dön
      </button>
      <hr />
      <div className="d-flex flex-column">
        <div className="d-flex flex-row gap-2">
          <img
            src={payKartPNG}
            alt=""
            style={{ height: "18px" }}
            className="mt-1"
          />
          <h5>Banka Çekim İşlem Bilgileri</h5>
        </div>

        <hr className="my-2" />
        <div className="d-flex flex-column">
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              Adı Soyadı
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.adiSoyadi}</strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              TC Kimlik No
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.tckimlik}</strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              Soru Kategorisi
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.kategori}</strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              Soru Başlığı
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.baslik}</strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              Danışma Ücreti
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.tutar}</strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              Adresiniz
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.adres}</strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              İl / Ülke
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>
                {editTable && editTable.il} / {editTable && editTable.ülke}
              </strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              E-Posta
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.email}</strong>
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
              Telefonunuz
            </label>
            <span className="col-lg-9 col-md-6 col-8">
              <strong>{editTable && editTable.telefon}</strong>
            </span>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary btn-block mt-1 col-lg-3 col-md-6 col-12"
          onClick={() => odemePageGo()}
        >
          Ödemeyi Tamamla
        </button>
        <div className="mt-3">
          <span>
            <i>Not : </i>
            <strong>
              Uygulamamız kredi kartı bilgilerinizi asla kayıt etmez. Güvenle
              kullanabilirsiniz.
            </strong>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PayTROdeme;
