import React, { useEffect, useState } from "react";
import {
  FaCheck,
  FaKey,
  FaSignInAlt,
  FaTimesCircle,
  FaUserPlus,
} from "react-icons/fa";
import "./LoginPage.css";
import registerLogo from "../assets/register.png";
import rightLogo from "../assets/loginrightimg.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetSorularsQuery,
  useRegisterMutation,
} from "../redux/auth/userApiSlice";
import { setCredentials } from "../redux/auth/authSlice";
import { toast } from "react-toastify";
import Spinner from "../web/Spinner";

function LoginPage() {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  const [texti, setTexti] = useState("");
  const [deger, setDeger] = useState(false);
  const [cpatchaDeger, setCpatchaDeger] = useState(false);
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [hataMesaj, setHataMesaj] = useState("");
  const currentYear = new Date().getFullYear();
  const {
    data: soruTable,
    isLoading: soruLoading,
  } = useGetSorularsQuery();
  const [hukukUserData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    uyelikSorusu: "",
    cevap: "",
  });
  const { name, email, password, password2, uyelikSorusu, cevap } =
    hukukUserData;

  const [register, { data: regData, isLoading }] = useRegisterMutation();

  useEffect(() => {
    if (hukukUser) {
      if (
        !hukukUser.isSuccess &&
        hataMesaj !== hukukUser.message &&
        hukukUser.message !== ""
      ) {
        setHataMesaj(hukukUser.message);
      }
    }
  }, [hukukUser, hataMesaj]);

  useEffect(() => {
    if (!hukukUser) {
      document.title = "Av. Selami Akar " + currentYear;
    }
  }, [hukukUser, currentYear]);

  const onChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

    // E-posta kontrol fonksiyonu
    const validateEmail = (email) => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

  function subForm() {
    let randomNr1 = 0;
    let randomNr2 = 0;
    let totalNr = 0;
    randomNr1 = Math.floor(Math.random() * 99);
    randomNr2 = Math.floor(Math.random() * 10);
    totalNr = randomNr1 + randomNr2;
    setTexti(randomNr1 + " + " + randomNr2 + " = ");
    setCpatchaDeger(totalNr);
  }

  const onRegister = async () => {
    if (
      (!name !== "",
      !email !== "",
      !password !== "",
      !password2 !== "",
      !uyelikSorusu !== "",
      !cevap !== "")
    ) {
      if (password !== password2) {
        toast.error("Passwords do not match");
        return;
      }
      if (!validateEmail(email)) {
        toast.error("Please enter a valid email address");
        return;
      }
      if (parseInt(cpatchaDeger) !== parseInt(inputCaptcha)) {
        toast.error("Captcha Doğru Değil");
        return;
      }
      try {
        const data = { name, email, password, uyelikSorusu, cevap };
        const res = await register(data).unwrap();
        dispatch(setCredentials({ ...res }));
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    if (regData && regData.isRegister === false) {
      toast.error("Kayıt Sorunu Var..[ ; ? gibi karakterleri kullanmayınız..]" + regData.message);
    }
  }, [regData, navigate, t]);

  useEffect(() => {
    if (
      hukukUser &&
      hukukUser.isRegistered === true &&
      hukukUser.message === "User is Registered"
    ) {
      toast.success(
        "Kayıt Başarılı Şekilde Yapılmıştır. Lütfen Giriş Yapınız..", {autoClose: 5000}
      );
    }
  }, [hukukUser, navigate]);

  useEffect(() => {
    if(hukukUser && hukukUser.token) {
      navigate("/app")
    }
  },[hukukUser, navigate])

  if (!deger) {
    subForm();
    setDeger(true);
  }

  return (
    <div className="container">
      {soruLoading && <Spinner />}
      <div className="row g-0 align-items-center mt-3">
        <div className="col-lg-6 col-md-11 col-10 mt-5">
          <div className="mt-5 mb-5 cascading_right">
            <div className="p-5 shadow_5">
              <div className="d-flex col-12">
                <h2 className="fw-bold mb-3 col-7 text-center azgolgeli">
                  Uygulama Kayıt
                </h2>
                <button
                  className="btn btn-outline-danger col-5"
                  onClick={() => navigate("/")}
                >
                  Web Sitesine Dön
                </button>
              </div>
              <div></div>
              <img
                src={registerLogo}
                alt=""
                className="login_logo"
                style={{ width: "230px" }}
              />
              <div className="d-flex flex-row col-12 gap-1">
                <div className="col-12 mb-2">
                  <input
                    className="form-control"
                    label="First name"
                    id="form1"
                    type="text"
                    name="name"
                    value={name}
                    placeholder="Adınız Soyadınız"
                    onChange={onChange}
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <div className="mb-2">
                <input
                  className="form-control"
                  label="Email"
                  id="form3"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="name@domain.com"
                  autoComplete="new-password"
                  onChange={onChange}
                  required
                />
                {!validateEmail(email) && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    Lütfen Geçerli e-Posta Adresi Giriniz
                  </span>
                )}
              </div>
              <div className="mb-2">
                <input
                  className="form-control"
                  label="Password"
                  id="form4"
                  type="password"
                  name="password"
                  value={password}
                  autoComplete="new-password"
                  placeholder={t("authPage.sifre")}
                  onChange={onChange}
                  required
                />
                {password && password.length < 6 && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {t("authPage.enAzAlti")}
                  </span>
                )}
              </div>
              <div className="mb-2">
                <input
                  className="form-control"
                  type="password"
                  id="password2"
                  name="password2"
                  autoComplete="new-password"
                  value={password2}
                  placeholder={t("authPage.sifreOnay")}
                  onChange={onChange}
                  required
                />
                {password.length > 5 && password === password2 ? (
                  <FaCheck style={{ color: "green" }} />
                ) : (
                  <FaTimesCircle style={{ color: "red" }} />
                )}
                {password && password.length < 6 && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {t("authPage.enAzAlti")}
                  </span>
                )}
              </div>
              <div className="gizli-soru d-flex flex-column">
                <div className="custom-select col-lg-12 mb-2">
                  {soruTable && soruTable.length > 0 && (
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setUserData((prevState) => ({
                          ...prevState,
                          uyelikSorusu: e.target.value,
                        }))
                      }
                    >
                      <option
                        className="register-input"
                        value="em"
                        style={{ fontWeight: "600" }}
                        required
                      >
                        Gizli Sorunuzu Seçiniz..
                      </option>
                      {soruTable.map((soru, index) => (
                        <option
                          className="register-input"
                          key={index}
                          value={soru.soru}
                          required
                        >
                          {soru.soru}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <input
                className="form-control mb-2"
                  type="text"
                  name="cevap"
                  value={cevap}
                  placeholder="Cevabınızı Yazınız"
                  onChange={(e) =>
                    setUserData((prevState) => ({
                      ...prevState,
                      cevap: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="mb-2 d-flex flex-row justify-content-between">
              <div className="col-lg-3 col-md-4 col-6">
                  <label className="form-label mt-1" id="ebcaptchatext">
                  {t("authPage.captcha")} {texti}
                  </label>
                </div>
                <div className="col-lg-8 col-md-7 col-6">
                  <input
                    type="text"
                    className="form-control"
                    id="ebcaptchainput"
                    name="ebcaptchainput"
                    value={inputCaptcha}
                    onChange={(e) => setInputCaptcha(e.target.value)}
                    placeholder={t("authPage.captchaGir")}
                    required
                    />
                </div>
              </div>
              <button
                className="btn btn-outline-success btn-block mb-2"
                size="md"
                onClick={onRegister}
                disabled={isLoading}
              >
                <FaSignInAlt /> {t("button.kayitOl")}
              </button>
              <div className="d-flex justify-content-between mt-1 mb-1">
                <Link className="forget-pass" to="/reset">
                  <FaKey /> {t("authPage.sifremiUnuttum")}
                </Link>
                <Link className="login" to="/login">
                  <FaUserPlus /> {t("authPage.girisYapmakIcin")}
                </Link>
              </div>
              <div className="footer login_footer mt-4">
                <div className="text-white text-center">
                  <h4>Ankara Hukuk Bürosu</h4>
                  <div className="register_adres">
                    <hr className="my-2" />
                    <h6>İsmetpaşa Mahallesi. İnönü Caddesi. No: 201/2</h6>
                    <h6>Merkez / Çanakkale</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 login_resim">
          <img
            src={rightLogo}
            className="login_right_logo image-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
