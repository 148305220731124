import { FIYAT_URL } from '../constants';
import apiSlice from '../apiSlice';

export const fiyatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFiyats: builder.query({
      query: () => ({
        url: FIYAT_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['tblfiyat'],
    }),
    getFiyatById: builder.query({
      query: (fiyatID) => ({
        url: `${FIYAT_URL}/${fiyatID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createFiyat: builder.mutation({
      query: (data) => ({
        url: FIYAT_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['tblfiyat'],
    }),
    deleteFiyatById: builder.mutation({
      query: (fiyatId) => ({
        url: `${FIYAT_URL}/${fiyatId}`,
        method: 'DELETE',
      }),
      providesTags: ['tblfiyat'],
    }),
    getTutaryId: builder.query({
      query: (soruID) => ({
        url: `${FIYAT_URL}/tutar/${soruID}`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetFiyatsQuery,
  useGetFiyatByIdQuery,
  useCreateFiyatMutation,
  useDeleteFiyatByIdMutation,
  useGetTutaryIdQuery,
} = fiyatApiSlice;