import React from "react";
import { useSelector } from "react-redux";

const AProfilPage = () => {
  const { hukukUser } = useSelector((state) => state.sacomAuth);

  return (
    <div className="admin">
      <div className="d-flex flex-row">
        <div className="col-lg-1 col-md-2 col-3">
          <label htmlFor="adiSoyadi">Adı Soyadı:</label>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          <strong className="kirmizi">{hukukUser.userCredential}</strong>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-lg-1 col-md-2 col-3">
          <label htmlFor="adiSoyadi">E-Posta     :</label>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          <strong>{hukukUser.userName}</strong>
        </div>
      </div>
    </div>
  );
};

export default AProfilPage;
