import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import hukukLogo from "../../assets/logo.webp";
import { FaSignOutAlt } from "react-icons/fa";
import {
  logout,
  reset,
  sayfaChange,
  sideBarClose,
} from "../../redux/auth/authSlice";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { barClose, hukukUser } = useSelector((state) => state.sacomAuth);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(sideBarClose(true));
      } else {
        dispatch(sideBarClose(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const cikisYap = () => {
    dispatch(reset());
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className={`sidebar ${barClose ? "close" : ""}`}>
      <Link to="/app">
        <div className="logo">
          <img className="buro_logo" src={hukukLogo} alt="" />
        </div>
      </Link>
      <ul className="side-menu">
        <li
          className={selectedMenuItem === 0 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Gösterge"))}
        >
          <Link to="udash" onClick={() => handleMenuItemClick(0)}>
            <i className="bx bxs-dashboard"></i>Durum
          </Link>
        </li>
        <li
          className={selectedMenuItem === 8 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Danışma Ücret Tablosu"))}
        >
          <Link to="tarife" onClick={() => handleMenuItemClick(8)}>
            <i className="bx bx-table"></i>Danışma Ücretleri
          </Link>
        </li>
        <li
          className={selectedMenuItem === 1 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Yeni Başvuru"))}
        >
          <Link to="yeni" onClick={() => handleMenuItemClick(1)}>
            <i className="bx bx-plus"></i>Yeni Başvuru
          </Link>
        </li>
        <li
          className={selectedMenuItem === 4 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Sorularım"))}
        >
          <Link to="soru" onClick={() => handleMenuItemClick(4)}>
            <i className="bx bx-question-mark"></i>Sorularım
          </Link>
        </li>
        <li
          className={selectedMenuItem === 5 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Ödemelerim"))}
        >
          <Link
            to={`odemelerim/${hukukUser.userID}`}
            onClick={() => handleMenuItemClick(5)}
          >
            <i className="bx bx-money"></i>Ödemelerim
          </Link>
        </li>
        <li
          className={selectedMenuItem === 8 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Üyelik Sorusu"))}
        >
          <Link to="uyeliksoru" onClick={() => handleMenuItemClick(8)}>
            <i className="bx bx-message"></i>Üyelik Sorusu
          </Link>
        </li>
        <li
          className={selectedMenuItem === 6 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Şifre Değiştir"))}
        >
          <Link to="upassword" onClick={() => handleMenuItemClick(6)}>
            <i className="bx bx-key"></i>Şifre Değiştir
          </Link>
        </li>
        <li
          className={selectedMenuItem === 7 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Profil"))}
        >
          <Link to="uprofil" onClick={() => handleMenuItemClick(7)}>
            <i className="bx bx-group"></i>Profil
          </Link>
        </li>
      </ul>
      <ul className="side-menu">
        <li>
          <button
            className="btn btn-outline-danger btn-block"
            onClick={() => cikisYap()}
          >
            <FaSignOutAlt /> {barClose ? "" : "Çıkış"}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
