import { WEBFOR_URL } from '../constants';
import apiSlice from '../apiSlice';

export const webforApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getForWeb: builder.query({
      query: () => ({
        url: WEBFOR_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    getCounter: builder.query({
      query: () => ({
        url: WEBFOR_URL + "/counter",
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetForWebQuery,
  // useGetCounterQuery,
} = webforApiSlice; 