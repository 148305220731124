import React, { useEffect, useState } from "react";
import { FaBackspace, FaCheck, FaGlasses, FaMailBulk, FaSave } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSoruByIdQuery, useUpdateOdemeOnayBySoruIDMutation } from "../../redux/web/soruSlice";
import {
  useCreateCevapMutation,
  useGetCevapByIdQuery,
  useToggleCevapByIdMutation,
} from "../../redux/web/cevapSlice";
import { toast } from "react-toastify";
import Spinner from "../../web/Spinner";
import DosyaRead from "./DosyaRead";
import { truncateWord } from "../Utility";
import { SERVERPICT_URL } from "../../redux/constants";

const SoruCevapPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { data: editSoru, isLoading, refetch } = useGetSoruByIdQuery(params.id);
  const { data: editCevap, isLoading: isCevapLoading } = useGetCevapByIdQuery(params.id);
  const [createCevap, { isLoading: addLoading }] = useCreateCevapMutation();
  const [updateOdemeOnayBySoruID, { isLoading: updateLoading }] = useUpdateOdemeOnayBySoruIDMutation();
  const [toggleCevapById, { isLoading: toogleLoading }] = useToggleCevapByIdMutation();
  const [cevap, setCevap] = useState("");

  useEffect(() => {
    if (editCevap && editCevap.cevap && cevap === "") {
      setCevap(editCevap.cevap);
    }
  }, [editCevap, cevap]);

  const cevabiKaydetBekle = async () => {
    try {
      setLoading(true); // Spinner'ı göster
      let data = {
        soruID: editSoru.id,
        baslik: editSoru.baslik,
        cevap,
      };
      await createCevap(data).unwrap();
      // 1 saniye bekle
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toast.success("Kayıt Başarılı Şekilde Yapılmıştır")
      navigate(-1);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false); // Spinner'ı gizle
    }
  };

  const cevabiGonder = async () => {
    try {
      let  soruID= editSoru.id
      await toggleCevapById(soruID).unwrap();
      toast.success("Cevabınız Başarıyla Sisteme Kayıt Edildi.. Sorular Sayfasına Yönlendirildiniz.");
      navigate("/app/notans");
    } catch (err) {
      toast.error(err);
    }
  };

  const dekontGoster = () => {
    const fileUrl = `${SERVERPICT_URL}${editSoru && editSoru.dekontURL}`;
    // Dosyayı indirmek için yeni bir pencere aç
    window.open(fileUrl, "_blank");
  };

  const belgeGoster = () => {
    const fileUrl = `${SERVERPICT_URL}${editSoru && editSoru.belge}`;
    // Dosyayı indirmek için yeni bir pencere aç
    window.open(fileUrl, "_blank");
  };

  const odemeyiOnayla = async () => {
    try {
      let soruID= editSoru.id
      await updateOdemeOnayBySoruID(soruID).unwrap();
      toast.success("Ödeme Dekont Kontrolü Sonucu Onaylandı");
      refetch()
    } catch (err) {
      toast.error(err);
    }
  }

  return (
    <div className="d-flex flex-column soru_cevap_admin_page">
      {(isLoading || addLoading || toogleLoading || isCevapLoading || updateLoading) && (
        <Spinner />
      )}
      {loading && <Spinner />}
      <div className="d-flex row gap-1">
        <button
          className="btn btn-outline-danger col-lg-2 col-md-3 col-4 col"
          onClick={() => navigate(-1)}
        >
          <FaBackspace /> Geri Dön
        </button>
        {editSoru && editSoru.dekontURL && (
            <button
              className="btn btn-outline-primary col-lg-2 col-md-3 col-4 col"
              onClick={() => dekontGoster()}
            >
              <FaGlasses /> Dekont Göster
            </button>
        )}
        <button style={{ display: `${editSoru && editSoru.durum === "success" ? "none" : ""}`}}
              className="btn btn-outline-primary col-lg-2 col-md-3 col-4 col"
              onClick={() => odemeyiOnayla()}
            >
              <FaCheck /> Ödemeyi Onayla
            </button>
        {editSoru && editSoru.belge && (
            <button
              className="btn btn-outline-primary col-lg-2 col-md-3 col-4 col"
              onClick={() => belgeGoster()}
            >
              <FaGlasses /> Belge Göster
            </button>
        )}
         <div className="col-lg-2 col-md-3 col-4 col">
        <DosyaRead fileOku={editSoru} />
        </div>
      </div>
      <hr className="my-2" />
      <div className="col-12">
        <div className="col">
          Kategorisi: <strong>"{editSoru && editSoru.kategori}"</strong>
        </div>
        <div className="col">
          <div className="row">
            <span>
              Başlık:{" "}
              <strong>"{truncateWord(editSoru && editSoru.baslik, 60)}"</strong>
            </span>
            <details>{editSoru && editSoru.baslik}</details>
          </div>
        </div>
        <div className="col">
          <textarea
            name="cevap"
            className="col-11 col-lg-12 col-md-12 rounded"
            rows="20"
            value={cevap}
            onChange={(e) => setCevap(e.target.value)}
          ></textarea>
          <div className="d-flex flex-row gap-1">
            <button
              className="btn btn-outline-success"
              onClick={cevabiKaydetBekle}
            >
              <FaSave /> Güncelle & Kaydet
            </button>
            <button
              className="btn btn-outline-primary"
              disabled={editSoru && editSoru.durum !== "success"}
              onClick={() => cevabiGonder()}
            >
              <FaMailBulk /> Cevabı Kesin Olarak Gönder
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoruCevapPage;
