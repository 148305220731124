import React from 'react'

const Insights = ({veriTable}) => {
  return (
    <>
    <ul className="insights">
                <li><i className='bx bx-line-chart'></i>
                    <span className="info">
                        <h3>
                        {veriTable && veriTable.toplam_soru}
                        </h3>
                        <p>Toplam Soru</p>
                    </span>
                </li>
                <li><i className='bx bx-dollar-circle'></i>
                    <span className="info">
                        <h3>
                        {veriTable && Number(veriTable.toplam_tutar).toLocaleString("tr-TR", {
                            style: "currency",
                            currency: "TRY"
                        })}
                        </h3>
                        <p>Toplam Ödenen</p>
                    </span>
                </li>
            </ul>
            <pre style={{ display: "none"}}>{veriTable && JSON.stringify(veriTable, null, 2)}</pre>
            </>
  )
}

export default Insights