import { SABIT_URL } from "../constants";
import apiSlice from '../apiSlice';

export const sabitApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSabits: builder.query({
      query: () => ({
        url: SABIT_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsabit"],
    }),
    getSabitById: builder.query({
      query: (sabitId) => ({
        url: `${SABIT_URL}/${sabitId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    ekleGuncelleAboutById: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}/hakkimda`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tblsabit"],
    }),
    silAboutById: builder.mutation({
      query: () => ({
        url: `${SABIT_URL}/hakkimda`,
        method: "DELETE",
      }),
      invalidatesTags: ["tblsabit"],
    }),
    getAboutById: builder.query({
      query: () => ({
        url: `${SABIT_URL}/hakkimda`,
      }),
      keepUnusedDataFor: 5,
    }),
    ekleGuncelleCezaHukukYaziById: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}/ceza`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tblsabit"],
    }),
    silCezaHukukYaziById: builder.mutation({
      query: () => ({
        url: `${SABIT_URL}/ceza`,
        method: "DELETE",
      }),
      invalidatesTags: ["tblsabit"],
    }),
    getCezaHukukYaziById: builder.query({
      query: () => ({
        url: `${SABIT_URL}/ceza`,
      }),
      keepUnusedDataFor: 5,
    }),
    ekleGuncelleIletisimById: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}/iletisim`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tblsabit"],
    }),
    silIletisimById: builder.mutation({
      query: () => ({
        url: `${SABIT_URL}/iletisim`,
        method: "DELETE",
      }),
      invalidatesTags: ["tblsabit"],
    }),
    getIletisimById: builder.query({
      query: () => ({
        url: `${SABIT_URL}/iletisim`,
      }),
      keepUnusedDataFor: 5,
    }),
    ekleGuncelleMedeniHukukYaziById: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}/medeni`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tblsabit"],
    }),
    silMedeniHukukYaziById: builder.mutation({
      query: () => ({
        url: `${SABIT_URL}/medeni`,
        method: "DELETE",
      }),
      invalidatesTags: ["tblsabit"],
    }),
    getMedeniHukukYaziById: builder.query({
      query: () => ({
        url: `${SABIT_URL}/medeni`,
      }),
      keepUnusedDataFor: 5,
    }),
    ekleGuncelleOdemeYaziById: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}/odemeyazi`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tblsabit"],
    }),
    silOdemeYaziById: builder.mutation({
      query: () => ({
        url: `${SABIT_URL}/odemeyazi`,
        method: "DELETE",
      }),
      invalidatesTags: ["tblsabit"],
    }),
    getOdemeYaziById: builder.query({
      query: () => ({
        url: `${SABIT_URL}/odemeyazi`,
      }),
      keepUnusedDataFor: 5,
    }),
    ekleGuncelleSloganLogoAltYaziById: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}/slogan`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tblsabit"],
    }),
    silSloganLogoAltYaziById: builder.mutation({
      query: () => ({
        url: `${SABIT_URL}/slogan`,
        method: "DELETE",
      }),
      invalidatesTags: ["tblsabit"],
    }),
    getSloganLogoAltYaziById: builder.query({
      query: () => ({
        url: `${SABIT_URL}/slogan`,
      }),
      keepUnusedDataFor: 5,
    }),
    ekleGuncelleSocialById: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}/social`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tblsabit"],
    }),
    silSocialById: builder.mutation({
      query: () => ({
        url: `${SABIT_URL}/social`,
        method: "DELETE",
      }),
      invalidatesTags: ["tblsabit"],
    }),
    getSocialById: builder.query({
      query: () => ({
        url: `${SABIT_URL}/social`,
      }),
      keepUnusedDataFor: 5,
    }),
    uploadSabitImage: builder.mutation({
      query: (data) => ({
        url: `/api/upload`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetSabitsQuery,
  useGetSabitByIdQuery,
  useEkleGuncelleAboutByIdMutation,
  useSilAboutByIdMutation,
  useGetAboutByIdQuery,
  useEkleGuncelleCezaHukukYaziByIdMutation,
  useSilCezaHukukYaziByIdMutation,
  useGetCezaHukukYaziByIdQuery,
  useEkleGuncelleIletisimByIdMutation,
  useSilIletisimByIdMutation,
  useGetIletisimByIdQuery,
  useEkleGuncelleMedeniHukukYaziByIdMutation,
  useSilMedeniHukukYaziByIdMutation,
  useGetMedeniHukukYaziByIdQuery,
  useEkleGuncelleOdemeYaziByIdMutation,
  useSilOdemeYaziByIdMutation,
  useGetOdemeYaziByIdQuery,
  useEkleGuncelleSloganLogoAltYaziByIdMutation,
  useSilSloganLogoAltYaziByIdMutation,
  useGetSloganLogoAltYaziByIdQuery,
  useEkleGuncelleSocialByIdMutation,
  useSilSocialByIdMutation,
  useGetSocialByIdQuery,
  useUploadSabitImageMutation,
} = sabitApiSlice;
