import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const App = () => {
  const { theme } = useSelector((state) => state.sacomAuth);

  useEffect(() => {
    // Sayfa yüklendiğinde veya theme değiştiğinde body class'larını güncelle
    if (!theme) {
      document.body.classList.remove("dark");
    } else {
      document.body.classList.add("dark");
    }
  }, [theme]); // theme değeri değiştiğinde useEffect yeniden çağrılır

  return <Outlet />;
};

export default App;
