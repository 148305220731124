import React, { useEffect, useState } from "react";
import {
    useGetFiyatByIdQuery,
    useCreateFiyatMutation,
} from "../../redux/web/fiyatSlice";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Spinner from "../../web/Spinner";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { reload } from "../../redux/auth/authSlice";

const FiyatlandirmaPage = ({silID}) => {
    const dispatch = useDispatch()
  const { t } = useTranslation("translation");
  const [kategoriAdi, setKategoriAdi] = useState("");
  const [fiyat, setFiyat] = useState("");
  const {data: editTable, isLoading} = useGetFiyatByIdQuery(silID);
  const [createFiyat, { isLoading: loadingRecord }] = useCreateFiyatMutation();

  console.log(silID)

  useEffect(() => {
    if(editTable) {
        setKategoriAdi(editTable.kategoriAdi)
        setFiyat(editTable.fiyat)
    }
  },[editTable])

  const submitHandler = async () => {
    try {
      await createFiyat({
        kategoriAdi,
        fiyat
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success(t("toast.guncellendi"));
      setKategoriAdi("")
      setFiyat("")
      dispatch(reload(true))
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="kategori-edit">
      {(isLoading || loadingRecord) && <Spinner />}
        <div className="d-flex row col-md-12 gap-2">
          <div className="d-flex justify-content-start col-md-12 d-block">
            <label htmlFor="kategoriAdi" className="col-md-3 mt-2">
              Kategori Adı
            </label>
            <div className="col-md-9">
              <input
                type="text"
                value={kategoriAdi}
                name="kategoriAdi"
                onChange={(e) => setKategoriAdi(e.target.value)}
                placeholder="Kategori Adı"
                className="form-control d-block"
              />
            </div>
          </div>
          <div className="d-flex justify-content-start col-md-12 d-block">
            <label htmlFor="fiyat" className="col-md-3 mt-2">
              Fiyatı
            </label>
            <div className="col-md-3">
              <input
                type="text"
                value={fiyat}
                name="fiyat"
                onChange={(e) => setFiyat(e.target.value)}
                placeholder="TL"
                className="form-control d-block"
              />
            </div>
          </div>
      </div>
      <div className="d-flex justify-content-start col-md-12 d-block">
        <button
          className="mt-2 btn btn-outline-success btn-block"
          onClick={submitHandler}
        >
          <FaSave /> Kaydet
        </button>
      </div>
    </div>
  );
};

export default FiyatlandirmaPage;
