import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaKey, FaSignInAlt, FaUserPlus } from "react-icons/fa";
import "./LoginPage.css";
import loginLogo from "../assets/login.png";
import rightLogo from "../assets/loginrightimg.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoginMutation } from "../redux/auth/userApiSlice";
import { setCredentials } from "../redux/auth/authSlice";
import { toast } from "react-toastify";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  const { t } = useTranslation("translation");
  const [login, { data:loginTable, isLoading }] = useLoginMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const [captchaResult, setCaptchaResult] = useState("");

  const handleSubmit = () => {
    if (!email || !password || !inputCaptcha) {
      toast.error("Bilgileri tam doldurunuz.");
      return;
    }
    //console.log({ inputCaptcha, captchaResult });
    if (parseInt(inputCaptcha) !== captchaResult) {
      toast.error("Captcha doğrulanamadı.");
      return;
    }
    handleLogin(email, password);
  };

  const handleLogin = async (email, password) => {
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const generateCaptcha = () => {
    const randomNr1 = Math.floor(Math.random() * 99);
    const randomNr2 = Math.floor(Math.random() * 10);
    const totalNr = randomNr1 + randomNr2;
    setCaptchaText(`${randomNr1} + ${randomNr2} =`);
    setCaptchaResult(totalNr);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    else if (name === "password") setPassword(value);
    else if (name === "captcha") setInputCaptcha(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleLogin();
  };

  useEffect(() => {
    if(loginTable && loginTable.message) {
      if(loginTable.isSuccess === false && loginTable.message === "Invalid User Password") {
        toast.error(t("authPage.hataliPass"))
      }
    }
  },[loginTable, t])

  if (hukukUser && hukukUser.token) {
    navigate("/app");
    return null;
  }

  return (
    <div className="container">
      <div className="row g-0 align-items-center mt-3">
        <div className="col-lg-6 col-md-11 col-10 mt-5">
          <div className="mt-5 mb-5 cascading_right">
            <div className="p-5 shadow_5">
              <div className="d-flex col-12">
                <h2 className="fw-bold mb-3 col-7 text-center azgolgeli">
                  Uygulama Giriş
                </h2>
                <button
                  className="btn btn-outline-danger col-5"
                  onClick={() => navigate("/")}
                >
                  Web Sitesine Dön
                </button>
              </div>
              <div></div>
              <img src={loginLogo} alt="" className="login_logo" />
              {/* Register kısmında olacak yer */}
              <div className="mb-2">
                <input
                  className="form-control"
                  label="Email"
                  name="email"
                  type="email"
                  value={email}
                  placeholder="user@user.com"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <input
                  className="form-control"
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  placeholder={t("authPage.sifre")}
                  onChange={handleChange}
                />
              </div>
              <div className="justify-content-between col d-flex mt-2 mb-2">
                <div className="col-lg-4 col-md-4 col-6">
                  <label className="form-label mt-1" id="ebcaptchatext">
                    {captchaText} {t("authPage.sonuc")}
                  </label>
                </div>
                <div className="col-lg-4 col-md-4 col-6">
                  <input
                    type="text"
                    className="form-control"
                    id="ebcaptchainput"
                    name="captcha"
                    value={inputCaptcha}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="?"
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-success btn-block mb-2"
                size="md"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                <FaSignInAlt /> Giriş Yap
              </button>
              <div className="d-flex justify-content-between mt-1 mb-1">
                  <Link className="forget-pass" to="/reset">
                    <FaKey /> {t("authPage.sifremiUnuttum")}
                  </Link>
                  <Link className="new-user" to="/register">
                    <FaUserPlus /> {t("authPage.kayitOlmakIcin")}
                  </Link>
              </div>
              <div className="footer login_footer mt-4">
                <div className="text-white text-center">
                  <h4>Ankara Hukuk Bürosu</h4>
                  <hr className="my-2" />
                  <h6>İsmetpaşa Mahallesi. İnönü Caddesi. No: 201/2</h6>
                  <h6>Merkez / Çanakkale</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 login_resim">
          <img
            src={rightLogo}
            className="login_right_logo image-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
