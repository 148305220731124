import React from "react";
import { useSelector } from "react-redux";
import Insights from "./Insights";
import Reminder from "./Reminder";
import Spinner from "../../web/Spinner";
import { useGetUserIstatistikSPQuery } from "../../redux/web/istatistikSlice";

const Dashboard = () => {
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  let userID = hukukUser.userID
  const {data: veriTable, isLoading } = useGetUserIstatistikSPQuery(userID)

  return (
    <>
    {isLoading && <Spinner />}
      <Insights veriTable={veriTable}/>
      <div className="bottom-data">
        <Reminder userID={userID}/>
      </div>
    </>
  );
};

export default Dashboard;
 