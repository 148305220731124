import React, { useEffect, useState } from "react";
import {
  useGetFiyatsQuery,
  useDeleteFiyatByIdMutation,
} from "../../redux/web/fiyatSlice";
import { toast } from "react-toastify";
import Message from "../../web/Message";
import Spinner from "../../web/Spinner";
import KategoriEdit from "./KategoriEdit";
import { useDispatch, useSelector } from "react-redux";
import { navIDChange, reload } from "../../redux/auth/authSlice";
import { Paginator } from "primereact/paginator";
import { truncateWord } from "../Utility";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const FiyatlandirmaTable = ({ aramaText }) => {
  const { reloadPage } = useSelector((state) => state.sacomAuth);
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  const [edit, setEdit] = useState(null);
  const [silID, setSilID] = useState("");
  const [silAD, setSilAD] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);
  const { data: fiyatTable, isLoading, error, refetch } = useGetFiyatsQuery();
  const [deleteFiyatById, { isLoading: loadingDelete }] = useDeleteFiyatByIdMutation();

  const deleteHandler = async () => {
    try {
      await deleteFiyatById(silID);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (reloadPage && edit) {
      setEdit(false);
      dispatch(reload(false));
    }
  }, [reloadPage, edit, dispatch]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const filteredData =
    fiyatTable &&
    fiyatTable.filter((fiyat) => {
      const kategoriAdiLowerCase = fiyat.kategoriAdi.toLowerCase();
      const baslikLowerCase = fiyat.baslik.toLowerCase();
      const aciklamaLowerCase = fiyat.aciklama.toLowerCase();
      const aramaTextLowerCase = aramaText.toLowerCase();

      return (
        kategoriAdiLowerCase.includes(aramaTextLowerCase) ||
        baslikLowerCase.includes(aramaTextLowerCase) ||
        aciklamaLowerCase.includes(aramaTextLowerCase)
      );
    });

    const editFiyat = (id) => {
      dispatch(reload(true))
      dispatch(navIDChange(id))
    }

  return isLoading ? null : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div className="sorular">
      {loadingDelete && <Spinner />}
      <div className="table-responsive">
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th className="mobil_hide" style={{ width: "5%" }}>
                ID
              </th>
              <th style={{ width: "10%" }}>İşlem</th>
              <th className="mobil_hide" style={{ width: "20%" }}>
                Kategori Açıklaması
              </th>
              <th className="mobil_hide" style={{ width: "25%" }}>
                Başlık
              </th>
              <th style={{ width: "30%" }}>Detay</th>
              <th style={{ width: "10%" }}>Fiyatı</th>
            </tr>
          </thead>
          <tbody>
            {filteredData &&
              filteredData.length > 0 &&
              filteredData.slice(first, first + rows).map((fiyat, index) => (
                <tr key={index}>
                  <td className="mobil_hide">{index + 1}</td>
                  <td className="d-flex flex-row gap-1 data_td">
                    <button
                      className="btn btn-outline-success btn-sm mb-2"
                      onClick={() => editFiyat(fiyat.id)}
                    >
                      <FaEdit /> Seç
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm mb-2"
                      onClick={() => {
                        setSilID(fiyat.id);
                        setSilAD(fiyat.kategoriAdi);
                        setShow(true);
                      }}
                    >
                      <FaTrashAlt /> Sil
                    </button>
                  </td>
                  <td className="mobil_hide">{fiyat.kategoriAdi}</td>
                  <td className="mobil_hide">
                    {truncateWord(fiyat.baslik, 30)}
                  </td>
                  <td>{truncateWord(fiyat.aciklama, 30)}</td>
                  <td>{fiyat.fiyat}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={fiyatTable.length}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      {show && (
        <div
          className={`modal fade ${show ? "show" : ""}`}
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            display: `${show ? "block" : "none"}`,
            paddingRight: `${show ? "17px" : ""}`,
          }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title" id="exampleModalLabel">
                  Silme Uyarısı
                </h5>
                <button
                  type="button"
                  className="close btn btn-secondary"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ height: "15vh" }}>
                <p>
                  <strong>{silAD} / </strong>Bu Kategori Silinecektir. Emin
                  misiniz?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteHandler();
                    setShow(false);
                  }}
                >
                  Sil
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => setShow(false)}
                >
                  Vazgeç
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {edit && (
        <div
          className={`modal fade ${edit ? "show" : ""}`}
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            display: `${edit ? "block" : "none"}`,
            paddingRight: `${edit ? "17px" : ""}`,
          }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Kategori Düzenleme
                </h5>
                <button
                  type="button"
                  className="close btn btn-secondary"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setEdit(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ height: "20vh" }}>
                <KategoriEdit silID={silID} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FiyatlandirmaTable;
