import React, { useEffect, useState } from "react";
import {
  useEkleGuncelleIletisimByIdMutation,
  useGetIletisimByIdQuery,
} from "../../redux/web/sabitSlice";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Message from "../../web/Message";
import Spinner from "../../web/Spinner";
import { useTranslation } from "react-i18next";

const IletisimPage = () => {
  const { t } = useTranslation("translation");
  const [il, setIl] = useState("");
  const [ilce, setIlce] = useState("");
  const [adres, setAdres] = useState("");
  const [loading, setLoading] = useState(false);
  const [telefon, setTelefon] = useState("");
  const {
    data: iletisimTable,
    isLoading,
    error,
    refetch,
  } = useGetIletisimByIdQuery();
  const [ekleGuncelleIletisimById, { isLoading: loadingUpdate }] =
    useEkleGuncelleIletisimByIdMutation();

  useEffect(() => {
    if (iletisimTable) {
      setIl(iletisimTable.il);
      setIlce(iletisimTable.ilce);
      setAdres(iletisimTable.adres);
      setTelefon(iletisimTable.telefon);
    }
  }, [iletisimTable]);

  const submitHandler = async () => {
    let data = { il, ilce, adres, telefon };
    try {
      setLoading(true); // Spinner'ı göster
      await ekleGuncelleIletisimById(data).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toast.success(t("toast.guncellendi"));
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    } finally {
      setLoading(false); // Spinner'ı gizle
    }
  };

  return isLoading ? null : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div className="yonetim_info">
      {(loadingUpdate || loading) && <Spinner />}
      <div className="d-flex row gap-2">
        <div className="row d-flex">
          <div className="col col-lg-3 col-md-3 col-3">
            <label htmlFor="il" className="form-label">
              İl
            </label>
          </div>
          <div className="col col-lg-3 col-md-3 col-9">
            <input
              type="text"
              value={il}
              name="il"
              onChange={(e) => setIl(e.target.value)}
              placeholder="İl"
              className="form-control"
            />
          </div>
        </div>
        <div className="row d-flex">
          <div className="col col-lg-3 col-md-3 col-3">
            <label htmlFor="ilce" className="form-label">
              İlçe
            </label>
          </div>
          <div className="col col-lg-3 col-md-3 col-9">
            <input
              type="text"
              value={ilce}
              name="ilce"
              onChange={(e) => setIlce(e.target.value)}
              placeholder="İlçe"
              className="form-control"
            />
          </div>
        </div>
        <div className="row d-flex">
          <div className="col col-lg-3 col-md-3 col-3">
            <label htmlFor="adres" className="form-label">
              Adres
            </label>
          </div>
          <div className="col col-lg-6 col-md-6 col-9">
            <input
              type="text"
              value={adres}
              name="adres"
              onChange={(e) => setAdres(e.target.value)}
              placeholder="Adres"
              className="form-control"
            />
          </div>
        </div>
        <div className="row d-flex">
          <div className="col col-lg-3 col-md-3 col-3">
            <label htmlFor="telefon" className="form-label">
              Telefon
            </label>
          </div>
          <div className="col col-lg-3 col-md-3 col-9">
            <input
              type="text"
              value={telefon}
              name="telefon"
              onChange={(e) => setTelefon(e.target.value)}
              placeholder="Telefon"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <button
          className="mt-2 btn btn-outline-success btn-block"
          onClick={submitHandler}
        >
          <FaSave /> Kaydet
        </button>
      </div>
    </div>
  );
};

export default IletisimPage;
