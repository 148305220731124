import React, { useEffect, useState } from "react";
import Spinner from "../../web/Spinner";
import { FaBackspace, FaSave, FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  useCreateMusteriMutation,
  useGetMusteriByIdQuery,
} from "../../redux/web/musteriSlice";
import { toast } from "react-toastify";
import ilTable from "./Turkiye.json";

const UserProfil = () => {
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  let userID = hukukUser && hukukUser.userID;
  const [editShow, setEditShow] = useState(false);
  const [reFecth, setReFetch] = useState(false);
  const {
    data: musteriTable,
    isLoading,
    fulfilled,
    refetch,
  } = useGetMusteriByIdQuery(userID);
  const [createMusteri, { data: createData }] = useCreateMusteriMutation();

  const [musteri, setMusteri] = useState({
    idCartNo: "",
    adi: "",
    soyAdi: "",
    adres: "",
    il: "",
    email: "",
    telefon: "",
    userId: userID,
  });
  const { idCartNo, adi, soyAdi, adres, il, email, telefon} = musteri;

  //console.log({ fulfilled, adi, reFecth });

  useEffect(() => {
    if (fulfilled || adi === "" || adi === undefined) {
      if (musteriTable && musteriTable.idNo !== "") {
        setMusteri((prevState) => ({
          ...prevState,
          idCartNo: musteriTable.idNo,
          adi: musteriTable.adi,
          soyAdi: musteriTable.soyAdi,
          adres: musteriTable.adres,
          il: musteriTable.il,
          email: musteriTable.email,
          telefon: musteriTable.telefon,
          userId: musteriTable.uID,
        }));
      }
    }
  }, [musteriTable, adi, fulfilled]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMusteri((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (
      reFecth &&
      createData &&
      (createData.isAdded || createData.isUpdate === "1")
    ) {
      refetch();
    }
  }, [createData, reFecth, refetch]);

  const handleRecord = async () => {
    if (
      idCartNo === "" ||
      adi === "" ||
      soyAdi === "" ||
      adres === "" ||
      il === "" ||
      email === "" ||
      telefon === ""
    ) {
      toast.error("Lütfen Eksik kısımlarını tam doldurunuz.");
      return;
    }

    try {
      await createMusteri(musteri).unwrap();
      setMusteri((prevState) => ({
        ...prevState,
        idCartNo: "",
        adi: "",
        soyAdi: "",
        adres: "",
        il: "",
        email: "",
        telefon: "",
      }));
      toast.success("Kayıt Ekle/Güncelle(nmiştir)..");
      setEditShow(false);
      setReFetch(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="profil_page">
      {isLoading && <Spinner />}
      <div className="row justify-content-center">
        <div className="text-center">
          <u>
            <h3>Ödeme Bilgileri</h3>
          </u>
        </div>
        <div className="col-12">
          <section className="mt-2">
            <div className="form-group row mt-1">
              <label className="col-2 form-label">TCK No:</label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  name="idCartNo"
                  value={idCartNo}
                  onChange={handleChange}
                  disabled={!editShow}
                  onFocus={() =>
                    setMusteri((prevState) => ({
                      ...prevState,
                      userId: hukukUser && hukukUser.userID,
                    }))
                  }
                />
              </div>
            </div>
            <div className="form-group row mt-1">
              <label className="col-2 form-label">Adı:</label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  name="adi"
                  value={adi}
                  onChange={handleChange}
                  disabled={!editShow}
                />
              </div>
            </div>
            <div className="form-group row mt-1">
              <label className="col-2 form-label">Soyadı:</label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  name="soyAdi"
                  value={soyAdi}
                  onChange={handleChange}
                  disabled={!editShow}
                />
              </div>
            </div>
            <div className="form-group row mt-1">
              <label className="col-2 form-label">Adres:</label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  name="adres"
                  value={adres}
                  onChange={handleChange}
                  disabled={!editShow}
                />
              </div>
            </div>
            <div className="form-group row mt-1">
              <label className="col-2 form-label">İl (Vilayet):</label>
              <div className="col-lg-3 col-md-4 col-9 d-flex row">
                <input
                  type="text"
                  className="form-control col"
                  name="il"
                  value={il}
                  onChange={handleChange}
                  disabled
                  style={{ marginLeft: "11px" }}
                />
                <div
                  className="custom-select col"
                  style={{ display: `${editShow ? "" : "none"}` }}
                >
                  {ilTable &&
                    ilTable.provinces &&
                    ilTable.provinces.length > 0 && (
                      <select
                        className="form-control"
                        onChange={(e) =>
                          setMusteri((prevState) => ({
                            ...prevState,
                            il: e.target.value,
                          }))
                        }
                      >
                        <option
                          className="register-input"
                          value="em"
                          style={{ fontWeight: "600" }}
                          required
                        >
                          Seçiniz..
                        </option>
                        {ilTable.provinces.map((il, index) => (
                          <option
                            className="register-input"
                            key={index}
                            value={il.name}
                            required
                          >
                            {il.name}
                          </option>
                        ))}
                      </select>
                    )}
                </div>
              </div>
            </div>
            <div className="form-group row mt-1">
              <label className="col-2 form-label">Email:</label>
              <div className="col-9">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  disabled={!editShow}
                />
              </div>
            </div>
            <div className="form-group row mt-1">
              <label className="col-2 form-label">Telefon:</label>
              <div className="col-6">
                <input
                  type="tel"
                  className="form-control"
                  name="telefon"
                  value={telefon}
                  onChange={handleChange}
                  disabled={!editShow}
                />
              </div>
            </div>
            {!editShow ? (
              <div className="form-group row mt-1">
                <label className="col-2 form-label"></label>
                <div className="col-10">
                  <button
                    className="btn btn-outline-success col-lg-2 col-md-2 col-6"
                    onClick={() => setEditShow(true)}
                  >
                    <FaSyncAlt /> Düzenle
                  </button>
                </div>
              </div>
            ) : (
              <div className="form-group row mt-1">
                <label className="col-2 form-label"></label>
                <div className="col-10 col d-flex gap-1">
                  <button
                    className="btn btn-outline-success col-lg-2 col-md-2 col-5"
                    onClick={() => handleRecord()}
                  >
                    <FaSave /> Kaydet
                  </button>
                  <button
                    className="btn btn-outline-primary col-lg-2 col-md-2 col-5"
                    onClick={() => setEditShow(false)}
                  >
                    <FaBackspace /> Vazgeç
                  </button>
                </div>
              </div>
            )}
          </section>
        </div>
        <div className="form-group row mt-5">
          <div
            className="col-12 offset-sm-2"
            style={{ fontFamily: "'Dancing Script', cursive" }}
          >
            <p className="text-muted">
              Bu formdaki bilgiler{" "}
              <strong className="kirmizi">kredi kartı</strong> ve{" "}
              <strong className="kirmizi">eft ödemeleri</strong> için{" "}
              <u className="kirmizi">
                <strong>mecburen gereklidir</strong>
              </u>
              .
            </p>
            <p>Lütfen bilgilerinizi kontrol ederek kayıt ediniz.</p>
          </div>
        </div>
      </div>
      <pre className="hide_menu">{musteri && JSON.stringify(musteri, null, 2)}</pre>
    </div>
  );
};

export default UserProfil;
