import React from "react";

const HukukInfo = () => {
  // const medeniHukukYonlendir = () => {
  //   //https://tr.wikipedia.org/wiki/Medeni_hukuk
  //   window.open('https://tr.wikipedia.org/wiki/Medeni_hukuk', '_blank')
  // }

  // const cezaHukukYonlendir = () => {
  //   //https://tr.wikipedia.org/wiki/Medeni_hukuk
  //   window.open("https://tr.wikipedia.org/wiki/T%C3%BCrk_Ceza_Kanunu", "_blank");
  // };

  return (
    <section id="story">
      <div className="story-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-content">
                <h2 className="">Medeni Hukuk Nedir?</h2>
                <p className="mt-5 mb-5">
                  Kişilerin birbiriyle ya da belirli ölçülerde kişilerle
                  devletin doğrudan veya dolaylı özel ilişkilerinin kamu hukuku
                  gibi başka bir hukuk dalının konusuna girmeyen hukuk dalına
                  verilen addır. Temeli Roma hukukuna dayanır ve Cermen halkları
                  tarafından geliştirilmiştir.
                </p>
                {/* <button
                  className="main-btn mt-3"
                  onClick={() => medeniHukukYonlendir()}
                >
                  Daha Fazlasını Oku
                </button> */}
              </div>
            </div>
            <div className="col-sm-12 mt-5">
              <div className="text-content">
                <h2>Türk Ceza Kanunu Nedir?</h2>
                <p className="mt-5 mb-5">
                  Kısaca TCK, Türkiye Büyük Millet Meclisi tarafından 29 Eylül
                  2004'te kabul edilen, 12 Ekim 2004'te T.C. Resmî Gazete'de
                  yayımlanan ve 1 Haziran 2005'te yürürlüğe giren yasadır.
                </p>
                {/* <button
                  className="main-btn mt-3"
                  onClick={() => cezaHukukYonlendir()}
                >
                  Daha Fazlasını Oku
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HukukInfo;
