import React from "react";
import { useSelector } from "react-redux";
import aboutPict from "../assets/img/img-1.png";

const About = () => {
  const { webData } = useSelector((state) => state.sacomAuth);

  return (
    <section id="about">
      <div className="about-section wrapper">
        <div className="container">
          <h1 className="text-center mb-5 azgolgeli">Hakkımda</h1>
          <hr />
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 mb-lg-0 mb-5">
              <div className="card border-0">
                <img src={aboutPict} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 text-sec">
              <h2 className="azgolgeli avukat_kimdir">
                Avukat {webData && webData.setup && webData.setup.adiSoyadi}{" "}
                kimdir ?
              </h2>
              <hr />
              <div
                className="about-content"
                dangerouslySetInnerHTML={{
                  __html:
                    webData && webData.hukuk && webData.hukuk.hakkimdaHtml,
                }}
                style={{ textAlign: "justify" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
