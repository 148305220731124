import React from "react";
import TarifePage from "../app/user/TarifePage";

const TarifesPage = () => {
  return (
    <section id="tarife" className="tarife">
      <div className="container">
      <h3 className="text-center">Danışma Ücretleri Tablosu</h3>
      <TarifePage />
      </div>
    </section>
  );
};

export default TarifesPage;

/*
import React from "react";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";

const TarifePage = () => {
  const navigate = useNavigate();
  const { webData } = useSelector((state) => state.sacomAuth);

  return (
    <section id="tarife">
      <div className="tarife-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-content">
                <h1>Danışma Ücret Tarifesi</h1>
                <h3>
                  {webData && webData.setup && webData.setup.odemeYazi}
                </h3>
                <button
                  className="main-btn mt-3"
                  onClick={() => navigate("/app")}
                >
                  Danışma Ücretlerini Görmek İçin..
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TarifePage;

*/
