import React from "react";
import { useSelector } from "react-redux";
import LoginPage from "./LoginPage";
import AdminPage from "./admin/AdminPage";
import UserPage from "./user/UserPage";

const AppPage = () => {
 const { hukukUser } = useSelector((state) => state.sacomAuth);
  // const [hukukUser, setHukukUser] = useState({
  //   token: "var",
  //   userMod: 1
  // })
  return (
    <>
      {hukukUser && hukukUser.token ? (
        hukukUser.userMod === 1 ? (
          <AdminPage />
        ) : (
          <UserPage />
        )
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default AppPage;
