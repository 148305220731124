import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaKey, FaTimesCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useUserChangePassTeyitMutation } from "../redux/auth/userApiSlice";
import { toast } from "react-toastify";

const ChangePassTeyit = ({ email }) => {
  const navigate = useNavigate();
  const [mesajVer, setMesajVer] = useState(false);
  const { t } = useTranslation("translation");
  const [userChangePassTeyit, { data: teyitTable }] =
    useUserChangePassTeyitMutation();
  const [userData, setUserData] = useState({
    password: "",
    password2: "",
  });
  const { password, password2 } = userData;

  const onChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEmailSubmit = async () => {
    try {
      let data = { email, password };
      await userChangePassTeyit(data).unwrap();
      toast("Şireniz Değiştiriliyor", { autoClose: 5000 });
      setMesajVer(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (
      mesajVer &&
      teyitTable &&
      teyitTable.isUpdated &&
      teyitTable.isUpdated === "1"
    ) {
      toast.success(teyitTable.message === "Password is Changed" ? t("toast.sifreDegisti") : teyitTable.message);
      navigate("/login");
      setMesajVer(false);
    }
  }, [mesajVer, teyitTable, navigate, t]);

  return (
    <div className="p-2 col-lg-4 col-md-4 col-12">
      <div className="mb-2">
        <input
          className="form-control"
          id="form3"
          type="email"
          name="email"
          value={email}
          placeholder="name@domain.com"
          autoComplete="new-password"
          readOnly
        />
      </div>
      <div className="mb-2">
        <input
          className="form-control"
          label="Password"
          id="form4"
          type="password"
          name="password"
          value={password}
          autoComplete="new-password"
          placeholder={t("authPage.sifre")}
          onChange={onChange}
          required
        />
        {password.length < 6 && (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {t("authPage.enAzAlti")}
          </span>
        )}
      </div>
      <div className="mb-2">
        <input
          className="form-control"
          type="password"
          id="password2"
          name="password2"
          autoComplete="new-password"
          value={password2}
          placeholder={t("authPage.sifreOnay")}
          onChange={onChange}
          required
        />
        {password.length > 5 && password === password2 ? (
          <FaCheck style={{ color: "green" }} />
        ) : (
          <FaTimesCircle style={{ color: "red" }} />
        )}
        {password.length < 6 && (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {t("authPage.enAzAlti")}
          </span>
        )}
      </div>
      <button
        className="btn btn-success mt-2 btn-block"
        type="button"
        onClick={() => handleEmailSubmit()}
      >
        <FaKey /> {t("genelPage.sifremiSifirla")}
      </button>
    </div>
  );
};

export default ChangePassTeyit;
