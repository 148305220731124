import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hukukUser: localStorage.getItem("hukukUser")
    ? JSON.parse(localStorage.getItem("hukukUser"))
    : null,
  reloadPage: false,
  isRecordAdded: false,
  cevapShow: false,
  dialogShow: "",
  navID: {},
  payHtml: "",
  iyziData: [],
  barClose: false,
  theme: false,
  sayfaAdi: "",
  webData: localStorage.getItem("sacom.webData")
    ? JSON.parse(localStorage.getItem("sacom.webData"))
    : [],
  counterData: localStorage.getItem("sacom.counterData")
    ? JSON.parse(localStorage.getItem("sacom.counterData"))
    : [],
  isRotated: false,
  aktifPageName: "",
  aktifPageTable: [],
  hukukSepet: {},
};

const authSlice = createSlice({
  name: "sacomAuth",
  initialState,
  reducers: {
    reset: (state) => {
      state.reloadPage= false;
      state.isRecordAdded= false;
      state.cevapShow= false;
      state.dialogShow= "";
      state.navID= {};
      state.payHtml= "";
      state.iyziData= [];
      state.barClose= false;
      state.theme= false;
      state.sayfaAdi= "";
      state.isRotated= false;
      state.aktifPageName = "";
      state.aktifPageTable = [];
      state.hukukSepet= {};
    },
    setCredentials: (state, action) => {
      state.hukukUser = action.payload;
      localStorage.setItem("hukukUser", JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.hukukUser = null;
      localStorage.removeItem("hukukUser");
      localStorage.removeItem("sacom.webData");
    },
    recordAdd: (state, action) => {
      state.isRecordAdded = action.payload;
    },
    reload: (state, action) => {
      state.reloadPage = action.payload;
    },
    cevapChange: (state, action) => {
      state.cevapShow = action.payload;
    },
    navIDChange: (state, action) => {
      state.navID = action.payload;
    },
    dialogClose: (state, action) => {
      state.dialogShow = action.payload;
    },
    payTrPayPage: (state, action) => {
      state.payHtml = action.payload;
    },
    iyziPayData: (state, action) => {
      state.iyziData = action.payload;
    },
    setIsRotated: (state, action) => {
      state.isRotated = action.payload;
    },
    sideBarClose: (state, action) => {
      state.barClose = action.payload;
    },
    themeChange: (state, action) => {
      state.theme = action.payload;
    },
    sayfaChange: (state, action) => {
      state.sayfaAdi = action.payload;
    },
    webDataSet: (state, action) => {
      state.webData = action.payload;
      localStorage.setItem("sacom.webData", JSON.stringify(action.payload)); // localStorage'a da kaydet
    },
    counterDataSet: (state, action) => {
      state.counterData = action.payload;
      localStorage.setItem("sacom.counterData", JSON.stringify(action.payload)); // localStorage'a da kaydet
    },
    removeUser: (state) => {
      state.hukukUser = {};
    },
    aktifPageChange: (state, action) => {
      state.aktifPageName = action.payload.sayfaAdi;
      state.aktifPageTable = action.payload.table;
    },
    sepetGuncelle: (state, action) => {
      state.hukukSepet = action.payload;
    },
  },
});

export const {
  reset,
  setCredentials,
  logout,
  recordAdd,
  reload,
  cevapChange,
  dialogClose,
  navIDChange,
  payTrPayPage,
  iyziPayData,
  setIsRotated,
  sideBarClose,
  themeChange,
  sayfaChange,
  webDataSet,
  counterDataSet,
  removeUser,
  aktifPageChange,
  sepetGuncelle
} = authSlice.actions;

export default authSlice.reducer;
