import React from "react";
import { FaBackspace } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

const CevapOkuPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column">
      <button
        className="btn btn-outline-danger col-1"
        onClick={() => navigate("/app/yesans")}
      >
        <FaBackspace /> Geri Dön
      </button>
      <p>CevapOkuPage</p>
      {params.id}
    </div>
  );
};

export default CevapOkuPage;
