import React from "react";
import Header from "./Header";
import Banner from "./Banner";
import Counter from "./Counter";
import About from "./About";
import HukukInfo from "./HukukInfo";
import TarifesPage from "./TarifesPage";
import DavaTurleri from "./DavaTurleri";
import KVKK from "./KVKK";
import Booking from "./Booking";
import Footer from "./Footer";
import Teslimat from "./Teslimat";
import Meta from "./Meta";
// import { useSearch, SearchProvider } from "./SearchPage";
// import SearchResult from "./SearchResult";

const WebPage = () => {
  // const { searchQuery } = useSearch();
  // const searchResults = ["Sonuç 1", "Sonuç 2", "Sonuç 3"];

  return (
    // <SearchProvider>
      <main className="App">
        <Header />
        <Meta />
        <Banner />
        <Counter />
        <About />
        <HukukInfo />
        <DavaTurleri />
        <TarifesPage />
        <KVKK />
        <Booking />
        <Teslimat />
        <Footer />
        {/* {searchQuery && <SearchResult results={searchResults} />} */}
      </main>
    // </SearchProvider>
  );
};

export default WebPage;
