import React from "react";
import {useNavigate} from "react-router-dom"

const Insights = ({veriTable}) => {
  const navigate = useNavigate()

  return (
    <ul className="insights">
      <li onClick={() => navigate("/app/aprofil")}>
        <i className="bx bx-show-alt"></i>
        <span className="info">
          <h3>{veriTable && veriTable.userCount}</h3>
          <p>Bu Ayın Müşteri Kayıt Sayısı</p>
        </span>
      </li>
      <li onClick={() => navigate("/app/notans")}>
        <i className="bx bx-line-chart"></i>
        <span className="info">
          <h3>{veriTable && veriTable.aylikSoru}</h3>
          <p>Bu Ayın Soru Sayısı</p>
        </span>
      </li>
      <li>
        <i className="bx bx-dollar-circle"></i>
        <span className="info">
          <h3>{veriTable && veriTable.totalTutar ? veriTable.totalTutar : 0}</h3>
          <p>Bu Ay Kazanılan</p>
        </span>
      </li>
    </ul>
  );
};

export default Insights;
