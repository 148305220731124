import React from 'react'
import { useSelector } from 'react-redux'

const SepetPage = () => {
  const {hukukSepet} = useSelector(state => state.sacomAuth)

  return (
    <div className='sepet-page'>
      <span>Sepetiniz..</span>
      <hr />
      <pre>{hukukSepet && JSON.stringify(hukukSepet, null, 2)}</pre>
    </div>
  )
}

export default SepetPage