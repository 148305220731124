import React, { useEffect, useState } from "react";
import {
  usePayTRListQuery,
  usePayTROdemeSilMutation,
} from "../../redux/web/payTRSlice";
import moment from "moment";
import Spinner from "../../web/Spinner";
import { Paginator } from "primereact/paginator";
import { FaBackspace, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";

const PayTRList = () => {
  const { t } = useTranslation("translation");
  const { data: listTable, isLoading, refetch } = usePayTRListQuery();
  const [payTROdemeSil, { isLoading: delLoading }] =
    usePayTROdemeSilMutation();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(8);
  const [loading, setLoading] = useState(false);
  const [silID, setSilID] = useState("");
  const [visible, setVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Ekran genişliği değiştiğinde güncelle
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  }; 
  useEffect(() => {
    // Component yüklendiğinde ve ekran genişliği değiştiğinde updateScreenWidth fonksiyonunu çağır
    window.addEventListener("resize", updateScreenWidth);
    // useEffect içinde temizleme işlemi
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);


  const durumDuzeltme = (durum) => {
    let durumYaz = "";
    let tdDurum = "";
    if (durum === "success") {
      durumYaz = "status completed";
      tdDurum = "Tamamlandı";
    } else if (durum === "wait") {
      durumYaz = "status process";
      tdDurum = "Bekliyor";
    } else if (durum === "error") {
      durumYaz = "status pending";
      tdDurum = "Hatalı";
    }

    return { durumYaz, tdDurum };
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const payPostSilSor = (id) => {
    setSilID(id);
    setVisible(true);
  };

  const payPostSil = async () => {
    setVisible(false);
    try {
      setLoading(true); // Spinner'ı göster
      const result = await payTROdemeSil(silID); // Silme işlemini başlat ve sonucunu al
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 saniye bekle
      toast.success(t(result.data.message)); // Silme işlemi başarılı mesajını göster
      await refetch(); // Veriyi yeniden al
    } catch (err) {
      toast.error(err?.data?.message || err.error); // Hata durumunda uygun mesajı göster
    } finally {
      setLoading(false); // Spinner'ı gizle
    }
  };

  return (
    <div className="bottom-data">
      <div className="orders">
        {(isLoading || delLoading || loading) && <Spinner />}
        <div className="header">
          <i className="bx bx-receipt"></i>
          <h3>Ödemeler Tablosu</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>Adı Soyadı</th>
              <th>Durumu / İşlem</th>
              <th>Tarih</th>
              <th>Soru ID</th>
              <th className="text_right">Tutar</th>
            </tr>
          </thead>
          <tbody>
            {listTable &&
              listTable.length > 0 &&
              listTable.slice(first, first + rows).map((odeme, index) => {
                const { durumYaz, tdDurum } = durumDuzeltme(odeme.status);
                return (
                  <tr key={index}>
                    <td style={{ fontSize: "14px" }}>{odeme.adiSoyadi}</td>
                    <td>
                      <span className={durumYaz}>{tdDurum}</span>
                      {tdDurum !== "success" && (
                        <button
                          className="btn btn-outline-danger mleft_3"
                          onClick={() => payPostSilSor(odeme.paytrpost_id)}
                        >
                          <FaTrashAlt /> Sil
                        </button>
                      )}
                    </td>
                    <td>{moment(odeme.tarih).format("DD.MM.YYYY")}</td>
                    <td>{odeme.soruID}</td>
                    <td className="text_right">{Number(odeme.payment_amount).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY"
                    })}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="card paginator">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={listTable && listTable.length}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <Dialog
        header="Silme Uyarısı"
        headerStyle={{ textAlign: "center" }}
        contentStyle={{ background: "#C68484" }}
        visible={visible}
        style={{
          width: `${
            screenWidth < 991 ? "75vw" : screenWidth < 768 ? "95vw" : "50vw"
          }`,
          marginLeft: "35px",
        }}
        onHide={() => setVisible(false)}
      >
        <div className="p-2 text-white text-center">
          <h3>Bu İşlem Geri Alınamaz</h3>
          <h6>Ödeme Kaydınız Silmek İstiyor musunuz?</h6>
          <hr className="my-2 mb-4" />
          <div className="d-flex flex-row gap-1">
            <button className="col-6 btn btn-danger"
            onClick={() => payPostSil()}>
              <FaTrashAlt /> Sil
            </button>
            <button className="col-6 btn btn-secondary"
            onClick={() => setVisible(false)}>
            <FaBackspace /> Vazgeç
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PayTRList;
