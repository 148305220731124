import { ISTATISTIK_URL } from "../constants";
import apiSlice from "../apiSlice";

export const istatistikApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminAylikIstatistikSP: builder.query({
      query: () => ({
        url: ISTATISTIK_URL + "/admin",
      }),
      keepUnusedDataFor: 5,
    }),
    getAdminIstatistikSonUcSP: builder.query({
      query: () => ({
        url: ISTATISTIK_URL + "/sonucsoru",
      }),
      keepUnusedDataFor: 5,
    }),
    getUserIstatistikSP: builder.query({
      query: (userID) => ({
        url: ISTATISTIK_URL + `/user/${userID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getUserIstatistikSonUcSP: builder.query({
      query: (userID) => ({
        url: `${ISTATISTIK_URL}/userucsoru/${userID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    yirmiDortSaatiKalanlar: builder.query({
      query: () => ({
        url: `${ISTATISTIK_URL}/yirmidort`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCevapsizSorular: builder.query({
      query: () => ({
        url: `${ISTATISTIK_URL}/cevapsizlar`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCevabiGonderilmeyenler: builder.query({
      query: () => ({
        url: `${ISTATISTIK_URL}/cevapunsets`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCevabiGidenler: builder.query({
      query: () => ({
        url: `${ISTATISTIK_URL}/cevapsets`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetAdminAylikIstatistikSPQuery,
  useGetAdminIstatistikSonUcSPQuery,
  useGetUserIstatistikSPQuery,
  useGetUserIstatistikSonUcSPQuery,
  useYirmiDortSaatiKalanlarQuery,
  useGetCevapsizSorularQuery,
  useGetCevabiGonderilmeyenlerQuery,
  useGetCevabiGidenlerQuery
} = istatistikApiSlice;
