import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import Pagination from "../../web/Pagination";
import { useNavigate } from "react-router-dom";
import { useGetCevapsQuery } from "../../redux/web/cevapSlice";
import { truncateWord } from "../Utility";
import { useTranslation } from "react-i18next";
import Spinner from "../../web/Spinner";
import moment from "moment"

const CevaplarPage = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const { data: cevapTable, isLoading, error } = useGetCevapsQuery();
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setHide(true);
      } else {
        setHide(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    cevapTable &&
    cevapTable.length > 0 &&
    cevapTable.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bottom-data sorular">
      {isLoading && <Spinner />}
      <div className="orders">
        <div className="header">
          <i className="bx bx-receipt"></i>
          <h3>Cevaplar</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <h5>İşlem</h5>
              </th>
              <th>
                <h5>Tarih</h5>
              </th>
              <th style={{ display: `${hide ? "none" : ""}` }}>
                <h5>Başlık</h5>
              </th>
              <th>
                <h5>Cevap</h5>
              </th>
            </tr>
          </thead>
          {cevapTable && cevapTable.length > 0 ? (
            currentPosts.map((soru, index) => (
              <tbody>
                <tr key={index} className="data_td">
                  <td>
                    <button
                      className="btn btn-outline-success"
                      onClick={() => navigate(`edit/${soru.id}`)}
                    >
                      <FaCheck className="tablo_icon" /> Seç
                    </button>
                  </td>
                  <td>
                    {moment(soru.tarih).format("DD.MM.YYYY")}
                  </td>
                  <td title={soru.baslik} style={{ display: `${hide ? "none" : ""}` }}>
                    {truncateWord(soru.baslik, 50)}
                  </td>
                  <td title={soru.cevap}>{truncateWord(soru.cevap, 50)}</td>
                </tr>
              </tbody>
            ))
          ) : (
            <h5 className="kirmizi mt-2">
              {error &&
                error.data === "RecordNotFound" &&
                t("genelPage.RecordNotFound")}
            </h5>
          )}
        </table>

        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={cevapTable && cevapTable.length}
          paginate={paginate}
          sayfaAdi="yesans"
        />
      </div>
    </div>
  );
};

export default CevaplarPage;
