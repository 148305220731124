import React from 'react'
import { useNavigate } from "react-router-dom";

const Booking = () => {
  const navigate = useNavigate()

  return (
    <section id='book-food'>
      <div className='book-food'>
        <div className='container book-food-text'>
          <div className='row text-center'>
            <div className='col-lg-9 col-md-12'>
            <h2>Hukuksal Tüm Soru ve Sorunlarınız İçin</h2>
            </div>
            <div className='col-lg-3 col-md-12 mt-lg-0 mt-4'>
              <button className='main-btn' onClick={() => navigate("/app")}>Bizimle İletişime Geçin..</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Booking