import React from "react";
import { FaBackspace } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import paytrLogo from "../../assets/paytr.png";
import iyzicoLogo from "../../assets/iyzico.png";
import { useGetSoruByIdQuery } from "../../redux/web/soruSlice";
import { useSelector } from "react-redux";
import { useGetMusteriByIdQuery } from "../../redux/web/musteriSlice";
import { toast } from "react-toastify";

const PayCardPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  let userID = hukukUser.userID;
  let soruID = params.id;

  const { data: editSoru } = useGetSoruByIdQuery(soruID);
  const { data: musteriTable, isLoading } = useGetMusteriByIdQuery(userID);

  const payTROdemeGo = () => {
    if ((isLoading && !musteriTable) || (musteriTable && !musteriTable.idCartNo)) {
      toast.error("Ödeme Yapmadan Önce Lütfen Üyelik Bilgileriniz Tam Doldurunuz", {
        autoClose: 5000,
      });
      navigate("/app/uprofil");
    } else {
      navigate(`/app/odeme/paytr/${soruID}`);
    }
  };

  const iyzicoOdemeGo = () => {
   // toast.error("Sistem henüz aktif edilemiştir. Lütfen PayTR ile devam ediniz..")
    if ((isLoading && !musteriTable) || (musteriTable && !musteriTable.idCartNo)) {
      toast.error("Ödeme Yapmadan Önce Lütfen Üyelik Bilgileriniz Tam Doldurunuz", {
        autoClose: 5000,
      });
      navigate("/app/uprofil");
    } else {
      navigate(`/app/odeme/iyzico/${soruID}`);
    }
  };

  return (
    <div className="paycard">
      <button
        className="btn btn-outline-danger col-lg-2 col-md-3 col-sm-4"
        onClick={() => navigate(-1)}
      >
        <FaBackspace /> Geri Dön
      </button>
      <hr />
      <section className="paycard-section">
        <div className="d-flex flex-row col-12">
          <label htmlFor="criter" className="form-label">
            <strong>Danışma Konusu :</strong>
            <div>
              <span>{editSoru && editSoru.kategori}</span>
              {" / "}
              <strong className="kirmizi">{editSoru && editSoru.baslik}</strong>
            </div>
          </label>
        </div>
        <div className="d-flex flex-row col-12">
          <label htmlFor="criter" className="form-label">
            Danışma Ücreti
            <div>
              <strong className="kirmizi">
                {Number(editSoru && editSoru.tutar).toLocaleString("tr-TR")}
              </strong>
            </div>
          </label>
        </div>
        <hr />
        <div className="d-flex row col-12 gap-1">
          <div
            className="d-flex col col-lg-3 col-md-4 col-sm-5"
            onClick={() => payTROdemeGo()}
          >
            <div className="card_box">
              <img
                className="image-fluid mb-2"
                src={paytrLogo}
                alt=""
                style={{ width: "200px" }}
              />
              <h4 className="mt-2 azgolgeli text-center">PayTR İle Öde</h4>
            </div>
          </div>
          <div
            className="d-flex col col-lg-3 col-md-4 col-sm-5"
            onClick={() => iyzicoOdemeGo()}
          >
            <div className="card_box">
              <img
                className="image-fluid mb-2"
                src={iyzicoLogo}
                alt=""
                style={{ width: "200px" }}
              />
              <h4 className="mt-2 azgolgeli text-center">iYZiCO İle Öde</h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PayCardPage;
