import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import sacomStore from "./redux/sacomStore";
import i18next from "./i18next";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import { PrimeReactProvider } from "primereact/api";
import {RouterProvider} from "react-router-dom"
import { webRouter } from "./Routes";
import "./index.css"
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <I18nextProvider i18n={i18next}>
      <Provider store={sacomStore}>
        <PrimeReactProvider>
          <RouterProvider router={webRouter} />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </PrimeReactProvider>
      </Provider>
    </I18nextProvider>
  </HelmetProvider>
);

reportWebVitals();
