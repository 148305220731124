import React, { useState } from "react";
import { useGetUsersQuery } from "../../redux/auth/userApiSlice";
import Spinner from "../../web/Spinner";
import moment from "moment";
import { Paginator } from "primereact/paginator";
import AProfilPage from "./AProfilPage";

const AdminProfil = () => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);
  const { data: usersTable, isLoading } = useGetUsersQuery();

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <AProfilPage />
      <hr />
      <h2>Kullanıcılar</h2>
      <div className="bottom-data">
        <div className="orders">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Adı Soyadı</th>
                <th>Email</th>
                <th>Telefon</th>
                <th>Kayıt Tarihi</th>
              </tr>
            </thead>
            {usersTable && usersTable.length > 0 && (
              <tbody>
                {usersTable.slice(first, first + rows).map((user, index) => (
                  <tr key={index}>
                    <td>{user.id}</td>
                    <td>{user.kullaniciAdi}</td>
                    <td>{user.kullaniciEmail}</td>
                    <td>{user.kullaniciTelefon}</td>
                    <td>{moment(user.baslangicTarihi).format("DD.MM.YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          <div className="card paginator">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={usersTable && usersTable.length}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfil;
