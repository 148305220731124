import React, { useState, useEffect } from "react";
import { FaBookReader } from "react-icons/fa";

const DosyaRead = ({ fileOku }) => {
  const [show, setShow] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    handleResize(); // Sayfa ilk yüklendiğinde boyutu kontrol etmek için
    window.addEventListener("resize", handleResize); // Ekran boyutu değiştiğinde kontrol etmek için

    return () => window.removeEventListener("resize", handleResize); // Temizleme
  }, []);

  const dosyaRead = () => {
    setShow("show");
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-outline-warning col-lg-2 col-md-3 col-4 col"
        onClick={() => dosyaRead()}
        style={{ width: "max-content", marginLeft: "-10px" }}
      >
        <FaBookReader /> Soruyu Oku
      </button>

      <div
        className={`modal fade ${show}`}
        id="exampleModalLong"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        style={{
          display: `${show === "show" ? "block" : "none"}`,
          marginLeft: isSmallScreen ? "55px" : "",
          width: isSmallScreen ? "380px" : "",
        }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{
              height: !isSmallScreen && "600px",
              width: !isSmallScreen && "800px",
            }}
          >
            <div className="modal-header justify-content-between">
              <strong>Soru İçeriği</strong>
              <button
                type="button"
                className="close btn btn-outline-danger"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShow("")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                background: "#EEEEEE",
                overflowY: "scroll",
                height: isSmallScreen ? "680px" : "",
                marginLeft: isSmallScreen ? "5px" : "",
              }}
            >
              {fileOku && fileOku.detay}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShow("")}
              >
                Kapat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DosyaRead;
