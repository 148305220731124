import React from "react";
import Insights from "./Insights";
import AcilCevapPage from "./AcilCevapPage";
import Reminder from "./Reminder";
import { useGetAdminAylikIstatistikSPQuery } from "../../redux/web/istatistikSlice";
import Spinner from "../../web/Spinner";
import { useSelector } from "react-redux";
import CevapsizPage from "./CevapsizPage";
import CevapUnSentPage from "./CevapUnSentPage";
import CevapSentPage from "./CevapSentPage";
import GenelAciklama from "./GenelAciklama";

const AdminDashboard = () => {
  const { aktifPageName } = useSelector((state) => state.sacomAuth);
  const { data: veriTable, isLoading } = useGetAdminAylikIstatistikSPQuery();
  let dynamicComponent = null;

  switch (aktifPageName) {
    case "AcilCevapPage":
      dynamicComponent = <AcilCevapPage />;
      break;
    case "CevapsizPage":
      dynamicComponent = <CevapsizPage />;
      break;
    case "CevapUnSentPage":
      dynamicComponent = <CevapUnSentPage />;
      break;
    case "CevapSentPage":
      dynamicComponent = <CevapSentPage />;
      break;
    default:
      dynamicComponent = <GenelAciklama />;
      break;
  }

  return (
    <>
      {isLoading && <Spinner />}
      <Insights veriTable={veriTable} />
      <div className="bottom-data">
        <div className="d-flex row col-12 gap-2">
          <div className="col-lg-5 col-md-6 col-12">
            <Reminder veriTable={veriTable} />
          </div>
          <div className="col-lg-6 col-md-6 col-12">{dynamicComponent}</div>
        </div>
      </div>
      <div className="mt-5 mb-5"> </div>
    </>
  );
};

export default AdminDashboard;
