import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaBackspace, FaPrint } from "react-icons/fa";
import { useGetCevapGetirBySoruIdQuery } from "../../redux/web/cevapSlice";
import Spinner from "../../web/Spinner";

const CevapBakPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const soruID = params.id;
  const { data: editCevap, isLoading } = useGetCevapGetirBySoruIdQuery(soruID);

  return (
    <div>
      {isLoading && <Spinner />}
      <div className="d-flex flex-row justify-content-between col-lg-4 col-md-8 col-10 gap-1">
        <button
          className="btn btn-outline-danger col-6"
          onClick={() => navigate(-1)}
        >
          <FaBackspace /> Geri Dön
        </button>
        <button
          className="btn btn-outline-primary col-6"
          onClick={() => navigate(`/app/soru/cprint/${soruID}`)}
        >
          <FaPrint /> Yazdır
        </button>
      </div>
      <hr className="my-2" />
      <div className="mb-2 col-12 d-flex row">
        <div className="col-12 mt-1 d-flex row">
          <div className="col-md-3">
            <label htmlFor="baslik" className="form-label">
              Başlık Kategorisi
            </label>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <strong>{editCevap && editCevap.baslik}</strong>
          </div>
        </div>
        <div className="col-12 mt-1 d-flex row">
          <div className="col-md-3">
            <label htmlFor="baslik" className="form-label">
              Konu Başlığı
            </label>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <strong>{editCevap && editCevap.baslik}</strong>
          </div>
        </div>
        <div className="col-12 mt-1 d-flex justify-content-between">
          <div className="col-md-3 justify-content-between d-flex">
            <label htmlFor="detayliAciklama" className="form-label text-orange">
              <strong>Hukuk Bürosu Cevabı</strong>
            </label>
          </div>
        </div>
        <div className="col-12 mt-1 d-flex">
          <textarea
            className="form-control"
            id="detayliAciklama"
            name="detay"
            value={editCevap && editCevap.cevap}
            readOnly
            placeholder="Detaylı Açıklama..."
            style={{
              minHeight: "200px",
              height: "450px",
              resize: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CevapBakPage;
