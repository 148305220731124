import React, { useEffect, useState } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaBackspace,
  FaHandPointDown,
} from "react-icons/fa";
import bankaLogo from "../../assets/denizbank.png";
import UploadDekont from "./UploadDekont";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTutaryIdQuery } from "../../redux/web/fiyatSlice";
import Spinner from "../../web/Spinner";
import gifLogo from "../../assets/dikkat.gif";
import { Dialog } from "primereact/dialog";

const PayEFTPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  let soruID = params.id;
  const { data: editTable, isLoading } = useGetTutaryIdQuery(soruID);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible && editTable === null) {
      setVisible(true);
    }
  }, [visible, editTable]);

  return (
    <div className="eft">
      {isLoading && <Spinner />}
      <button
        className="btn btn-outline-danger col-lg-2 col-md-3 col-sm-4"
        onClick={() => navigate(-1)}
      >
        <FaBackspace /> Geri Dön
      </button>
      <hr />
      <section className="eft-section">
        <div className="text-center">
          <img
            className="eft_image"
            src={bankaLogo}
            alt="çanakkale en iyi avukat selami akar"
            style={{ width: "200px" }}
          />
          <div className="d-flex row mb-4">
            <span>
              <strong>Selami AKAR</strong>
            </span>
            <span>
              <strong>Çanakkale</strong> Şubesi
            </span>
            <span>
              IBAN :<strong>TR16 0013 4000 0113 1287 1000 01</strong>
            </span>
          </div>
          <hr className="my-1" />
          <div className="d-flex justify-content-center row">
            <img src={gifLogo} alt="" style={{ width: "200px" }} />
            <small style={{ marginLeft: "10px", marginRight: "20px" }}>
              Aşağıdaki Danışma Konusunu Ödeme Açıklamasına Yazınız.{" "}
              <strong>EFT</strong>'yi yaptıktan sonra havale dekontunuzu
              <strong>
                {" "}
                <FaAngleDoubleLeft color="red" />
                Ödeme Belgesi
                <FaAngleDoubleRight color="red" />{" "}
              </strong>{" "}
              olarak sisteme yükleyiniz.
            </small>
            <FaHandPointDown
              className="mt-3"
              color="darkred"
              style={{ fontSize: "40px" }}
            />
          </div>
        </div>
        <hr className="py-1" />
        <div className="cekim_info">
          <h5 className="text-center">Banka Çekim İşlem Bilgileri</h5>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Adı Soyadı
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.adiSoyadi}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                TC Kimlik No
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.tckimlik}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Soru Kategorisi
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.kategori}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Soru Başlığı
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.baslik}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Danışma Ücreti
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.tutar}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Adresiniz
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.adres}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                İl / Ülke
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>
                  {editTable && editTable.il} / {editTable && editTable.ülke}
                </strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                E-Posta
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.email}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Telefonunuz
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.telefon}</strong>
              </span>
            </div>
          </div>
        </div>
        <hr className="py-1" />
        <UploadDekont />
      </section>
      <Dialog
        header="Önemli Uyarı"
        headerStyle={{ background: "darkred", textAlign: "center", color: "white", height: "60px"}}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <p className="m-5 text-center">
          <span>Ödeme İşlemleri İçin Gerekli Kayıtlarınızda Eksikler Olduğu Tespit Edildi.</span>
          <p>Lütfen Üyelik Bilgilerini Tamamlayınız.</p>
          <p><button className="btn btn-primary"
          onClick={() => navigate("/app/uprofil")}
          >Üyelik Sayfası İçin Tıklayınız..</button></p>
        </p>
      </Dialog>
    </div>
  );
};

export default PayEFTPage;
