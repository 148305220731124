import { DOVIZ_URL } from '../constants';
import apiSlice from '../apiSlice';

export const dovizApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDovizs: builder.query({
      query: () => ({
        url: DOVIZ_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['tbldoviz'],
    }),
    postDovizFromTCMB: builder.mutation({
        query: () => ({
          url: `${DOVIZ_URL}`,
          method: 'POST',
        }),
        invalidatesTags: ['tbldoviz'],
      }),
  }),
});

export const {
  useGetDovizsQuery,
  usePostDovizFromTCMBMutation
} = dovizApiSlice;