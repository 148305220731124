import React from "react";
import { useSelector } from "react-redux"
import logo from "../assets/logo.webp";

const MedeniHukuk = () => {
  const { webData } = useSelector((state) => state.sacomAuth);

  return (
        <section id="ceza">
          <div className="ceza-section wrapper">
            <div className="container">
            <div className="mt-3 mb-5  text-center">
            <img src={logo} alt="" style={{ width: "100px" }} />
          </div>{" "}
              <div className="row align-items-center">
                <h2 className="text-center mb-2">Medeni Hukuk Hakkında</h2>
                <div className="col-lg-12 col-md-12 mb-lg-0 mb-5">
                <div
                className="about-content"
                dangerouslySetInnerHTML={{
                  __html:
                    webData && webData.hukuk && webData.hukuk.medeniHukukHTML,
                }}
                style={{ textAlign: "justify" }}
              />
                </div>
              </div>
            </div>
          </div>
        </section>
  );
};

export default MedeniHukuk;
