import React, { useEffect, useState } from "react";
import {
  FaBackspace,
  FaCloudUploadAlt,
  FaFolderOpen,
  FaPrint,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSoruByIdQuery,
  useUploadSoruImageMutation,
} from "../../redux/web/soruSlice";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { SERVERPICT_URL } from "../../redux/constants";

const SoruOkuPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const soruID = params.id;
  const [visible, setVisible] = useState(false);
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { data: editSoru, refetch } = useGetSoruByIdQuery(soruID);
  const [uploadSoruImage] = useUploadSoruImageMutation();
  const [file, setFile] = useState("");
  const [dosya, setDosya] = useState("");

  // Ekran genişliği değiştiğinde güncelle
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  }; 
  useEffect(() => {
    // Component yüklendiğinde ve ekran genişliği değiştiğinde updateScreenWidth fonksiyonunu çağır
    window.addEventListener("resize", updateScreenWidth);
    // useEffect içinde temizleme işlemi
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "application/pdf" ||
        selectedFile.type === "application/msword" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setFile(URL.createObjectURL(selectedFile));
      setDosya(selectedFile);
    } else {
      toast.error("Lütfen bir JPEG, PNG, PDF veya DOC(x) dosyası seçin.");
      e.target.value = null;
    }
  };

  const uploadFileHandler = async () => {
    try {
      const soruID = editSoru.id;
      const userID = hukukUser.userID;
      const token = hukukUser && hukukUser.token;
      const formData = new FormData();
      formData.append("file", dosya);
      // Diğer verileri de formData'ya ekleyebilirsiniz, örneğin:
      formData.append("soruID", soruID);
      formData.append("userID", userID);
      formData.append("token", token);
      // uploadSoruImage fonksiyonuna formData'yı doğrudan iletebilirsiniz.
      const res = await uploadSoruImage(formData).unwrap();
      toast.success(res.message);
      refetch();
      setVisible(false)
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const belgeGoster = () => {
    const fileUrl = `${SERVERPICT_URL}${editSoru && editSoru.belge}`;
    // Dosyayı indirmek için yeni bir pencere aç
    window.open(fileUrl, "_blank");
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between col-lg-6 col-md-8 col-10 gap-1">
        <button
          className="btn btn-outline-danger col-lg-4 col-md-4 col-sm-4 col-4"
          onClick={() => navigate("/app/soru")}
        >
          <FaBackspace /> Geri Dön
        </button>
        <button
          className="btn btn-outline-success col-lg-4 col-md-4 col-sm-4 col-4"
          onClick={() => setVisible(true)}
          disabled={editSoru && editSoru.belge !== null}
        >
          <FaCloudUploadAlt /> Soruya İlişkin Belge Yükle
        </button>
        <button
          className="btn btn-outline-primary col-lg-4 col-md-4 col-sm-4 col-4"
          onClick={() => navigate(`/app/soru/print/${soruID}`)}
        >
          <FaPrint /> Yazdır
        </button>
      </div>
      <hr className="my-2" />
      <div className="mb-2 col-12 d-flex row">
        <div className="col-12 mt-1 d-flex row">
          <div className="col-md-3">
            <label htmlFor="kategori" className="form-label">
              Hukuksal Kategori
            </label>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <strong>{editSoru && editSoru.kategori}</strong>
          </div>
        </div>
        <div className="col-12 mt-1 d-flex row">
          <div className="col-md-3">
            <label htmlFor="baslik" className="form-label">
              Başlık Kategorisi
            </label>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <strong>{editSoru && editSoru.baslik}</strong>
          </div>
        </div>
        <div className="col-12 mt-1 d-flex row">
          <div className="col-md-3">
            <label htmlFor="secenek" className="form-label">
              Danışma Tutarı
            </label>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            {editSoru && editSoru.tutar && (
              <div>
                <strong className="sari">
                  {editSoru.tutar.toLocaleString("tr-TR")}
                </strong>{" "}
                {" TL"}
              </div>
            )}
          </div>
        </div>
        <div className="col-12 mt-1 d-flex row">
          <div className="col-md-3">
            <label htmlFor="baslik" className="form-label">
              Konu Başlığı
            </label>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <strong>{editSoru && editSoru.baslik}</strong>
          </div>
        </div>
        <div className="col-12 mt-1 d-flex justify-content-between">
          <div className="col-md-3 justify-content-between d-flex">
            <label htmlFor="detayliAciklama" className="form-label text-orange">
              <strong>Açıklayınız..</strong>
            </label>
          </div>
        </div>
        <div className="col-12 mt-1 d-flex">
          <textarea
            className="form-control"
            id="detayliAciklama"
            name="detay"
            value={editSoru && editSoru.detay}
            readOnly
            placeholder="Detaylı Açıklama..."
            style={{
              minHeight: "200px",
              height: "450px",
              resize: "none",
            }}
          />
        </div>
        {editSoru && (editSoru.belge !== null || editSoru.belge === "") ? (
         <div className="container">
            <button
              className="btn btn-outline-success col-lg-2 col-md-3 col-6 mt-1"
              onClick={() => belgeGoster()}
            >
              <FaFolderOpen /> Sorula İlişkin Belgenizi İnceleyin
            </button>
          </div>
        ): null}
      </div>
      <div className="mt-5"> </div>
      <Dialog
        header="Sorularınıza Ek Olarak Evrak Yükleme"
        headerStyle={{ textAlign: "center" }}
        visible={visible}
        style={{
          width: `${
            screenWidth < 991 ? "75vw" : screenWidth < 768 ? "95vw" : "50vw"
          }`,
          marginLeft: "35px",
        }}
        onHide={() => setVisible(false)}
      >
        <p className="m-5 evrak_yukleme_modul">
          <div className="uploads">
            <label htmlFor="yukleme">
              Evrak Seçiniz (*.PNG, *.JPG, *.PDF, *.DOC(x))
            </label>
            <input
              className="form-control"
              type="file"
              name="resimURL"
              id="resimURL"
              variant="outlined"
              onChange={(e) => handleFileChange(e)}
              accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />

            <button
              className="btn btn-outline-primary mt-2 btn-block"
              onClick={() => uploadFileHandler()}
            >
              <FaCloudUploadAlt /> Gönder
            </button>
            <div className="resim_gosterme">
              {file && (
                <section
                  className="dosya-gosterim"
                  style={{ border: "1px solid #ccc", marginTop: "5px" }}
                >
                  {file.type === "application/pdf" ? (
                    <iframe
                      src={file}
                      title="PDF önizleme"
                      style={{ width: "100%", height: "500px" }}
                    />
                  ) : file.type === "application/msword" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <embed
                      src={file}
                      type="application/msword"
                      style={{ width: "100%", height: "500px" }}
                    />
                  ) : (
                    <img
                      src={file}
                      alt={file.name}
                      className="image-fluid dosya_upload_img"
                    />
                  )}
                </section>
              )}
            </div>
          </div>
        </p>
      </Dialog>
    </div>
  );
};

export default SoruOkuPage;
