import React, { useEffect } from "react";
import {toast} from "react-toastify"
import { usePostDovizFromTCMBMutation } from "../redux/web/dovizSlice";

function Doviz() {
  const [postDovizFromTCMB] = usePostDovizFromTCMBMutation()


  // Otomatik güncelleme için bir işlev
  const updateCurrency = async () => {
    try {
        await postDovizFromTCMB().unwrap();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
  };

  useEffect(() => {
    // Bileşen yüklendiğinde bir kez çağır
    updateCurrency();
    // Her yarım saatte bir tekrar çağır
    const interval = setInterval(() => {
      updateCurrency();
    }, 1800000); // 1800000 milisaniye = 30 dakika
    // Cleanup fonksiyonu
    return () => clearInterval(interval);
  }, []); // Boş dizi, useEffect'in sadece bileşen yüklendiğinde çalışmasını sağlar


  return (
    <i
      onClick={updateCurrency}
      style={{ cursor: "pointer", color: "blue" }}
    >
      {"            "}
    </i>
  );
}

export default Doviz;
