import React, { useEffect, useState } from "react";
import {
  useEkleGuncelleSloganLogoAltYaziByIdMutation,
  useGetSloganLogoAltYaziByIdQuery,
} from "../../redux/web/sabitSlice";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Message from "../../web/Message";
import Spinner from "../../web/Spinner";
import { useTranslation } from "react-i18next";

const SloganLogoPage = () => {
  const { t } = useTranslation("translation");
  const [slogan, setSlogan] = useState("");
  const [loading, setLoading] = useState(false);
  const [logoAltYazi, setLogoAltYazi] = useState("");
  const {
    data: sloganTable,
    isLoading,
    error,
    refetch,
  } = useGetSloganLogoAltYaziByIdQuery();
  const [ekleGuncelleSloganLogoAltYaziById, { isLoading: loadingUpdate }] =
    useEkleGuncelleSloganLogoAltYaziByIdMutation();

  useEffect(() => {
    if (sloganTable) {
      setSlogan(sloganTable.slogan);
      setLogoAltYazi(sloganTable.logoAltYazi);
    }
  }, [sloganTable]);

  const submitHandler = async () => {
    try {
      setLoading(true); // Spinner'ı göster
      let data = { slogan, logoAltYazi };
      await ekleGuncelleSloganLogoAltYaziById(data).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toast.success(t("toast.guncellendi"));
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    } finally {
      setLoading(false); // Spinner'ı gizle
    }
  };

  return isLoading ? null : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div className="yonetim_info bg-soft">
      {(loadingUpdate || loading) && <Spinner />}
      <div className="d-flex row gap-2">
        <div className="row d-flex">
          <div className="col col-lg-2 col-md-4 col-4">
            <label htmlFor="slogan" className="form-label">
              Slogan
            </label>
          </div>
          <div className="col col-lg-8 col-md-8 col-8">
            <input
              type="text"
              value={slogan}
              name="slogan"
              onChange={(e) => setSlogan(e.target.value)}
              placeholder="Slogan"
              className="form-control"
            />
          </div>
        </div>
        <div className="row d-flex">
          <div className="col col-lg-2 col-md-4 col-4">
            <label htmlFor="logoAltYazi" className="form-label">
              Logo Alt Yazısı
            </label>
          </div>
          <div className="col col-lg-8 col-md-8 col-8">
            <input
              type="text"
              value={logoAltYazi}
              name="logoAltYazi"
              onChange={(e) => setLogoAltYazi(e.target.value)}
              placeholder="Logo Alt Yazısı"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-10">
        <button
          className="mt-2 btn btn-outline-success btn-block"
          onClick={submitHandler}
        >
          <FaSave /> Kaydet
        </button>
      </div>
    </div>
  );
};

export default SloganLogoPage;
