import React from "react";
import { useGetUserIstatistikSonUcSPQuery } from "../../redux/web/istatistikSlice";
import Spinner from "../../web/Spinner";
import moment from "moment";
import { truncateWord } from "../Utility";
import { useSelector } from "react-redux";

const Reminder = () => {
  const {hukukUser} = useSelector(state => state.sacomAuth)
  const userID = hukukUser && hukukUser.userID
  const {
    data: sonucTable,
    isLoading,
  } = useGetUserIstatistikSonUcSPQuery(userID);

  const durumDuzeltme = (soru) => {
    let cevapOK = soru.cevapOK;
    let cevapVarmi = soru.cevapExists;
    let durumYaz = "";
    let tdDurum = "";
    if (!cevapOK && (cevapVarmi === 1 || cevapVarmi === 0)) {
      durumYaz = "not-completed";
      tdDurum = "no-entry";
    } else {
      durumYaz = "completed";
      tdDurum = "check-circle";
    }

    return { durumYaz, tdDurum };
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="reminders">
        <div className="header">
          <i className="bx bx-note"></i>
          <h3>Son Sorularım</h3>
        </div>

        <ul className="task-list">
          {sonucTable && sonucTable.length > 0 && (
            <>
              {sonucTable.map((sonuc, index) => {
                const { durumYaz, tdDurum } = durumDuzeltme(sonuc);
                return (
                  <li key={index} className={durumYaz}>
                    <div className="task-title">{" "}
                      <i className={`bx bx-${tdDurum}`}>{" "}</i>
                      <span>
                        {sonuc && moment(sonuc.tarih).format("DD.MM.YYYY")}
                        {" / "}
                        {sonuc && sonuc.kategori}
                        {" / "}
                        {sonuc && truncateWord(sonuc.baslik, 50)}
                      </span>
                    </div>
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </li>
                );
              })}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default Reminder;

/*
<li className="not-completed">
          <div className="task-title">
            <i className="bx bx-x-circle"></i>
            <p>Play Footbal</p>
          </div>
          <i className="bx bx-dots-vertical-rounded"></i>
        </li>
*/
