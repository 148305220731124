import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import hukukLogo from "../assets/logo.webp";
import { useSelector } from "react-redux";

const Banner = () => {
  const navigate = useNavigate();
  const [topMargin, setTopMargin] = useState("mt-2");
  const { webData } = useSelector((state) => state.sacomAuth);
  const curYear = new Date().getFullYear()
  const kalan = parseInt(curYear) - parseInt(1999)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setTopMargin("mt-5");
      } else {
        setTopMargin("mt-2");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section id="home">
      <div className="container-fluid px-0 top-banner">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="col-lg-3 col-md-6 buro_logo mb-2 banner_slogan_logo">
                <img
                  title="Bizimle İletişime Geçin .."
                  src={hukukLogo}
                  alt=""
                  style={{ width: "150px" }}
                  onClick={() => navigate("/app")}
                />
              </div>
              <div className={`col-lg-6 col-md-6 ${topMargin}`}>
                <h1 className="golgeli col-sm-10 col-10">
                  {webData && webData.setup && webData.setup.slogan}
                </h1>
                <div className={`mobile_bottom_banner ${topMargin}`}>            
                  <h3>
                    {webData && webData.setup && kalan + " Yıllık Tecrübe ile " + webData.setup.logoAltYazi}
                  </h3>
                  <div className="mt-3">
                    <button
                      className="main-btn"
                      onClick={() => navigate("/app")}
                    >
                      Danışmak İçin Bizimle İletişime Geçin ..
                      <i className="fas fa-shopping-basket p3"></i>
                    </button>
                  </div>
                  {/* <div className="sice_text">
                  <span>25 Yıllık Tecrübe</span>
                  </div> */}
                  <div className="mt-1">
                    <span className="avukat_imza">Avukat Selami Akar</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
