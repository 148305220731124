import React, { useEffect } from "react";
import NavBar from "../NavBar";
import SideBar from "./SideBar";
import Header from "../Header";
import { Outlet, useLocation } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import { useDispatch } from "react-redux";
import { sideBarClose } from "../../redux/auth/authSlice";

function AdminPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDefaultComponentNeeded = location.pathname === "/app" && !location.state;
 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(sideBarClose(true));
      } else {
        dispatch(sideBarClose(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
      <div className="webbody">
        <SideBar />
        <div className="content">
          <NavBar />
          <main>
            <Header />
            {isDefaultComponentNeeded ? <AdminDashboard /> : <Outlet />}
          </main>
        </div>
      </div>
  );
}

export default AdminPage;
