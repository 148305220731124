import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Av. Selami Akar",
  description: "Medeni Hukuk ve Ceza Hukuku alanlarında cevap veriyoruz Tüm Hukuki ihtiyaçlarınız için Çanakkale Avukatlık Bürosu Çanakkale Avukat Av. Selami Akar ile deneyimli ve güvenilir destek Çanakkale En İyi Avukat Selami Akar - Anasayfa - Çanakkale En İyi Avukat - Av. Selami Akar",
  keywords:
    "avukat, çanakkale, hukuk, miras, davası, en iyi, tapu, tescil, kadastro, medeni, boşanma, usül, velayet, nafaka, tedbir, ihtiyadi, haciz, tazminatı, manevi, maddi, cezai, şart, sözleşme, arabulucu, uzlaştırma, izalei, şüyuu, ortaklığın, giderilemsi, satış, suretiyle, kira, kiracılık, kontratı, alım, satım, trampa, takas, beyanı, reddi, mahrum, bırakılma, ıskat, soybağı, nesep, babalık, iptal, tam yargı, belgesinin, alınamsı, tahliye, uyuşmazlık, kıta, mülkiyeti, vesayet, kayum, kayyum, yönetici, temsil, kişilik, altına, sona, atanma, seçer, hakim, hakimi, tereke, tenkis, şirketi, mümessil, defter, artırım, tespit, tevdi, mahal, tayini, alacak, menfi, müspet, olası, muhtemel, mevcut, önalım, ger, istirdat, rücü, ödenenin, geri, istenmesi, teslim, haksız, fiil, kötüniyet, ispatı, iyiniyetin, suistimali, ayıplı, iadesi, misliyle, değiştirilmesi, ayıba, karşı, tekeffül, tefekkülün, semen, iadesi, bedelin, tezyidi, nüfus, kadının, kağıdının, düzeltimesi, isim, değiştirme, yaş, kazi, rüşt, evlenme, delil, istemesi, el, kamulaştırmasız, müdahele, meni, bedeli, tespiti, ipka, muvazaa, vasiyetname, edilir, mi, vasinin, görevleri, azli, değiştirilmesi, iade, şufa, önalım, vefa, iştira, geçit, irtifakı, ecri, ortaklığı, hisse, tüzüğü, ana, kambiyo, senedi, senedine, dayalı, icrası, unsurları, vasfı, bono, takibi, değerli, kağıt, kıymetli, evrak, çek, senet, tahakkukta, paranın, işçi, kıdem, yıllık, izin, milli, dini, günlere, mesai, ihtar, akdinin, feshi, rücu",
};

export default Meta;
