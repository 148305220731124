import React, { useEffect, useState } from "react";
import {
  useCreateFiyatMutation,
  useGetFiyatByIdQuery,
} from "../../redux/web/fiyatSlice";
import { FaBackspace, FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Spinner from "../../web/Spinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { navIDChange, reload } from "../../redux/auth/authSlice";

const FiyatlandirmaPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const { navID, reloadPage } = useSelector((state) => state.sacomAuth);
  const [fiyatData, setFiyatData] = useState({
    kategoriAdi: "",
    baslik: "",
    tck: "",
    aciklama: "",
    fiyat: "",
  });
  const { kategoriAdi, baslik, tck, aciklama, fiyat } = fiyatData;

  const {
    data: editTable,
    refetch,
  } = useGetFiyatByIdQuery(navID);
  const [createFiyat, { isLoading: loadingRecord }] = useCreateFiyatMutation();

  useEffect(() => {
    if (reloadPage && navID !== "") {
      refetch();
    }
  }, [reloadPage, navID, refetch]);

  useEffect(() => {
    if (editTable && editTable.id && kategoriAdi === "") {
      setFiyatData((prevState) => ({
        ...prevState,
        kategoriAdi: editTable.kategoriAdi,
        baslik: editTable.baslik,
        tck: editTable.tck,
        aciklama: editTable.aciklama,
        fiyat: editTable.fiyat,
      }));
    }
  }, [editTable, kategoriAdi]);

  const submitHandler = async () => {
    let data = { kategoriAdi, baslik, tck, aciklama, fiyat };
    try {
      await createFiyat(data).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success(t("toast.guncellendi"));
      dispatch(reload(false));
      setFiyatData((prevState) => ({
        ...prevState,
        kategoriAdi: "",
        baslik: "",
        tck: "",
        aciklama: "",
        fiyat: "",
      }));
      dispatch(navIDChange(""));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const vazgecKaydet = async () => {
    dispatch(reload(false));
    setFiyatData((prevState) => ({
      ...prevState,
      kategoriAdi: "",
      baslik: "",
      tck: "",
      aciklama: "",
      fiyat: "",
    }));
    dispatch(navIDChange(""));
  };

  const onChange = (e) => {
    setFiyatData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="yonetim_info bg-soft">
      {loadingRecord && <Spinner />}
      <div className="d-flex row gap-2">
        <div className="d-flex justify-content-start col-12">
          <label htmlFor="kategoriAdi" className="col-lg-2 col-md-4 col-4 mt-2">
            Kategori Adı
          </label>
          <div className="col-lg-10 col-md-8 col-8 ">
            <input
              type="text"
              value={kategoriAdi}
              name="kategoriAdi"
              onChange={onChange}
              placeholder="Kategori Adı"
              className="form-control"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-12">
          <label htmlFor="baslik" className="col-lg-2 col-md-4 col-4 mt-2">
            Başlık
          </label>
          <div className="col-lg-10 col-md-8 col-8 ">
            <input
              type="text"
              value={baslik}
              name="baslik"
              onChange={onChange}
              placeholder="Başlık"
              className="form-control"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-12">
          <label htmlFor="tck" className="col-lg-2 col-md-4 col-4 mt-2">
            T.C.K. No
          </label>
          <div className="col-lg-2 col-md-2 col-5 ">
            <input
              type="text"
              value={tck}
              name="tck"
              onChange={onChange}
              placeholder="T.C.K. No"
              className="form-control"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-12">
          <label htmlFor="aciklama" className="col-lg-2 col-md-4 col-4 mt-2">
            Alt Detay
          </label>
          <div className="col-lg-10 col-md-8 col-8 ">
            <input
              type="text"
              value={aciklama}
              name="aciklama"
              onChange={onChange}
              placeholder="Alt Detay"
              className="form-control"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-12">
          <label htmlFor="fiyat" className="col-lg-2 col-md-4 col-4 mt-2">
            Danışma Ücreti
          </label>
          <div className="col-lg-2 col-md-2 col-5 ">
            <input
              type="text"
              value={fiyat}
              name="fiyat"
              onChange={onChange}
              placeholder="Ücret"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start col-12 gap-2">
        <button
          className="mt-2 btn btn-outline-success col-6"
          onClick={submitHandler}
        >
          <FaSave /> Kaydet
        </button>
        <button
          className="mt-2 btn btn-outline-secondary col-6"
          onClick={() => vazgecKaydet()}
        >
          <FaBackspace /> Vazgeç
        </button>
      </div>
    </div>
  );
};

export default FiyatlandirmaPage;
