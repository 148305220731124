import { UYELIK_URL } from "../constants";
import apiSlice from "../apiSlice";

export const uyelikSoruApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUyelikSorus: builder.query({
      query: () => ({
        url: UYELIK_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbluyesorulari"],
    }),
    getUyelikSoruById: builder.query({
      query: (id) => ({
        url: `${UYELIK_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createUyelikSoru: builder.mutation({
      query: (data) => ({
        url: `${UYELIK_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tbluyesorulari"],
    }),
    deleteUyelikSoruById: builder.mutation({
      query: (id) => ({
        url: `${UYELIK_URL}/${id}`,
        method: "DELETE",
      }),
      providesTags: ["tbluyesorulari"],
    }),
    getUyelikSoruByUserId: builder.query({
      query: (id) => ({
        url: `${UYELIK_URL}/user/${id}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblusers"],
    }),
    updateUyelikSoruByUserId: builder.mutation({
      query: (data) => ({
        url: `${UYELIK_URL}/usersoruchange`,
        method: "POST",
        body: data,
      }),
      providesTags: ["tblusers"],
    }),
  }),
});

export const {
  useGetUyelikSorusQuery,
  useGetUyelikSoruByIdQuery,
  useCreateUyelikSoruMutation,
  useDeleteUyelikSoruByIdMutation,
  useGetUyelikSoruByUserIdQuery,
  useUpdateUyelikSoruByUserIdMutation,
} = uyelikSoruApiSlice;
