import React, { useEffect, useRef, useState } from "react";
import { FaBackspace } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useIyzicoOdemePageMutation } from "../../redux/web/iyzicoSlice";
import { toast } from "react-toastify";
import { useGetTutaryIdQuery } from "../../redux/web/fiyatSlice";
import Spinner from "../../web/Spinner";

const IyzicoOdeme = () => {
  const navigate = useNavigate();
  const params = useParams();
  const inputRef = useRef(null);
  let soruID = params.id;
  const { data: editTable, isLoading: soruLoading } =
    useGetTutaryIdQuery(soruID);

  const [iyzicoOdemePage, { data: iyziTable, isLoading }] =
    useIyzicoOdemePageMutation();

  const [paymentCard, setPaymentCard] = useState({
    cardHolderName: "",
    cardNumber: "",
    expireMonth: "",
    expireYear: "",
    cvc: "",
    registerCard: "0"
  });

  const { cardHolderName, cardNumber, expireMonth, expireYear, cvc } =
    paymentCard;

  const handleInputChange = (e) => {
    if(e.target.name === "expireYear") {
      setPaymentCard((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else {
          setPaymentCard((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    }

  };

  const handleCardInputChange = (e) => {
    const { name, value } = e.target;
    // Sadece rakamları kabul et ve her 4 karakter arasına bir boşluk ekleyin
    const formattedValue = value
      .replace(/\D/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    // Değişiklikleri kaydet
    setPaymentCard({ ...paymentCard, [name]: formattedValue });
  };

  const yilCheck = () => {
    if((expireYear && expireYear.length !== 4) || (expireYear && parseInt(expireYear) < 2024)) {
      toast.error("Lütfen Kredi Kartı Yıl Bilgisini Doğru Giriniz..")
      inputRef.current.focus();
    }
  }

  const odemePageGo = async () => {
    try {
      setPaymentCard((prevState) => ({
        ...prevState,
        cardNumber: cardNumber.replace(" ", "")
      }))
      const buyer = {
        id: editTable.musteriID, 
        name: editTable.adi, 
        surname : editTable.soyAdi, 
        identityNumber: editTable.tckimlik, 
        email: editTable.email, 
        gsmNumber: editTable.telefon, 
        registrationAddress: editTable.adres, 
        city: editTable.il, 
        country: editTable.ulke,
        contactName: editTable.adiSoyadi
      }
      const price = editTable.tutar
      const basketItems = [{
        id: editTable.soruID,
        price: editTable.tutar,
        name: editTable.baslik,
        category1: editTable.kategori,
        itemType: "VIRTUAL"
      }]

      const data = {buyer, price, basketItems, paymentCard}
      const response = await iyzicoOdemePage(data).unwrap();
      if (response && response.isSuccess) {
        console.log({ response });
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if(iyziTable && iyziTable.status === "failure") {
      toast.error(iyziTable.errorMessage, {autoClose: 5000})
      navigate(-1)
    }
  },[iyziTable, navigate])

  useEffect(() => {
    if(iyziTable && iyziTable.status === "success") {
      toast.error("Ödemeniz Başarıyla Sonuçlanmıştır", {autoClose: 5000})
      navigate("/app/soru")
    }
  },[iyziTable, navigate])

  return (
    <div>
      {(soruLoading || isLoading) && <Spinner />}
      <button
        className="btn btn-outline-danger col-lg-2 col-md-3 col-sm-4"
        onClick={() => navigate(-1)}
      >
        <FaBackspace /> Geri Dön
      </button>
      <hr />
      <div>
        <div className="col-12">
          <h5 className="text-center">Banka Çekim İşlem Bilgileri</h5>
          <hr className="my-2" />
          <div className="d-flex flex-column">
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Adı Soyadı
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.adiSoyadi}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                TC Kimlik No
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.tckimlik}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Soru Kategorisi
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.kategori}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Soru Başlığı
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.baslik}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Danışma Ücreti
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.tutar}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Adresiniz
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.adres}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                İl / Ülke
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>
                  {editTable && editTable.il} / {editTable && editTable.ülke}
                </strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                E-Posta
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.email}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-2">
              <label htmlFor="adi" className="col-lg-1 col-md-3 col-4">
                Telefonunuz
              </label>
              <span className="col-lg-9 col-md-6 col-8">
                <strong>{editTable && editTable.telefon}</strong>
              </span>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        <div className="col-lg-6 col-md-6 col-12">
          <div className="row mt-2">
            <div className="col-lg-4 col-md-4 col-12">
              <label htmlFor="cardHolderName" className="form-label">
                Kart Sahibinin Adı
              </label>
            </div>
            <div className="col-lg-8 col-md-8 col-11">
              <input
                type="text"
                className="form-control"
                id="cardHolderName"
                name="cardHolderName"
                value={cardHolderName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-4 col-md-4 col-12">
              <label htmlFor="cardNumber" className="form-label">
                Kart Numarası
              </label>
            </div>
            <div className="col-lg-8 col-md-8 col-11">
              <input
                type="text"
                className="form-control"
                id="cardNumber"
                name="cardNumber"
                value={cardNumber}
                onChange={handleCardInputChange}
                maxLength={19}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-4 col-md-4 col-12">
              <label htmlFor="expireMonth" className="form-label">
                Son Kullanma Tarihi (12/2030)
              </label>
            </div>
            <div className="col-lg-8 col-md-8 col-11">
              <div className="d-flex flex-row">
                <div className="col-lg-2 col-md-2 col-2">
                  <input
                    type="text"
                    className="form-control"
                    id="expireMonth"
                    name="expireMonth"
                    value={expireMonth}
                    onChange={handleInputChange}
                    maxLength={2}
                  />
                </div>
                <div className="mt-2">{" / "}</div>
                <div className="col-lg-2 col-md-2 col-2">
                  <input
                    type="text"
                    className="form-control"
                    id="expireYear"
                    name="expireYear"
                    value={expireYear}
                    onChange={handleInputChange}
                    maxLength={4}
                    onBlur={() => yilCheck()}
                    ref={inputRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-4 col-md-4 col-12">
              <label htmlFor="cvc" className="form-label">
                CVC Kodu
              </label>
            </div>
            <div className="col-lg-2 col-md-2 col-4">
              <input
                type="text"
                className="form-control cvc_length"
                id="cvc"
                name="cvc"
                value={cvc}
                onChange={handleInputChange}
                maxLength={3}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-11 mt-3">
          <button
            className="btn btn-outline-success btn-block"
            onClick={odemePageGo}
          >
            Ödeme Yap
          </button>
        </div>
        <div className="mt-3">
          <span>
            <i>Not : </i>
            <strong>
              Uygulamamız kredi kartı bilgilerinizi asla kayıt etmez. Güvenle
              kullanabilirsiniz.
            </strong>
          </span>
        </div>
      </div>
      <div className="mt-5 mb-5"> </div>
    </div>
  );
};

export default IyzicoOdeme;

/*
<div className="col col-6">
          <div className="col-12">
            <div className="mb-3 row">
              <div className="col-4">
                <label htmlFor="cardHolderName" className="form-label">
                  Kart Sahibinin Adı
                </label>
              </div>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id="cardHolderName"
                  name="cardHolderName"
                  value={cardHolderName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-3 row">
              <div className="col-4">
                <label htmlFor="cardNumber" className="form-label">
                  Kart Numarası
                </label>
              </div>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id="cardNumber"
                  name="cardNumber"
                  value={cardNumber}
                  onChange={handleCardInputChange}
                  maxLength={19}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-3 row">
              <div className="col-4">
                <label htmlFor="expireMonth" className="form-label">
                  Son Kullanma Tarihi
                </label>
              </div>
              <div className="col-8 row">
                <div className="col-4">
                  <input
                    type="text"
                    className="form-control"
                    id="expireMonth"
                    name="expireMonth"
                    value={expireMonth}
                    onChange={handleInputChange}
                    maxLength={2}
                  />
                </div>
                {"/"}
                <div className="col-4">
                  <input
                    type="text"
                    className="form-control"
                    id="expireYear"
                    name="expireYear"
                    value={expireYear}
                    onChange={handleInputChange}
                    maxLength={2}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-3 row">
              <div className="col-4">
                <label htmlFor="cvc" className="form-label">
                  CVC Kodu
                </label>
              </div>
              <div className="col-3">
                <input
                  type="text"
                  className="form-control cvc_length"
                  id="cvc"
                  name="cvc"
                  value={cvc}
                  onChange={handleInputChange}
                  maxLength={3}
                />
              </div>
            </div>
          </div>
        </div>
*/
