import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetSorusQuery } from "../../redux/web/soruSlice";
import Message from "../../web/Message";
import Spinner from "../../web/Spinner";
import { truncateWord } from "../Utility";
import { toast } from "react-toastify";
import { Paginator } from "primereact/paginator";

const SorularPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const { data: soruTable, isLoading, error } = useGetSorusQuery();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 567) {
        setHide(true);
      } else {
        setHide(false);
      }
    };
    window.addEventListener("resize", handleResize);
    // Unsubscribe event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleNavigate = async (url) => {
    try {
      setLoading(true); // Spinner'ı göster
      // 1 saniye bekle
      await new Promise((resolve) => setTimeout(resolve, 1000));
      navigate(url);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false); // Spinner'ı gizle
    }
  };

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div className="bottom-data sorular">
      {loading && <Spinner />}
      <div className="orders">
        <div className="header">
          <i className="bx bx-receipt"></i>
          <h3>Son Sorular</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <h5>İşlem</h5>
              </th>
              <th>
                <h5>Kategori</h5>
              </th>
              <th style={{ display: `${hide ? "none" : ""}` }}>
                <h5>İçerik</h5>
              </th>
              <th>
                <h5>Detay</h5>
              </th>
            </tr>
          </thead>
          {soruTable && soruTable.length > 0 && (
            <tbody>
              {soruTable.slice(first, first + rows).map((soru, index) => (
                <tr key={index}>
                  <td className="data_td">
                    <button
                      className="btn btn-outline-success"
                      onClick={() => handleNavigate(`edit/${soru.id}`)}
                    >
                      <FaCheck className="tablo_icon" /> Seç
                    </button>
                  </td>
                  <td className="data_td">{soru.kategori}</td>
                  <td
                    className="data_td"
                    style={{ display: `${hide ? "none" : ""}` }}
                  >
                    {truncateWord(soru.baslik, 30)}
                  </td>
                  <td className="data_td">{truncateWord(soru.detay, 30)}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className="card paginator">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={soruTable && soruTable.length}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SorularPage;
