import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaSave } from "react-icons/fa";
import {
  useEkleGuncelleMedeniHukukYaziByIdMutation,
  useGetMedeniHukukYaziByIdQuery,
} from "../../redux/web/sabitSlice";
import Spinner from "../../web/Spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    ["link", "image", "video"],
    [{ color: [] }],
  ],
};

const MedeniSetup = () => {
  const { t } = useTranslation("translation");
  const [value, setValue] = useState("");
  const { data: medeniTable, isLoading } = useGetMedeniHukukYaziByIdQuery();
  const [ekleGuncelleMedeniHukukYaziById, { isLoading: addLoading }] =
    useEkleGuncelleMedeniHukukYaziByIdMutation();

  useEffect(() => {
    if (value === "" && medeniTable && medeniTable.medeniHukukHTML) {
      setValue(medeniTable.medeniHukukHTML);
    }
  }, [value, medeniTable]);

  const duyuruKaydet = async () => {
    try {
      await ekleGuncelleMedeniHukukYaziById({
        medeniHukukHTML: value,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success(t("toast.guncellendi"));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div>
      {(isLoading || addLoading) && <Spinner />}
      <div className="mt-2 web_info row">
        <div className="editor col-lg-6 col-md-6 col-11">
          <div className="text-center">
            <strong className="mt-1">HTML Editor</strong>
          </div>
          <div className="canli_html_editor">
            <ReactQuill
              className="editor-input"
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
              style={{ background: "#F4EEED" }}
              placeholder="HTML destekli CEZA Hukuku yazınızı biçimlendiriniz.."
            />
          </div>{" "}
          <button
            className="btn btn-success btn-block mt-2"
            onClick={() => duyuruKaydet()}
            disabled={!value}
          >
            <FaSave />
             HTML Kaydet
          </button>
        </div>
        <div className="col-lg-6 col-md-6 col-11 html_preview canli_html_editor">
          <strong className="mt-1"> </strong>
          <div
            className="preview"
            dangerouslySetInnerHTML={{ __html: value && value }}
          />
        </div>
      </div>
    </div>
  );
};

export default MedeniSetup;
