import React, { useEffect, useState } from "react";
import { FaBackspace, FaCheck, FaCreditCard, FaMobileAlt, FaReadme } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetSoruByUserIdQuery } from "../../redux/web/soruSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../web/Spinner";
import Message from "../../web/Message";
import { Paginator } from "primereact/paginator";
import { truncateWord } from "../Utility";
import { navIDChange } from "../../redux/auth/authSlice";

const Sorularim = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  const [hide, setHide] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);
  let uID = hukukUser && hukukUser.userID;
  const { data: soruTable, isLoading, error } = useGetSoruByUserIdQuery(uID);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setHide(true);
      } else {
        setHide(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div>
      <button
        className="btn btn-outline-danger col-lg-2 col-md-3 col-sm-4"
        onClick={() => navigate(-1)}
      >
        <FaBackspace /> Geri Dön
      </button>
      <div className="bottom-data sorular">
        <hr />
        <div className="orders">
          <div className="header">
            <i className="bx bx-receipt"></i>
            <h3>Sorularım</h3>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>
                  <span>İşlem</span>
                </th>
                <th style={{ display: `${hide ? "none" : ""}` }}>
                  <span>Kategori</span>
                </th>
                <th style={{ display: `${hide ? "none" : ""}` }}>
                  <span>Başlık</span>
                </th>
                <th>
                  <span>Açıklama</span>
                </th>
              </tr>
            </thead>
            {soruTable && soruTable.length > 0 && (
              <tbody>
                {soruTable.slice(first, first + rows).map((soru, index) => (
                  <tr key={index} className="data_td">
                    <td className="d-flex flex-row">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => navigate(`/app/soru/oku/${soru.id}`)}
                      >
                        <FaReadme className="tablo_icon" /> Seç
                      </button>
                      {soru.payState === "success" && (
                        <button className="btn btn-success" onClick={() => navigate(`cevap/${soru.id}`)}>
                          <FaCheck />{" "} Ödendi {soru.cevapOK === "1" ? "/ Cevap Verildi" : ""} 
                        </button>
                      )}
                      {!soru.payState && (
                        <div className="d-flex flex-row gap-1">
                        <button title="Kredi Kartı ile Ödeme Yapınız" className="btn btn-success"
                        onClick={() => navigate(`/app/odeme/${soru.id}`)}
                        >
                          <FaCreditCard />{" "}Kart ile Ödeme
                        </button>
                        <button title="EFT ile Ödeme Yapınız" className="btn btn-success"
                        onClick={() => {
                          let soruID = soru.id
                          dispatch(navIDChange({soruID}))
                          navigate(`/app/eft/${soruID}`)
                        }}
                        >
                          <FaMobileAlt />{" "}EFT ile Ödeme
                        </button>
                        </div>
                      )}
                    </td>
                    <td style={{ display: `${hide ? "none" : ""}` }}>
                      {truncateWord(soru.kategori, 30)}
                    </td>
                    <td style={{ display: `${hide ? "none" : ""}` }}>
                      {truncateWord(soru.baslik, 30)}
                    </td>
                    <td>{truncateWord(soru.detay, 30)}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          <div className="card paginator">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={soruTable && soruTable.length}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sorularim;
