import React, { useEffect, useState } from "react";
import { FaBackspace, FaEdit, FaSave } from "react-icons/fa";
import { useGetPayParamQuery } from "../../redux/web/payTRSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PAYTRSetup = () => {
  const { t } = useTranslation("translation");
  const {data: payTrTable} = useGetPayParamQuery()

  const [textDisable, setTextDisable] = useState(true);
  const [tusAdi, setTusAdi] = useState(t("button.duzenle"));
  const [payData, setPayData] = useState({
    id: "",
    merchant_id: "",
    merchant_key: "",
    merchant_salt: "",
    user_ip: "",
    merchant_ok_url: "",
    merchant_fail_url: "",
    timeout_limit: "",
    debug_on: "",
    test_mode: "",
    no_installment: "",
    max_installment: "",
    currency: "",
    lang: "",
  });

  const {
    merchant_id,
    merchant_key,
    merchant_salt,
    user_ip,
    merchant_ok_url,
    merchant_fail_url,
    timeout_limit,
    debug_on,
    test_mode,
    no_installment,
    max_installment,
    currency,
    lang,
  } = payData;

  useEffect(() => {
    if (payTrTable && payTrTable.id && merchant_id === "") {
      setPayData({
        id: payTrTable.id,
        merchant_id: payTrTable.merchant_id,
        merchant_key: payTrTable.merchant_key,
        merchant_salt: payTrTable.merchant_salt,
        user_ip: payTrTable.user_ip,
        merchant_ok_url: payTrTable.merchant_ok_url,
        merchant_fail_url: payTrTable.merchant_fail_url,
        timeout_limit: payTrTable.timeout_limit,
        debug_on: payTrTable.debug_on,
        test_mode: payTrTable.test_mode,
        no_installment: payTrTable.no_installment,
        max_installment: payTrTable.max_installment,
        currency: payTrTable.currency,
        lang: payTrTable.lang,
      });
    }
  }, [payTrTable, merchant_id]);

  const onChange = (e) => {
    setPayData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const payKaydet = () => {
   // dispatch(updatePayTR(payData));
    toast.success("Ödeme Parametreleri Başarıyla Güncellenmiştir.");
    setTextDisable(true);
    setTusAdi(t("button.duzenle"));
    setInputClass("paytr_input_right")
  };

  const payDuzenle = () => {
    setTextDisable(false);
    setTusAdi(t("button.guncelle"));
    setInputClass("")
  };

  const editCancel = () => {
    setTextDisable(true);
    setTusAdi(t("button.duzenle"));
    setInputClass("paytr_input_right")
  };
  const [inputClass, setInputClass] = useState("paytr_input_right")

  return (
    <div className="mt-2">
      <h4 className="text-center">{t("paytr.odemeDuzenle")}</h4>
      <div className={`row ${inputClass} paytr_label_kisalt`}>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            merchant_id
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="merchant_id"
            maxLength="50"
            name="merchant_id"
            type="text"
            value={merchant_id}
            onChange={onChange}
            disabled={textDisable}
          />
          </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            merchant_key
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="merchant_key"
            maxLength="50"
            name="merchant_key"
            type="text"
            value={merchant_key}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            merchant_salt
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="merchant_salt"
            maxLength="50"
            name="merchant_salt"
            type="text"
            value={merchant_salt}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            user_ip
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="user_ip"
            maxLength="50"
            name="user_ip"
            type="text"
            value={user_ip}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.basariliOdemeRedirectPage")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="merchant_ok_url"
            maxLength="50"
            name="merchant_ok_url"
            type="text"
            value={merchant_ok_url}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.unExpectedPage")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="merchant_fail_url"
            maxLength="50"
            name="merchant_fail_url"
            type="text"
            value={merchant_fail_url}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.processTimeOutDakika")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="timeout_limit"
            maxLength="50"
            name="timeout_limit"
            type="number"
            value={timeout_limit}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.hataMesajlariEkraniOnay")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="debug_on"
            maxLength="50"
            name="debug_on"
            type="number"
            value={debug_on}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.canliModOn")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="test_mode"
            maxLength="50"
            name="test_mode"
            type="number"
            value={test_mode}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.taksitTekCekim")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="no_installment"
            maxLength="50"
            name="no_installment"
            type="number"
            value={no_installment}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.taksitAdeti")}

{" / "}            {t("paytr.maxTaksit")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="max_installment"
            maxLength="50"
            name="max_installment"
            type="number"
            value={max_installment}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.paraBirimi")}
             {" / "}
            {t("paytr.digerParaBirimOnay")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="currency"
            maxLength="3"
            name="currency"
            type="text"
            value={currency}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-1 col-12">
          <label className="form-label col-4">
            {t("paytr.dilSecimi")}
          </label>
          <div className="col-7">
          <input
            className="form-control"
            id="lang"
            maxLength="3"
            name="lang"
            type="text"
            value={lang}
            onChange={onChange}
            disabled={textDisable}
          />
        </div>
        </div>
        <div className="d-flex mt-2 gap-1">
          <div className="col-4"></div>
          <button
            className="btn btn-warning col-4 btn-block"
            style={{
              display: `${tusAdi === t("button.duzenle") ? "none" : ""}`,
            }}
            onClick={() => editCancel()}
          >
            <FaBackspace /> {t("button.vazgec")}
          </button>
          <button
            className={`${
              tusAdi === t("button.duzenle")
                ? "btn btn-block btn-success col-4"
                : "btn btn-block btn-primary col-4"
            }`}
            onClick={() => {
              if (tusAdi === t("button.duzenle")) {
                payDuzenle();
              } else {
                payKaydet();
              }
            }}
          >
            {tusAdi === t("button.guncelle") ? <FaSave /> : <FaEdit />} {tusAdi}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PAYTRSetup