import { IYZICO_URL } from '../constants';
import apiSlice from '../apiSlice';

export const iyzicoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    iyzicoOdemePage: builder.mutation({
      query: (data) => ({
        url: `${IYZICO_URL}/paypage`,
        method: 'POST',
        body: data,
      }),
      providesTags: ['tbliyzipaypost'],
    }),
  }),
});

export const {
  useIyzicoOdemePageMutation
} = iyzicoApiSlice;