import { CEVAP_URL } from '../constants';
import apiSlice from '../apiSlice';

export const cevapApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCevaps: builder.query({
      query: () => ({
        url: CEVAP_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['tblcevap'],
    }),
    getCevapById: builder.query({
      query: (cevapID) => ({
        url: `${CEVAP_URL}/${cevapID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCevapGetirBySoruId: builder.query({
      query: (soruID) => ({
        url: `${CEVAP_URL}/soru/${soruID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createCevap: builder.mutation({
      query: (data) => ({
        url: `${CEVAP_URL}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['tblcevap'],
    }),
    toggleCevapById: builder.mutation({
      query: (soruID) => ({
        url: `${CEVAP_URL}/toggle/${soruID}`,
        method: 'POST',
        body: soruID,
      }),
      invalidatesTags: ['tblcevap'],
    }),
    uploadCevapImage: builder.mutation({
      query: (data) => ({
        url: `/api/upload`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteCevapById: builder.mutation({
      query: (cevapID) => ({
        url: `${CEVAP_URL}/${cevapID}`,
        method: 'DELETE',
      }),
      providesTags: ['tblcevap'],
    }),
  }),
});

export const {
  useGetCevapsQuery,
  useGetCevapByIdQuery,
  useGetCevapGetirBySoruIdQuery,
  useCreateCevapMutation,
  useToggleCevapByIdMutation,
  useUploadCevapImageMutation,
  useDeleteCevapByIdMutation,
} = cevapApiSlice;