import { PAYTR_URL } from '../constants';
import apiSlice from '../apiSlice';

export const paytrApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayParam: builder.query({
      query: () => ({
        url: PAYTR_URL + `/param`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['tblpaytrsetting'],
    }),
    payTROdemePage: builder.mutation({
      query: (data) => ({
        url: PAYTR_URL + `/getpaypage`,
        method: 'POST',
        body: data,
      }),
      providesTags: ['tblpaytrpost'],
    }),
    payTRList: builder.query({
      query: () => ({
        url: PAYTR_URL + `/getlist`,
      }),
      providesTags: ['tblpaytrpost'],
    }),
    payTROdemeSil: builder.mutation({
      query: (id) => ({
        url: PAYTR_URL + `/paysil/${id}`,
        method: 'DELETE',
      }),
      providesTags: ['tblpaytrpost'],
    }),
  }),
});

export const {
  useGetPayParamQuery,
  usePayTROdemePageMutation,
  usePayTRListQuery,
  usePayTROdemeSilMutation
} = paytrApiSlice;