import React from "react";
import iyzicoLogo from "../assets/iyzicologo.svg";
import arrowLogo from "../assets/newArrow.svg";

const Teslimat = () => {
  return (
    <section id="iyzico">
      <div className="iyzico">
        <div className="container iyzico-text">
          <div className="row">
            <div className="col-lg-1 col-md-1 mt-2">
              <img
                src={iyzicoLogo}
                alt="Çanakkale En İyi Avukat Selami Akar"
                style={{ width: "100px" }}
              />
            </div>
            <hr />
            <div className="col-lg-12 col-md-12 mt-2">
              <div className="mt-5 col-md-12 d-flex row">
                <div className="col-md-6 mb-4">
                  <h1 className="azgolgeli">Sizlere yardımcı olmaktan mutluluk duyuyoruz.</h1>
                  <p className="mt-4">
                    Merak ettiğiniz soruları ya da yaşadığınız problemleri
                    yardım merkezinde arayabilir ya da aşağıdaki kategorilerden
                    başlıkları seçerek örnek sorulara ulaşabilirsiniz.
                  </p>
                </div>
                <div className="col-md-6 d-flex justify-content-start">
                  <img
                    src={arrowLogo}
                    alt="Türkiye'nin En İyi Avukatı Selami Akar"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 mb-4">
                  <h2 className="font22bold azgolgeli">Teslimat işlemi nedir?</h2>
                  <h6>
                    ✅ Hukuk Büromuzun web sistemi üzerinden sormuş olduğunuz
                    soruya verilen cevaplar,{" "}
                    <strong>
                      Hukuk Büromuzun anteti ve ilgili avukat'ın imzası ile
                      oluşturulmuş <u>PDF halinde</u>
                    </strong>{" "}
                    sistem üzerindeki kayıtlı mail adresinize gönderilecektir.
                    Ayrıca sisteme kullanıcı adınız ve şifrenizle giriş
                    yaptıktan sonra cevaplanan sorular içindeki sayfanızda PDF
                    olarak kendi bilgisayarınız, tabletiniz ya da cep
                    telefonunuza indirebilirsiniz.
                  </h6>
                </div>
                <div className="col-md-6">
                  <h2 className="font22bold azgolgeli">
                    Para İadeleri müşterinin kartına ne kadar sürede geçecek?
                  </h2>
                  <h6>
                    ✅ İade işlemleri online olarak gerçekleşmektedir. Bu
                    nedenle Ankara Hukuk Bürosu 48 saat içinde cevaplanmayan
                    sorunuzun bedelini kendi sistemi üzerinden ilgili ödeme
                    kuruluşuna bilgi verecek ve ödemeniz iade edilecektir. İade
                    işlemleri,{" "}
                    <strong>
                      kredi kartları ile yapılan ödemelerde 1-3 iş günü
                      içerisinde
                    </strong>
                    , debit (bankamatik) kartlar ile yapılan ödemelerde ise 7-14
                    iş günü içerisinde kart hesabına yansımaktadır.
                  </h6>
                </div>
                <div className="mt-5">
                  <hr />
                  <h2 className="mt-3 azgolgeli">
                    TÜKETİCİ HAKLARI – CAYMA – İPTAL İLE HİZMET BEDELİNİN İADE
                    KOŞULLARI
                  </h2>
                  <p>
                    Kullanmakta olduğunuz web sitesi üzerinden elektronik
                    ortamda sipariş verdiğiniz takdirde, size sunulan ön
                    bilgilendirme formunu ve mesafeli satış sözleşmesini kabul
                    etmiş sayılırsınız.
                  </p>
                  <p>
                    Danışma hizmeti alanlar, ücret karşılığı sordukları
                    soruların cevaplarını; üye olurken bildirmiş oldukları
                    e-posta adresine gönderilmekle teslim almış sayılırlar.
                    Ayrıca web sitemize üye girişi yaptıklarında sadece
                    kendilerinin göreceği şekilde sordukları sorunun cevaplarını
                    PDF olarak görebilecekler ve istedikleri cihaza
                    indirebileceklerdir. Satın aldıkları danışma hizmeti ve
                    teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması
                    Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği
                    (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer
                    yasalara tabidir.
                  </p>
                  <p>
                    Satın alınan danışma hizmeti, eksiksiz ve sorulan soruların
                    içeriğine uygun cevaplandırılacaktır.
                  </p>
                  <h6>
                    <strong>SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:</strong>
                  </h6>
                  <p>
                    Danışan, satın aldığı danışma hizmetinin bedelini ödemez
                    veya banka kayıtlarında iptal ederse, danışma hizmeti
                    verenin sorulan soru veya sorulara cevap (verilmez)
                    gönderilmez.
                  </p>
                  <h6>
                    <strong>
                      ÖNGÖRÜLEMEYEN SEBEPLERLE HİZMET SÜRESİNDE TESLİM EDİLEMEZ
                      İSE:
                    </strong>
                  </h6>
                  <p>
                    Danışma hizmeti sunanın öngöremeyeceği mücbir sebepler
                    oluşursa veya hizmet (soru-soruların cevabı) 48 saat içinde
                    (üye olurken belirtmiş olduğu e-posta adresine) teslim
                    edilemez ise, sonraki 1-3 gün içinde danışanın herhangi bir
                    bildirim yapmasına gereken kalmaksızın ödediği hizmet bedeli
                    kesintisiz şekilde (eft şeklinde yapılmış ise eft olarak,
                    kredi kartı ise kart numarasına) hesabına iade edilecektir.
                  </p>
                  <h6>
                    <strong>
                      ALICININ ALDIĞI HİZMETİ KONTROL ETME YÜKÜMLÜLÜĞÜ:
                    </strong>
                  </h6>
                  <p>
                    Danışan, sorusuna verilen cevabın mail adresine gönderilip
                    gönderilmediğini veya sistemde cevaplanıp cevaplanmadığını
                    kontrol etmek zorundadır. Bu kontrolün yapılıp
                    yapılmamasından danışma hizmeti verenin bir sorumluluğu
                    yoktur.
                  </p>
                  <h6>
                    <strong>CAYMA HAKKI:</strong>
                  </h6>
                  <p>
                    Danışan; Kendisine verilen cevabı beğenmediğini ileri
                    sürerek cayma hakkı kullanamaz. Fakat soru içeriği anlamsız,
                    karmaşık, seçtiği kategori ile başlık ve konusu dışında soru
                    sormuş ise kendisine 48 saat içinde cevap verilmez. Ancak
                    ilk 48’in sonrasındaki 1-3 gün içerisinde yine ödemiş olduğu
                    danışma bedeli kesintisiz şekilde iade edilir. Verilen
                    cevaplardan tatmin olmaması halinde doğacak ihtilaflardan
                    Çanakkale Mahkemeleri yetkilidir.
                  </p>
                  <h6>
                    <strong>DANIŞMA HİZMETİ VERENİN İLETİŞİM BİLGİLERİ:</strong>
                  </h6>
                  <p>ADI/UNVANI: Selami Akar / Avukat</p>
                  <p>
                    ADRES: İsmetpaşa Mahallesi. İnönü Caddesi. No: 201/2Merkez /
                    ÇANAKKALE
                  </p>
                  <p>TEL: +90 546 9391234</p>
                  <h6>
                    <strong>TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</strong>
                  </h6>
                  <p>
                    Danışan, ödeme işlemlerini kredi kartı ile yaptığı durumda
                    temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki
                    kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve
                    bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt
                    eder. Bu durumda ilgili banka hukuki yollara başvurabilir;
                    doğacak masrafları ve vekâlet ücretini danışandan talep
                    edebilir ve her koşulda danışanın borcundan dolayı temerrüde
                    düşmesi halinde, danışan, borcun gecikmeli ifasından dolayı
                    danışma hizmeti verenin uğradığı zarar ve ziyanını
                    ödeyeceğini kabul eder.
                  </p>
                  <h6>
                    <strong>ÖDEME VE TESLİMAT</strong>
                  </h6>
                  <p>
                    Banka Havalesi veya EFT (Elektronik Fon Transferi) yaparak,
                    web sitemizdeki belirtilen hesaplarımızdan (TL) herhangi
                    birine yapabilirsiniz.
                  </p>
                  <p>
                    Sitemiz üzerinden kredi kartlarınız ile, her türlü kredi
                    kartınıza online tek ödeme yapabilirsiniz. Online
                    ödemelerinizde siparişiniz sonunda kredi kartınızdan tutar
                    çekim işlemi gerçekleşecektir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Teslimat;

/*
<div className="mt-5 col-md-12 d-flex row">
        <div className="col-md-6 mb-4">
          <h1>Sizlere yardımcı olmaktan</h1>
          <h1>mutluluk duyuyoruz.</h1>
          <h4 className="mt-4">
            Merak ettiğiniz soruları ya da yaşadığınız problemleri yardım
            merkezinde arayabilir ya da aşağıdaki kategorilerden başlıkları
            seçerek örnek sorulara ulaşabilirsiniz.
          </h4>
        </div>
        <div className="col-md-6 d-flex justify-content-start">
          <img
            src={arrowLogo}
            alt="Türkiye'nin En İyi Avukatı Selami Akar"
            style={{ maxWidth: "100%" }}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6 mb-4">
          <h2 className="font22bold">Teslimat işlemi nedir?</h2>
          <h6>
            ✅ Hukuk Büromuzun web sistemi üzerinden sormuş olduğunuz soruya
            verilen cevaplar,{" "}
            <strong>
              Hukuk Büromuzun anteti ve ilgili avukat'ın imzası ile oluşturulmuş{" "}
              <u>PDF halinde</u>
            </strong>{" "}
            sistem üzerindeki kayıtlı mail adresinize gönderilecektir. Ayrıca
            sisteme kullanıcı adınız ve şifrenizle giriş yaptıktan sonra
            cevaplanan sorular içindeki sayfanızda PDF olarak kendi
            bilgisayarınız, tabletiniz ya da cep telefonunuza indirebilirsiniz.
          </h6>
        </div>
        <div className="col-md-6">
          <h2 className="font22bold">
            Para İadeleri müşterinin kartına ne kadar sürede geçecek?
          </h2>
          <h6>
            ✅ İade işlemleri online olarak gerçekleşmektedir. Bu nedenle Ankara
            Hukuk Bürosu 48 saat içinde cevaplanmayan sorunuzun bedelini kendi
            sistemi üzerinden ilgili ödeme kuruluşuna bilgi verecek ve ödemeniz
            iade edilecektir. İade işlemleri,{" "}
            <strong>
              kredi kartları ile yapılan ödemelerde 1-3 iş günü içerisinde
            </strong>
            , debit (bankamatik) kartlar ile yapılan ödemelerde ise 7-14 iş günü
            içerisinde kart hesabına yansımaktadır.
          </h6>
        </div>
        <div className="mt-5">
          <hr />
          <h2 className="mt-3">
            TÜKETİCİ HAKLARI – CAYMA – İPTAL İLE HİZMET BEDELİNİN İADE KOŞULLARI
          </h2>
          <p>
            Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda
            sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu
            ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.
          </p>
          <p>
            Danışma hizmeti alanlar, ücret karşılığı sordukları soruların
            cevaplarını; üye olurken bildirmiş oldukları e-posta adresine
            gönderilmekle teslim almış sayılırlar. Ayrıca web sitemize üye
            girişi yaptıklarında sadece kendilerinin göreceği şekilde sordukları
            sorunun cevaplarını PDF olarak görebilecekler ve istedikleri cihaza
            indirebileceklerdir. Satın aldıkları danışma hizmeti ve teslimi ile
            ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve
            Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile
            yürürlükteki diğer yasalara tabidir.
          </p>
          <p>
            Satın alınan danışma hizmeti, eksiksiz ve sorulan soruların
            içeriğine uygun cevaplandırılacaktır.
          </p>
          <h6>
            <strong>SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:</strong>
          </h6>
          <p>
            Danışan, satın aldığı danışma hizmetinin bedelini ödemez veya banka
            kayıtlarında iptal ederse, danışma hizmeti verenin sorulan soru veya
            sorulara cevap (verilmez) gönderilmez.
          </p>
          <h6>
            <strong>
              ÖNGÖRÜLEMEYEN SEBEPLERLE HİZMET SÜRESİNDE TESLİM EDİLEMEZ İSE:
            </strong>
          </h6>
          <p>
            Danışma hizmeti sunanın öngöremeyeceği mücbir sebepler oluşursa veya
            hizmet (soru-soruların cevabı) 48 saat içinde (üye olurken belirtmiş
            olduğu e-posta adresine) teslim edilemez ise, sonraki 1-3 gün içinde
            danışanın herhangi bir bildirim yapmasına gereken kalmaksızın
            ödediği hizmet bedeli kesintisiz şekilde (eft şeklinde yapılmış ise
            eft olarak, kredi kartı ise kart numarasına) hesabına iade
            edilecektir.
          </p>
          <h6>
            <strong>ALICININ ALDIĞI HİZMETİ KONTROL ETME YÜKÜMLÜLÜĞÜ:</strong>
          </h6>
          <p>
            Danışan, sorusuna verilen cevabın mail adresine gönderilip
            gönderilmediğini veya sistemde cevaplanıp cevaplanmadığını kontrol
            etmek zorundadır. Bu kontrolün yapılıp yapılmamasından danışma
            hizmeti verenin bir sorumluluğu yoktur.
          </p>
          <h6>
            <strong>CAYMA HAKKI:</strong>
          </h6>
          <p>
            Danışan; Kendisine verilen cevabı beğenmediğini ileri sürerek cayma
            hakkı kullanamaz. Fakat soru içeriği anlamsız, karmaşık, seçtiği
            kategori ile başlık ve konusu dışında soru sormuş ise kendisine 48
            saat içinde cevap verilmez. Ancak ilk 48’in sonrasındaki 1-3 gün
            içerisinde yine ödemiş olduğu danışma bedeli kesintisiz şekilde iade
            edilir. Verilen cevaplardan tatmin olmaması halinde doğacak
            ihtilaflardan Çanakkale Mahkemeleri yetkilidir.
          </p>
          <h6>
            <strong>DANIŞMA HİZMETİ VERENİN İLETİŞİM BİLGİLERİ:</strong>
          </h6>
          <p>ADI/UNVANI: Selami Akar / Avukat</p>
          <p>
            ADRES: İsmetpaşa Mahallesi. İnönü Caddesi. No: 201/2Merkez /
            ÇANAKKALE
          </p>
          <p>TEL: +90 546 9391234</p>
          <h6>
            <strong>TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</strong>
          </h6>
          <p>
            Danışan, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde
            düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı
            sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu
            olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka
            hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini
            danışandan talep edebilir ve her koşulda danışanın borcundan dolayı
            temerrüde düşmesi halinde, danışan, borcun gecikmeli ifasından
            dolayı danışma hizmeti verenin uğradığı zarar ve ziyanını
            ödeyeceğini kabul eder.
          </p>
          <h6>
            <strong>ÖDEME VE TESLİMAT</strong>
          </h6>
          <p>
            Banka Havalesi veya EFT (Elektronik Fon Transferi) yaparak, web
            sitemizdeki belirtilen hesaplarımızdan (TL) herhangi birine
            yapabilirsiniz.
          </p>
          <p>
            Sitemiz üzerinden kredi kartlarınız ile, her türlü kredi kartınıza
            online tek ödeme yapabilirsiniz. Online ödemelerinizde siparişiniz
            sonunda kredi kartınızdan tutar çekim işlemi gerçekleşecektir.
          </p>
        </div>
        */
