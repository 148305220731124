import { configureStore } from "@reduxjs/toolkit";
import  apiSlice from "./apiSlice";
import authReducer from './auth/authSlice';
import {sabitApiSlice} from './web/sabitSlice';
import {cevapApiSlice} from './web/cevapSlice';
import {dovizApiSlice} from './web/dovizSlice';
import {fiyatApiSlice} from './web/fiyatSlice';
import {iyzicoApiSlice} from './web/iyzicoSlice';
import {musteriApiSlice} from './web/musteriSlice';
import {paytrApiSlice} from './web/payTRSlice';
import {soruApiSlice} from './web/soruSlice';
import {webforApiSlice} from './web/webForSlice';

const sacomStore = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    sabit: sabitApiSlice,
    cevapState: cevapApiSlice,
    dovizState: dovizApiSlice,
    fiyatState: fiyatApiSlice,
    iyzicoState: iyzicoApiSlice,
    musteriState: musteriApiSlice,
    paytrState: paytrApiSlice,
    soruState: soruApiSlice,
    webState: webforApiSlice,
    sacomAuth: authReducer,
  },
  //devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default sacomStore;
