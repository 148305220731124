import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaCheck, FaExclamationCircle, FaEye, FaSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  useChangePassByUserMutation,
  useCheckPassByUserMutation,
} from "../redux/auth/userApiSlice";

function ChangePassword() {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  const [mesajVer, setMesajVer] = useState(false);
  const [sifreDogru, setSifreDogru] = useState(false);
  const [checkPassByUser, { data: checkTable }] = useCheckPassByUserMutation();
  const [changePassByUser] = useChangePassByUserMutation();
  const [sifreData, setSifreData] = useState({
    userName: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { password, newPassword, confirmPassword } = sifreData;

  const [showPassword, setShowPassword] = useState("password");
  const [showNewPassword, setShowNewPassword] = useState("password");
  const [showConPassword, setShowConPassword] = useState("password");
  const [checkValue, setCheckValue] = useState(false);
  const [checkText, setCheckText] = useState(false);

  useEffect(() => {
    if (hukukUser && sifreData.userName === "") {
      setSifreData((prevState) => ({
        ...prevState,
        userName: hukukUser.userName,
      }));
    }
  }, [hukukUser, sifreData]);

  const onChange = (e) => {
    setSifreData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (newPassword && confirmPassword) {
      if (
        (newPassword.length >= 5 && newPassword.length <= 20) ||
        (confirmPassword.length >= 5 && confirmPassword.length <= 20)
      ) {
        if (newPassword === confirmPassword) {
          setCheckValue(true);
          setCheckText(false);
        } else {
          setCheckText(true);
          setCheckValue(false);
        }
      }
    }
    if (newPassword.length === 0 || confirmPassword.length === 0) {
      setCheckValue(false);
    }
  }, [newPassword, confirmPassword]);

  const showMevcutPassword = () => {
    if (showPassword === "password") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };

  const showYeniPassword = () => {
    if (showNewPassword === "password") {
      setShowNewPassword("text");
    } else {
      setShowNewPassword("password");
    }
  };

  const showKontPassword = () => {
    if (showConPassword === "password") {
      setShowConPassword("text");
    } else {
      setShowConPassword("password");
    }
  };

  const sifreDegistir = async () => {
    if (newPassword.length < 1 || confirmPassword.length < 1) {
      toast.error(t("toast.sifreBosOlamaz"));
    } else {
      try {
        await changePassByUser(sifreData).unwrap();
        setMesajVer(true);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }

      setSifreData({
        userName: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      toast.success(t("toast.sifreDegisti"));
      navigate("/");
    }
  };

  async function sifreKontrol() {
    setSifreData((prevState) => ({
      ...prevState,
      password: password,
    }));
    setTimeout(1500);
    try {
      await checkPassByUser(sifreData).unwrap();
      setMesajVer(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }

  useEffect(() => {
    if (mesajVer && checkTable && checkTable.message) {
      toast(checkTable.message);
      setMesajVer(false);
      if(checkTable.isFound === false) {
      setSifreDogru(false);
      } else {
        setSifreDogru(true);
      }
    }
  }, [mesajVer, checkTable]);

  return (
    <div className="login_page">
      <section className="sifre-degistirme">
        <div className="col-12 d-flex">
          <div className="col-lg-1 col-md-2 col-4">
            <label htmlFor="mevcutsifre">{t("genelPage.mevcutSifreniz")}</label>
          </div>
          <div className="col-lg-4 col-md-4 col-8 row">
            <div className="col-9">
              <input
                className="form-control"
                type={showPassword}
                name="password"
                id="password"
                placeholder={t("genelPage.mevcutSifreniz")}
                onChange={onChange}
                value={password}
                onBlur={() => sifreKontrol()}
                disabled={checkTable && checkTable.isFound}
              />
            </div>
            <div className="col-2">
              <span
                className="btn btn-outline-primary"
                onClick={() => showMevcutPassword()}
              >
                <FaEye />
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex mt-2">
          <div className="col-lg-1 col-md-2 col-4">
            <label htmlFor="newPassword">{t("genelPage.yeniSifreniz")}</label>
          </div>
          <div className="col-lg-4 col-md-4 col-8 row">
            <div className="col-9">
              <input
                className="form-control"
                type={showNewPassword}
                name="newPassword"
                id="newPassword"
                placeholder={t("genelPage.yeniSifreniz")}
                onChange={onChange}
                value={newPassword}
                maxLength={20}
                minLength={5}
                disabled={!sifreDogru}
              />
            </div>
            <div className="col-2">
              <span
                className="btn btn-outline-primary"
                onClick={() => showYeniPassword()}
              >
                <FaEye />
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex mt-2">
          <div className="col-lg-1 col-md-2 col-4">
            <label htmlFor="confirmpassword">
              {t("genelPage.sifreniziOnaylayin")}
            </label>
          </div>
          <div className="col-lg-4 col-md-4 col-8 row">
            <div className="col-9">
              <input
                className="form-control"
                type={showConPassword}
                name="confirmPassword"
                id="confirmPassword"
                placeholder={t("genelPage.sifreniziOnaylayin")}
                onChange={onChange}
                value={confirmPassword}
                maxLength={20}
                minLength={5}
                disabled={!sifreDogru}
              />
            </div>
            <div className="col-2">
              <span
                className="btn btn-outline-primary"
                onClick={() => showKontPassword()}
              >
                <FaEye />
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex mt-2">
          <div className="col-3"></div>
          <div className="col-9 row">
            <div>
              {checkValue && (
                <FaCheck
                  style={{
                    color: "green",
                    marginTop: "15px",
                    marginLeft: "-5px",
                  }}
                />
              )}
              {checkText && (
                <div style={{ marginTop: "10px" }}>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    <strong>{t("genelPage.sifreniziOnaylayin")}</strong>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 d-flex mt-2">
          <div className="col-3"></div>
          <div className="col-9 row">
            {newPassword &&
              confirmPassword &&
              newPassword.length < 5 &&
              confirmPassword.length < 5 && (
                <sub>{t("genelPage.sifreMinKarakter")}</sub>
              )}
          </div>
        </div>
        <div className="col-12 d-flex mt-2">
          <div className="col-1"></div>
          <div className="col-lg-3  col-md-4 col-9">
            <button
              onClick={() => sifreDegistir()}
              className="btn bg-primary text-white btn-block"
              disabled={!checkValue}
            >
              <FaSave /> {t("button.guncelle")}
            </button>
            {checkTable && checkTable.isFound ? (
              <div>
                <span>
                  <FaExclamationCircle /> {`${checkTable.message}`}
                </span>
              </div>
            ) : !password.length > 0 ? (
              <div>
                <span>
                  <FaExclamationCircle /> {t("genelPage.lutfenSifreGirin")}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangePassword;
