import React from "react";

const GenelAciklama = () => {
  return (
    <div className="orders">
      <div className="header">
        <i className="bx bx-receipt"></i>
        <h3>Genel Açıklama</h3>
      </div>
      <div>
          <p>
        Buradaki bilgiler Yönetici için önem arz eden bilgilerdir. Soldaki
        kısımda (Hatırlatma) bulunan satırlardaki kısımlarda gezindikçe tam bu
        kısımda canlı olarak ilgili bilgiler tablo halinde getirilecektir.
      </p>
      </div>
    </div>
  );
};

export default GenelAciklama;
