import React from "react";

const KVKK = () => {
  return (
    <section id="kvkk">
      <div className="faq wrapper">
        <div className="container">
          <div className="col-sm-12">
            <div className="text-center pb-4">
            <h2 className="text-center azgolgeli">Kişisel Verilerin Korunması Kanunu</h2>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-sm-12 mb-4">
          <strong>Kişisel Verilerin Korunması (KVKK) Ne Demektir?</strong>
          <p className="mt-2">
            Kişisel verilerin korunması, kişisel verilerin işlenmesinin disiplin
            altına alınması ile temel hak ve özgürlüklerin korunmasıdır. Kişisel
            verilerin korunması, temelde verilerin değil, bu kişisel verilerin
            ilişkili olduğu kişilerin korunmasını amaçlamaktadır.
          </p>
          <p>
            Başka bir ifade ile verilerin korunması; kişileri, onlar hakkındaki
            verilerin tamamen veya kısmen otomatik olan ya da otomatik olmayan
            yollarla işlenmesinden doğacak zararlardan koruma amacına yönelmiş
            ve kişisel verilerin korunmasına ilişkin ilkelerde somutlaşmış
            idari, teknik ve hukuki önlemleri ifade eder.
          </p>
          <p>
            Bu anlamda kişisel verilerin korunmasının, kişilere ilişkin
            verilerin toplanması, saklanması, kullanılması ve aktarılması gibi
            veri işleme süreçlerinin bütün aşamalarını kapsar şekilde bireylere
            kontrol hakkını yeniden kazandırmayı amaçladığı söylenebilir. Bu
            amaç kapsamında kişisel verilerin korunması, kişinin verilerinin
            geleceğini bizzat kendisinin belirleme hakkını ifade eder. Aynı
            zamanda bu koruma insan onurunun ve kişilik hakkının da bir
            gereğidir.
          </p>
          <strong>
            Ülkemizde Kişisel Verilerin Korunmasına İlişkin Hukuki Düzenlemeler
            Nelerdir?
          </strong>
          <p className="mt-2">
            Kişisel verilerin korunması hakkı ülkemizde 2010 yılında anayasal
            teminata bağlanmıştır. Bu tarihe kadarki dönemde ise kişisel veriler
            daha çok genel hukuki düzenlemelerde yer alan hükümler ile
            korunmaktaydı.
          </p>
          <p>
            Türk Medeni Kanunu ve Türk Ceza Kanununda kişilik hakkı ile kişisel
            verilerin korunmasına yönelik hükümler ve yaptırımlar bu
            düzenlemelere örnek gösterilebilir.
          </p>
          <p>
            2010 yılında ise, Anayasanın 20. maddesine eklenen “Herkes,
            kendisiyle ilgili kişisel verilerin korunmasını isteme hakkına
            sahiptir…” hükmü ile kişisel verilerin korunması ilk kez anayasal
            bir hak statüsüne kavuşmuştur. Aynı zamanda Anayasanın 20.
            maddesinin 3. fıkrasında bu hakkın korunmasına ilişkin usul ve
            esasların belirlenmesi çıkarılacak bir kanuna bırakılmıştır.
          </p>
          <p>
            Bu kapsamda 24 Mart 2016 tarihinde kabul edilen 6698 sayılı Kişisel
            Verilerin Korunması Kanunu, 7 Nisan 2016 tarihli ve 29677 sayılı
            Resmi Gazetede yayımlanarak yürürlüğe girmiştir.
          </p>
          <strong>
            Kişisel Verilerin Korunması Hakkının Dayanağı Nedir? Bu Hak,
            Sınırsız Bir Hak mıdır?
          </strong>
          <p>
            Kişisel verilerin korunması hakkının dayanağı, Anayasanın 20.
            maddesinin son fıkrasıdır. Temel bir hak olarak düzenlenen kişisel
            verilerin korunmasını isteme hakkı, Anayasanın kişinin hak ve
            ödevlerine ilişkin bölümünde yer almaktadır.
          </p>
          <p>
            Bununla birlikte, tüm hak ve özgürlüklerde olduğu gibi, kişisel
            verilerin korunmasına ilişkin hak da Anayasada çizilen sınırlar
            çerçevesinde diğer hak ve özgürlükler lehine sınırlandırılabilir.
          </p>
          <p>
            Buna göre, Anayasanın 20. maddesinde tanınan kişisel verilerin
            korunmasına ilişkin her bir hakkın uygulanması ve diğer haklar
            lehine sınırlanmasına ilişkin düzenlemeler ancak kanun yoluyla
            gerçekleştirilebilir.
          </p>
          <p>
            Anayasa Mahkemesi, 9 Nisan 2014 tarihli ve E:2013/122, K:2014/74
            sayılı kararında da, Anayasanın 20. maddesinin 3. fıkrasının son
            cümlesinde, “Kişisel verilerin korunmasına ilişkin esas ve usuller
            kanunla düzenlenir” hükmüne yer vererek ve “yasama yetkisinin
            devredilmezliği” ilkesi gereğince, Anayasanın açıkça kanunla
            düzenlenmesini öngördüğü konularda yürütme organına doğrudan ve ilk
            elden düzenleyici işlem yapma yetkisi verilemeyeceğine hükmederek,
            Anayasada öngörülen kanuni düzenlemenin mutlaka gerçekleştirilmesi
            gerektiğinin altını çizmiştir. Dolayısıyla Anayasanın 20. maddesinin
            son fıkrasında tanınan kişisel verilerin korunması hakkına ilişkin
            düzenlemeler kanun ile yapıldığı sürece uygulama alanı bulacaktır.
          </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KVKK;
