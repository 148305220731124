import React from "react";
import { useSelector } from "react-redux"

const Header = () => {
const {sayfaAdi} = useSelector(state => state.sacomAuth)
  return (
    <div className="header">
      <div className="left">
        <ul className="breadcrumb">
          <li>Home</li>/<li>{sayfaAdi}</li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
