export const SERVER_URL = "https://selamiakar.com:5000/api"
export const SERVERPICT_URL = "https://selamiakar.com:5000/getresim"
//export const SERVER_URL = "http://192.168.1.217:5000/api"
//export const SERVERPICT_URL = "http://192.168.1.217:5000/getresim"
export const AUTH_URL = '/users';
export const MUSTERI_URL = '/musteri';
export const SORU_URL = '/soru';
export const CEVAP_URL = '/cevap';
export const LANGUAGE_URL = '/language';
export const FIYAT_URL = '/fiyat';
export const SABIT_URL = '/sabit';
export const WEBFOR_URL = '/webfor';
export const UPLOAD_URL = '/dosya';
export const PAYTR_URL = '/paytr';
export const DOVIZ_URL = '/doviz';
export const IYZICO_URL = '/iyzipay';
export const ISTATISTIK_URL = '/istatistik';
export const UYELIK_URL = '/uyeliksoru';
