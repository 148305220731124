import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
 
const PayTRPage = () => {
  const {payHtml} = useSelector(state => state.sacomAuth)
  const [geldi, setGeldi] = useState(false)
  const [html, setHtml] = useState()

  useEffect(() => {
    if(!geldi && payHtml !== "") {
      setGeldi(true)
    }
  }, [geldi, payHtml])

  useEffect(() => {
    if(geldi) {
      setHtml(payHtml)
    } 
  },[geldi, payHtml])

  if(!geldi && !payHtml.length > 0) {
    return (
        <div className='container text-center mt-2'>
            <h3>Sipariş Ödeme Linki Pasiftir</h3>
            <hr />
            <h5><a href="/">Lütfen Ana Sayfa İçin Tıklayınız..</a></h5>
        </div>
    )
}

  return (<>
      <div
        className="preview_paytr mt-1 py-1" style={{ height: "100vh"}}
        dangerouslySetInnerHTML={{ __html: html && html }}
      />
      </>
  );
};

export default PayTRPage