import React, { useEffect, useState } from "react";
import {
  useEkleGuncelleSocialByIdMutation,
  useGetSocialByIdQuery,
  useSilSocialByIdMutation,
} from "../../redux/web/sabitSlice";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import Message from "../../web/Message";
import Spinner from "../../web/Spinner";
import { useTranslation } from "react-i18next";
import insLogo from "../../assets/socialicon/instagram.png";
import faceLogo from "../../assets/socialicon/facebook.png";
import xLogo from "../../assets/socialicon/twitter.png";
import ytLogo from "../../assets/socialicon/youtube.png";
import leLogo from "../../assets/socialicon/linkedin.png";

const SocialPage = () => {
  const { t } = useTranslation("translation");
  const [loading, setLoading] = useState(false);
  const [instagramUrl, setInstagramUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [linkedinUrl, setLinkedInUrl] = useState("");
  const {
    data: socialTable,
    isLoading,
    error,
    refetch,
  } = useGetSocialByIdQuery();
  const [ekleGuncelleSocialById, { isLoading: loadingUpdate }] =
    useEkleGuncelleSocialByIdMutation();
  const [silSocialById, { isLoading: loadingSil }] = useSilSocialByIdMutation();

  useEffect(() => {
    if (socialTable) {
      setInstagramUrl(socialTable.instagramUrl);
      setFacebookUrl(socialTable.facebookUrl);
      setTwitterUrl(socialTable.twitterUrl);
      setYoutubeUrl(socialTable.youtubeUrl);
      setLinkedInUrl(socialTable.linkedinUrl);
    }
  }, [socialTable]);

  const submitHandler = async () => {
    let data = {
      instagramUrl,
      facebookUrl,
      twitterUrl,
      youtubeUrl,
      linkedinUrl,
    };
    try {
      setLoading(true); // Spinner'ı göster
      await ekleGuncelleSocialById(data).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toast.success(t("toast.guncellendi"));
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    } finally {
      setLoading(false); // Spinner'ı gizle
    }
  };

  const submitRemoveHandler = async () => {
    setInstagramUrl("");
    setFacebookUrl("");
    setTwitterUrl("");
    setYoutubeUrl("");
    setLinkedInUrl("");
    let data = {
      instagramUrl,
      facebookUrl,
      twitterUrl,
      youtubeUrl,
      linkedinUrl,
    };
    try {
      setLoading(true); // Spinner'ı göster
      await silSocialById(data).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toast.success(t("toast.guncellendi"));
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }finally {
      setLoading(false); // Spinner'ı gizle
    }
  };

  return isLoading ? null : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div className="yonetim_info bg-soft">
      {(loadingUpdate || loadingSil) && <Spinner />}
      {loading && <Spinner />}
      <div className="d-flex row gap-2">
        <div className="d-flex justify-content-start col-md-8 d-block">
          <label htmlFor="instagramUrl" className="col-md-3 mt-2">
            <img src={insLogo} alt="çanakkale en iyi avukat selami akar" />{" "}
            <span className="mobil_hide">Profil Linki</span>
          </label>
          <div className="col-lg-9 col-md-9 col-11">
            <input
              type="text"
              value={instagramUrl}
              name="instagramUrl"
              onChange={(e) => setInstagramUrl(e.target.value)}
              placeholder="İnstagram"
              className="form-control d-block"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-md-8 d-block">
          <label htmlFor="facebookUrl" className="col-md-3 mt-2">
            <img src={faceLogo} alt="çanakkale en iyi avukat selami akar" />{" "}
            <span className="mobil_hide">Profil Linki</span>
          </label>
          <div className="col-lg-9 col-md-9 col-11">
            <input
              type="text"
              value={facebookUrl}
              name="facebookUrl"
              onChange={(e) => setFacebookUrl(e.target.value)}
              placeholder="Facebook"
              className="form-control d-block"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-md-8 d-block">
          <label htmlFor="twitterUrl" className="col-md-3 mt-2">
            <img src={xLogo} alt="çanakkale en iyi avukat selami akar" /> 
            <span className="mobil_hide">Profil Linki</span>
          </label>
          <div className="col-lg-9 col-md-9 col-11">
            <input
              type="text"
              value={twitterUrl}
              name="twitterUrl"
              onChange={(e) => setTwitterUrl(e.target.value)}
              placeholder="Twitter"
              className="form-control d-block"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-md-8 d-block">
          <label htmlFor="youtubeUrl" className="col-md-3 mt-2">
            <img src={ytLogo} alt="çanakkale en iyi avukat selami akar" />{" "}
            <span className="mobil_hide">Profil Linki</span>
          </label>
          <div className="col-lg-9 col-md-9 col-11">
            <input
              type="text"
              value={youtubeUrl}
              name="youtubeUrl"
              onChange={(e) => setYoutubeUrl(e.target.value)}
              placeholder="Youtube"
              className="form-control d-block"
            />
          </div>
        </div>
        <div className="d-flex justify-content-start col-md-8 d-block">
          <label htmlFor="linkedinUrl" className="col-md-3 mt-2">
            <img src={leLogo} alt="çanakkale en iyi avukat selami akar" />{" "}
            <span className="mobil_hide">Profil Linki</span>
          </label>
          <div className="col-lg-9 col-md-9 col-11">
            <input
              type="text"
              value={linkedinUrl}
              name="linkedinUrl"
              onChange={(e) => setLinkedInUrl(e.target.value)}
              placeholder="LinkedIn"
              className="form-control d-block"
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between d-block gap-1">
        <button
          className="mt-2 btn btn-outline-success btn-block col-md-8"
          onClick={submitHandler}
        >
          <FaSave /> Kaydet
        </button>
        <button
          className="mt-2 btn btn-outline-warning btn-block col-md-4"
          onClick={submitRemoveHandler}
        >
          <FaTrashAlt /> Hepsini Sil
        </button>
      </div>
    </div>
  );
};

export default SocialPage;
