import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.webp";
import { useGetForWebQuery } from "../redux/web/webForSlice";
import Spinner from "./Spinner";
import { useDispatch, useSelector } from "react-redux";
import { webDataSet } from "../redux/auth/authSlice";
import Message from "./Message";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const { webData } = useSelector((state) => state.sacomAuth);
  const { data: webDataTable, isLoading, error } = useGetForWebQuery();

  useEffect(() => {
    if (
      webDataTable &&
      webDataTable.iletisim &&
      (!webData || webData.length === 0)
    ) {
      dispatch(webDataSet(webDataTable));
    }
  }, [webDataTable, webData, dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector(".navigation-wrap");
      if (document.documentElement.scrollTop > 20) {
        nav.classList.add("scroll-on");
      } else {
        nav.classList.remove("scroll-on");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link");
    const navCollapse = document.querySelector(".navbar-collapse.collapse");
    const handleClick = () => {
      navCollapse.classList.remove("show");
    };
    navLinks.forEach((link) => {
      link.addEventListener("click", handleClick);
    });
    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleClick);
      });
    };
  }, []);

  const rotaKontrol = () => {
    if (pathName !== "/") {
      navigate("/");
    }
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navigation-wrap">
        {isLoading && <Spinner />}
        {error && (
          <Message variant="danger">
            {error?.data?.message || error?.error}
          </Message>
        )}
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" className="buro_logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item" onClick={() => rotaKontrol()}>
                <a className="nav-link active" aria-current="page" href="#home">
                  Ana Sayfa
                </a>
              </li>
              <li className="nav-item" onClick={() => rotaKontrol()}>
                <a className="nav-link" href="#about">
                  Hakkımda
                </a>
              </li>
              <li className="nav-item" onClick={() => rotaKontrol()}>
                <a className="nav-link" href="#explore-book">
                  Dava Türleri
                </a>
              </li>
              <li className="nav-item" onClick={() => rotaKontrol()}>
                <a className="nav-link" href="#kvkk">
                  KVKK
                </a>
              </li>
              <li className="nav-item" onClick={() => rotaKontrol()}>
                <a className="nav-link" href="#iyzico">
                  Teslimat & İade
                </a>
              </li>
              <li className="nav-item" onClick={() => rotaKontrol()}>
                <a className="nav-link" href="#tarife" style={{ width: "max-content"}}>
                Danışma Ücretleri
                </a>
              </li>
              <li className="nav-item" onClick={() => rotaKontrol()}>
                <a className="nav-link" href="#footer">
                  İletişim
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
