import { SORU_URL, UPLOAD_URL } from '../constants';
import apiSlice from '../apiSlice';

export const soruApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSorus: builder.query({
      query: () => ({
        url: SORU_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['tblsoru'],
    }),
    getSoruByUserId: builder.query({
      query: (userID) => ({
        url: `${SORU_URL}/${userID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getSoruById: builder.query({
      query: (soruID) => ({
        url: `${SORU_URL}/bak/${soruID}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createSoru: builder.mutation({
      query: (data) => ({
        url: `${SORU_URL}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['tblsoru']
     }),
    updateSoruById: builder.mutation({
      query: (data) => ({
        url: SORU_URL + `/${data.soruId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['tblsoru'],
    }),
    uploadSoruImage: builder.mutation({
      query: (data) => ({
        url: UPLOAD_URL,
        method: 'POST',
        body: data,
      }),
    }),
    deleteSoruById: builder.mutation({
      query: (soruId) => ({
        url: SORU_URL + `/${soruId}`,
        method: 'DELETE',
      }),
      providesTags: ['tblsoru'],
    }),
    getOdemelerimByUserID: builder.query({
      query: (userID) => ({
        url: SORU_URL + `/odemelerim/${userID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['tblsoru'],
    }),
    updateOdemeOnayBySoruID: builder.mutation({
      query: (soruID) => ({
        url: `${SORU_URL}/odemeonay/${soruID}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetSorusQuery,
  useGetSoruByUserIdQuery,
  useGetSoruByIdQuery,
  useCreateSoruMutation,
  useUpdateSoruByIdMutation,
  useUploadSoruImageMutation,
  useDeleteSoruByIdMutation,
  useGetOdemelerimByUserIDQuery,
  useUpdateOdemeOnayBySoruIDMutation,
} = soruApiSlice;
