import apiSlice from "../apiSlice";
import { AUTH_URL } from "../constants";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/auth`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/register`,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${AUTH_URL}/logout`,
        method: "POST",
      }),
    }),
    profile: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/profile`,
        method: "PUT",
        body: data,
      }),
    }),
    getUsers: builder.query({
      query: () => ({
        url: AUTH_URL,
      }),
      providesTags: ["tblusers"],
      keepUnusedDataFor: 5,
    }),
    getSorulars: builder.query({
      query: () => ({
        url: AUTH_URL + "/sorular",
      }),
      providesTags: ["tbluyesorulari"],
      keepUnusedDataFor: 5,
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${AUTH_URL}/${userId}`,
        method: "DELETE",
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/${data.userId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["tblusers"],
    }),
    checkPassByUser: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/checkpass`,
        method: "POST",
        body: data,
      }),
    }),
    changePassByUser: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/changepass`,
        method: "POST",
        body: data,
      }),
    }),
    tempMailOnay: builder.mutation({
      query: (id) => ({
        url: `${AUTH_URL}/tempmailonay/${id}`,
        method: "POST",
      }),
    }),
    userCheckGetSoruByID: builder.mutation({
      query: (data) => ({
        url: AUTH_URL + "/usercheckforanswer",
        method: "POST",
        body: data,
      }),
    }),
    userCheckSoruTeyit: builder.mutation({
      query: (data) => ({
        url: AUTH_URL + "/usercheckforteyit",
        method: "POST",
        body: data,
      }),
    }),
    userChangePassTeyit: builder.mutation({
      query: (data) => ({
        url: AUTH_URL + "/userchangepassofteyit",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useProfileMutation,
  useGetUsersQuery,
  useGetSorularsQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useCheckPassByUserMutation,
  useChangePassByUserMutation,
  useTempMailOnayMutation,
  useUserCheckGetSoruByIDMutation,
  useUserCheckSoruTeyitMutation,
  useUserChangePassTeyitMutation
} = userApiSlice;
