import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "./App";
import WebPage from "./web/WebPage";
import AppPage from "./app/AppPage";
import Dashboard from "./app/user/Dashboard";
import AdminDashboard from "./app/admin/AdminDashboard";
import Register from "./app/Register";
import LoginPage from "./app/LoginPage";
import ResetPassword from "./app/ResetPassword";
import SorularPage from "./app/admin/SorularPage";
import CevaplarPage from "./app/admin/CevaplarPage";
import AdminProfil from "./app/admin/AdminProfil";
import Sorularim from "./app/user/Sorularim";
import PayCardPage from "./app/user/PayCardPage";
import PayEFTPage from "./app/user/PayEFTPage";
import UserProfil from "./app/user/UserProfil";
import SoruCevapPage from "./app/admin/SoruCevapPage";
import CevapOkuPage from "./app/admin/CevapOkuPage";
import SoruOkuPage from "./app/user/SoruOkuPage";
import CevapBakPage from "./app/user/CevapBakPage";
import PayTROdeme from "./app/user/PayTROdeme";
import IyzicoOdeme from "./app/user/IyzicoOdeme";
import AdminPanel from "./app/admin/AdminPanel";
import PAYTRSetup from "./app/admin/PAYTRSetup";
import AboutSetup from "./app/admin/AboutSetup";
import CezaSetup from "./app/admin/CezaSetup";
import MedeniSetup from "./app/admin/MedeniSetup";
import YeniSoruPage from "./app/user/YeniSoruPage";
import SoruPrint from "./app/user/SoruPrint";
import Odemelerim from "./app/user/Odemelerim";
import ChangePassword from "./app/ChangePassword";
import PayTRPage from "./app/user/PayTRPage";
import PayTRList from "./app/admin/PayTRList";
import CevapPrint from "./app/user/CevapPrint";
import TarifePage from "./app/user/TarifePage";
import TempOnayConfirm from "./app/TempOnayConfirm";
import UyelikSoruPage from "./app/UyelikSoruPage";
import SepetPage from "./app/user/SepetPage";

export const webRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<WebPage />} />
      <Route path="reset" element={<ResetPassword />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<Register />} />
      <Route path="tempmailconfirm/:id" element={<TempOnayConfirm />} />
      <Route path="/app" element={<AppPage />}>
        <Route path="adash" element={<AdminDashboard />} />
        <Route path="apassword" element={<ChangePassword />} />
        <Route path="notans" element={<SorularPage />} />
        <Route path="notans/edit/:id" element={<SoruCevapPage />} />
        <Route path="yesans" element={<CevaplarPage />} />
        <Route path="yesans/edit/:id" element={<CevapOkuPage />} />
        <Route path="aprofil" element={<AdminProfil />} />
        <Route path="udash" element={<Dashboard />} />
        <Route path="yeni" element={<YeniSoruPage />} />
        <Route path="soru" element={<Sorularim />} />
        <Route path="soru/oku/:id" element={<SoruOkuPage />} />
        <Route path="soru/cevap/:id" element={<CevapBakPage />} />
        <Route path="soru/print/:id" element={<SoruPrint />} />
        <Route path="soru/cprint/:id" element={<CevapPrint />} />
        <Route path="odeme/:id" element={<PayCardPage />} />
        <Route path="odeme/paytr/:id" element={<PayTROdeme />} />
        <Route path="odeme/paypage" element={<PayTRPage />} />
        <Route path="odeme/iyzico/:id" element={<IyzicoOdeme />} />
        <Route path="eft/:id" element={<PayEFTPage />} />
        <Route path="odemelerim/:id" element={<Odemelerim />} />
        <Route path="upassword" element={<ChangePassword />} />
        <Route path="uprofil" element={<UserProfil />} />
        <Route path="panel" element={<AdminPanel />} />
        <Route path="paytrsetup" element={<PAYTRSetup />} />
        <Route path="aboutsetup" element={<AboutSetup />} />
        <Route path="cezasetup" element={<CezaSetup />} />
        <Route path="medenisetup" element={<MedeniSetup />} />
        <Route path="paytrlist" element={<PayTRList />} />
        <Route path="tarife" element={<TarifePage />} />
        <Route path="uyeliksoru" element={<UyelikSoruPage />} />
        <Route path="sepet" element={<SepetPage />} />
      </Route>
    </Route>
  )
);
 