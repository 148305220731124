import React, { useState } from "react";
import medeniHukukLogo from "../assets/img/img-4.jpg";
import cezaHukukLogo from "../assets/img/img-5.jpg";
import { Dialog } from "primereact/dialog";
import CezaHukuku from "./CezaHukuku";
import MedeniHukuk from "./MedeniHukuk";
import { FaBackspace } from "react-icons/fa";

const DavaTurleri = () => {
  const [cezaVisible, setCezaVisible] = useState(false);
  const [medeVisible, setMedeVisible] = useState(false);

  return (
    <section id="explore-book">
      <div className="explore-book wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-content text-center">
                <h2 className="azgolgeli">Dava Türleri</h2>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-lg-6 col-md-6 mb-lg-0 mb-5">
              <div
                className="card kitap_img"
                onClick={() => setCezaVisible(true)}
              >
                <img src={cezaHukukLogo} alt="" className="img-fluid" />
                <h1 className="text-center">Ceza Hukuku</h1>
                <span className="text-center">Detaylar İçin Tıklayınız</span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-lg-0 mb-5">
              <div
                className="card kitap_img"
                onClick={() => setMedeVisible(true)}
              >
                <img src={medeniHukukLogo} alt="" className="img-fluid" />
                <h1 className="text-center">Medeni Hukuk</h1>
                <span className="text-center">Detaylar İçin Tıklayınız</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        headerStyle={{ textAlign: "center" }}
        visible={cezaVisible}
        style={{ width: "50vw" }}
        onHide={() => setCezaVisible(false)}
        maximized
      >
        <div className="container">
          <button
            className="btn btn-outline-secondary"
            onClick={() => setCezaVisible(false)}
          >
            <FaBackspace /> Geri Dön
          </button>
        </div>
        <CezaHukuku />
      </Dialog>
      <Dialog
        headerStyle={{ textAlign: "center" }}
        visible={medeVisible}
        style={{ width: "50vw" }}
        onHide={() => setMedeVisible(false)}
        maximized
      >
        <div className="container">
          <button
            className="btn btn-outline-secondary"
            onClick={() => setMedeVisible(false)}
          >
            <FaBackspace /> Geri Dön
          </button>
        </div>
        <MedeniHukuk />
      </Dialog>
    </section>
  );
};

export default DavaTurleri;
