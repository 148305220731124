import React, { useEffect, useState } from "react";
import { FaBackspace, FaCloudUploadAlt, FaSave } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useGetFiyatsQuery } from "../../redux/web/fiyatSlice";
import { useCreateSoruMutation, useUploadSoruImageMutation } from "../../redux/web/soruSlice";
import { navIDChange, recordAdd } from "../../redux/auth/authSlice";
import { toast } from "react-toastify";
import Spinner from "../../web/Spinner";
import Message from "../../web/Message";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";

const YeniSoruPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hukukUser, navID } = useSelector((state) => state.sacomAuth);
  const { data: fiyatTable, isLoading, error } = useGetFiyatsQuery();
  const [uploadSoruImage ] = useUploadSoruImageMutation()
  const [visible, setVisible] = useState(false);
  const [kayitOk, setKayitOk] = useState(false);
  const [file, setFile] = useState("");
  const [dosya, setDosya] = useState("");
  const [ekran, setEkran] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setEkran(true);
      } else {
        setEkran(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [
    createSoru,
    { data: createData, isLoading: loadingCreate },
  ] = useCreateSoruMutation();
  const [soruData, setSoruData] = useState({
    userID: hukukUser ? hukukUser.userID : null,
    kategori: "",
    baslik: "",
    detay: "",
    tutar: "",
  });
  const { kategori, baslik, detay } = soruData;
  const [kalanChar, setKalanChar] = useState("");

  const onChange = (e) => {
    const textarea = e.target;
    setSoruData((prevState) => ({
      ...prevState,
      detay: e.target.value,
    }));
    textarea.style.height = "auto"; // textarea'nın yüksekliğini sıfırla
    textarea.style.height = textarea.scrollHeight + "px";
    if (detay.length <= 20000) {
      setKalanChar(detay);
    }
  };

  const soruyuKaydet = async () => {
    if (kategori === "" || baslik === "") {
      toast.error("Lütfen Kategori ve Başlık kısımlarını tam doldurunuz.");
      return;
    }
    if (detay.length < 10) {
      toast.error(
        "Açıklamanız bu kadar kısa olmamalı.. Lütfen daha uzun açıklama giriniz."
      );
      return;
    }
    try {
      await createSoru(soruData).unwrap();
      dispatch(recordAdd(true));
      toast.success("Kayıt Eklenmiştir", { autoClose: 5000 });
      setKayitOk(true)
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (
      createData &&
      createData &&
      createData.soruData &&
      createData.soruData.soruID
    ) {
      let soruID = parseInt(createData.soruData.soruID);
      let kategori = createData.soruData.kategori;
      let baslik = createData.soruData.baslik;
      let tutar = parseFloat(createData.soruData.tutar).toFixed(2);
      const data = { soruID, kategori, baslik, tutar };
      dispatch(navIDChange(data));
    }
  }, [createData, dispatch]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  
  // KategoriAdi'leri gruplamak
  const groupedCategories = [
    ...new Set(fiyatTable && fiyatTable.map((item) => item.kategoriAdi)),
  ];

  // Başlıkları gruplamak
  const groupedTitles = (category) => {
    return [
      ...new Set(
        fiyatTable &&
          fiyatTable
            .filter((item) => item.kategoriAdi === category)
            .map((item) => item.baslik)
      ),
    ];
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    // Diğer seçimleri sıfırla
    setSoruData((prevState) => ({
      ...prevState,
      kategori: category,
    }));
    setSelectedTitle("");
    setSelectedDescription("");
    setSelectedPrice("");
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    setSelectedTitle(title);
    // Diğer seçimleri sıfırla
    setSelectedDescription("");
    setSelectedPrice("");
  };

  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    setSelectedDescription(description);
    // Seçilen açıklamaya göre fiyatı al
    setSoruData((prevState) => ({
      ...prevState,
      baslik: description,
    }));
    const selectedPrice =
      fiyatTable.find((item) => item.aciklama === description)?.fiyat || "";
    setSelectedPrice(selectedPrice);
    setSoruData((prevState) => ({
      ...prevState,
      tutar: selectedPrice,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "application/pdf" ||
        selectedFile.type === "application/msword" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setFile(URL.createObjectURL(selectedFile));
      setDosya(selectedFile);
    } else {
      toast.error("Lütfen bir JPEG, PNG, PDF veya DOC(x) dosyası seçin.");
      e.target.value = null;
    }
  };

  const uploadFileHandler = async () => {
    try {
      const soruID = navID.soruID;
      const userID = hukukUser.userID;
      const token = hukukUser && hukukUser.token;
      const formData = new FormData();
      formData.append("file", dosya);
      // Diğer verileri de formData'ya ekleyebilirsiniz, örneğin:
      formData.append("soruID", soruID);
      formData.append("userID", userID);
      formData.append("token", token);
      // uploadSoruImage fonksiyonuna formData'yı doğrudan iletebilirsiniz.
      const res = await uploadSoruImage(formData).unwrap();
      toast.success(res.filePath && "Belge Başarıyla Yüklenmiştir");
      navigate("/app/soru")
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  if (loadingCreate) {
    return <Spinner />;
  }

  return isLoading ? null : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div className="col-12">
      <div className="d-flex flex-row gap-1">
        <button
          className="btn btn-outline-danger col-lg-2 col-md-3 col-sm-4 col-5"
          onClick={() => navigate(-1)}
        >
          <FaBackspace /> Geri Dön
        </button>
        <button
          className="btn btn-outline-success col-lg-2 col-md-3 col-sm-4 col-5"
          onClick={() => setVisible(true)}
          disabled={!navID.soruID}
        >
          <FaCloudUploadAlt /> Soruya İlişkin Belge Yükle
        </button>
      </div>
      <hr />
      <div className="row">
        <div className="mb-2 col-12 d-flex row">
          <div className="col-12 mt-1 d-flex row">
            <div className="col-md-3">
              <label htmlFor="secenek" className="form-label">
                Hukuksal Kategori
              </label>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <select
                className="form-select"
                onChange={handleCategoryChange}
                value={selectedCategory}
              >
                <option value="">Kategori Seçiniz..</option>
                {groupedCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12 mt-1 d-flex row">
            <div className="col-md-3">
              <label htmlFor="secenek" className="form-label">
                Başlık Kategorisi
              </label>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <select
                className="form-select"
                onChange={handleTitleChange}
                value={selectedTitle}
                disabled={!selectedCategory} // Kategori seçilmeden başlık seçilemez
              >
                <option value="">Başlık Seçiniz..</option>
                {selectedCategory &&
                  groupedTitles(selectedCategory).map((title, index) => (
                    <option key={index} value={title}>
                      {title}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-12 mt-1 d-flex row">
            <div className="col-md-3">
              <label htmlFor="secenek" className="form-label">
                Soru Kategorisi
              </label>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <select
                className="form-select"
                onChange={handleDescriptionChange}
                value={selectedDescription}
                disabled={!selectedTitle} // Başlık seçilmeden açıklama seçilemez
              >
                <option value="">Açıklama Seçiniz..</option>
                {selectedTitle &&
                  fiyatTable
                    .filter((item) => item.baslik === selectedTitle)
                    .map((item, index) => (
                      <option key={index} value={item.aciklama}>
                        {item.aciklama}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div className="col-12 mt-1 d-flex row">
            <div className="col-md-3">
              <label htmlFor="secenek" className="form-label">
                Danışma Tutarı
              </label>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              {selectedPrice && (
                <div>
                  <strong className="sari">
                    {selectedPrice.toLocaleString("tr-TR")}
                  </strong>{" "}
                  {" TL"}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 mt-1 d-flex row">
            <div className="col-md-3">
              <label htmlFor="baslik" className="form-label">
                Konu Başlığı
              </label>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <input
                type="text"
                className="form-control"
                id="baslik"
                name="baslik"
                value={baslik}
                onChange={(e) =>
                  setSoruData((prevState) => ({
                    ...prevState,
                    baslik: e.target.value,
                  }))
                }
                placeholder="Konu Başlığı..."
              />
            </div>
          </div>
          <div className="col-12 mt-1 d-flex justify-content-between">
            <div className="col-md-3 justify-content-between d-flex">
              <label
                htmlFor="detayliAciklama"
                className="form-label text-orange"
              >
                <strong>Açıklayınız..</strong>
              </label>
            </div>
            <div className="col-md-9">
              <div className="text-right kalan_karakter">
                Karakter Sayısı:{" "}
                <span className="kirmizi">
                  <strong>{20000 - kalanChar.length}</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 mt-1 d-flex">
            <textarea
              className="form-control"
              id="detayliAciklama"
              name="detay"
              value={detay}
              onChange={onChange}
              placeholder="Detaylı Açıklama..."
              style={{
                minHeight: "200px",
                maxHeight: "340px",
                resize: "none",
              }}
            />
          </div>
        </div>
        <button
          className="mt-2 btn btn-success col-lg-12 col-md-12 col-11"
          onClick={() => soruyuKaydet()}
          disabled={kayitOk}
        >
          <FaSave /> Soruyu Kaydet
        </button>
      </div>
      <Dialog
        header="Sorularınıza Ek Olarak Evrak Yükleme"
        headerStyle={{ textAlign: "center" }}
        visible={visible}
        style={{ width: `${ekran ? "90vw" : "50vw"}`, marginLeft: "10px" }}
        onHide={() => setVisible(false)}
      >
        <p className="m-5 evrak_yukleme_modul">
          <div className="uploads">
            <label htmlFor="yukleme">
              Evrak Seçiniz (*.PNG, *.JPG, *.PDF, *.DOC(x))
            </label>
            <input
              className="form-control"
              type="file"
              name="resimURL"
              id="resimURL"
              variant="outlined"
              onChange={(e) => handleFileChange(e)}
              accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />

            <button className="btn btn-outline-primary mt-2 btn-block"
            onClick={() => uploadFileHandler()}>
              <FaCloudUploadAlt /> Gönder
            </button>
            <div className="resim_gosterme">
              {file && (
                <section
                  className="dosya-gosterim"
                  style={{ border: "1px solid #ccc", marginTop: "5px" }}
                >
                  {file.type === "application/pdf" ? (
                    <iframe
                      src={file}
                      title="PDF önizleme"
                      style={{ width: "100%", height: "500px" }}
                    />
                  ) : file.type === "application/msword" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <embed
                      src={file}
                      type="application/msword"
                      style={{ width: "100%", height: "500px" }}
                    />
                  ) : (
                    <img
                      src={file}
                      alt={file.name}
                      style={{
                        height: "250px",
                        width: `${ekran ? "290px" : "400px"}`,
                        objectFit: "contain",
                      }}
                    />
                  )}
                </section>
              )}
            </div>
          </div>
        </p>
      </Dialog>
    </div>
  );
};

export default YeniSoruPage;
// style={{ width: `${ekran ? "90vw" : "50vw"}`, marginLeft: "10px" }}