import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useGetFiyatsQuery } from "../../redux/web/fiyatSlice";
import Message from "../../web/Message";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { truncateWord } from "../Utility";
import { FaQuestion } from "react-icons/fa";
import Spinner from "../../web/Spinner";
import { useSelector } from "react-redux";

const TarifePage = () => {
  const location = useLocation();
  const {hukukUser} = useSelector(state => state.sacomAuth)
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(15);
  const { data: fiyatTable, isLoading, error } = useGetFiyatsQuery();
  const [aramaText, setAramaText] = useState("");
  const [visible, setVisible] = useState(false);
  const [tarife, setTarife] = useState({});

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const aramaYap = (e) => {
    setAramaText(e.target.value);
  };

  const showAciklama = (fiyat) => {
    setTarife(fiyat)
    setVisible(true)
  };

  return error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div className="sorular">
      {isLoading && <Spinner />}
      <hr />
      {hukukUser && hukukUser.token ? (
        <>
        <div className="mb-2 col-11 col-lg-5 col-md-6 d-flex flex-row gap-1">
        <input
          type="search"
          className="form-control"
          placeholder="Arama..."
          onChange={aramaYap}
          value={aramaText}
        />
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={() => aramaYap()}
        >
          <FaQuestion />
        </button>
      </div>
      <div className="table-responsive" style={{ width: "98%" }}>
        <table className="table">
          <thead className="table-dark">
            <tr>
              {location.pathname === "/" ? null : (
                <th className="mobil_hide" style={{ width: "5%" }}>
                  ID
                </th>
              )}
              <th className="mobil_hide" style={{ width: "10%" }}>
                Kategori
              </th>
              <th className="mobil_hide" style={{ width: "25%" }}>
                Başlık
              </th>
              <th style={{ width: "40%" }}>Detay</th>
              <th style={{ width: "10%" }}>Fiyatı</th>
            </tr>
          </thead>
          <tbody>
            {fiyatTable &&
              fiyatTable.length > 0 &&
              fiyatTable
                .filter((fiyat) =>
                  fiyat.aciklama.toLowerCase().includes(aramaText.toLowerCase())
                )
                .slice(first, first + rows)
                .map((fiyat, index) => (
                  <tr key={index}>
                    {location.pathname === "/" ? null : (
                      <td className="mobil_hide">{index}</td>
                    )}
                    <td className="mobil_hide">{fiyat.kategoriAdi}</td>
                    <td className="mobil_hide">
                      {truncateWord(fiyat.baslik, 30)}
                    </td>
                    <td
                      title={fiyat.aciklama}
                      className="cursor-pointer"
                      onClick={() => showAciklama(fiyat)}
                    >
                      {truncateWord(fiyat.aciklama, 70)}
                    </td>
                    <td>
                      {Number(fiyat.fiyat).toLocaleString("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                      })}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        <div>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={fiyatTable && fiyatTable.length}
            onPageChange={onPageChange}
          />
        </div>
      </div>
        </>
      ) : (
        <div style={{ height: "300px"}}>
        <h5 className="text-center mb-5" style={{ marginTop: "10%"}}>Ücret Tarifesini Görmek İçin Sitemize Üye Olmanız Gerekmektedir.</h5>
        <div className="text-center">
        <Link className="text-white mt-5" to="/login">Üye Olmak İçin Tıklayınız..</Link>
        </div>
        </div>
      ) }
      
      
      <Dialog
        header="Açıklama"
        visible={visible}
        style={{ width: `${window.innerWidth < 991 ? "90vw" : "50vw"}`}}
        headerStyle={{ padding: "10px", textAlign: "center"}}
        onHide={() => setVisible(false)}
      >
        <section className="m-2">
              <div className="d-flex flex-column gap-1">
                <label htmlFor="kategori">Kategori  : <span><strong>{tarife.kategoriAdi}</strong></span></label>
                  <label htmlFor="baslik">Başlık      : {tarife.baslik}</label>
                <label htmlFor="aciklama">Açıklama : <span><strong>{tarife.aciklama}</strong></span></label>
              </div>
        </section>
      </Dialog>
    </div>
  );
};

export default TarifePage;
