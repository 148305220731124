import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sideBarClose, themeChange } from "../redux/auth/authSlice";
import Doviz from "./Doviz";
import { useGetDovizsQuery } from "../redux/web/dovizSlice";

const NavBar = () => {
  const dispatch = useDispatch();
  const { barClose, theme, hukukUser } = useSelector((state) => state.sacomAuth);
  const dovizDeger = 0;
  const { data: dovizTable } = useGetDovizsQuery();

  return (
    <nav>
      <i
        className="bx bx-menu"
        style={{ fontSize: "24px" }}
        onClick={() => dispatch(sideBarClose(!barClose))}
      ></i>
      {hukukUser && hukukUser.userCredential && (
        <span className="kullanici_adi col-lg-2 col-3">{hukukUser.userCredential}</span>
      )}
      <form> </form>
      <input
        type="checkbox"
        id="theme-toggle"
        hidden
        value={theme}
        onChange={() => dispatch(themeChange(!theme))}
      />
      <label htmlFor="theme-toggle" className="theme-toggle"></label>
      {/* <Link to="#" className="notif">
        <i className="bx bx-bell"></i>
        <span className="count">12</span>
      </Link> */}
      <span
        className="text-center col-lg-1 col-md-2 col-2 doviz_kuru"
      >
        $ 
        <span style={{ color: "red", fontWeight: "700" }}>
          {dovizTable && dovizTable.alisKuru
            ? parseFloat(dovizTable.alisKuru.toLocaleString("tr-TR")).toFixed(3)
            : parseFloat(dovizDeger).toFixed(3)}
        </span>
        <div style={{ zIndex: 9999, marginTop: "-25px" }}>
          <Doviz />
        </div>
      </span>
    </nav>
  );
};

export default NavBar;
