import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import hukukLogo from "../../assets/logo.webp";
import { useDispatch, useSelector } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import { logout, reset, sayfaChange, sideBarClose } from "../../redux/auth/authSlice";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { barClose } = useSelector((state) => state.sacomAuth);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(sideBarClose(true));
      } else {
        dispatch(sideBarClose(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
 
  const cikisYap = () => {
    dispatch(reset())
    dispatch(logout())
    navigate("/")
  }

  return (
    <div className={`sidebar ${barClose ? "close" : ""}`}>
      <Link to="/app">
        <div className="logo">
          <img className="buro_logo" src={hukukLogo} alt="" />
        </div>
      </Link>
      <ul className="side-menu">
        <li
          className={selectedMenuItem === 0 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Gösterge"))}
        >
          <Link to="adash" onClick={() => handleMenuItemClick(0)}>
            <i className="bx bxs-dashboard"></i>Durum
          </Link>
        </li>
        <li
          className={selectedMenuItem === 1 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Sorular"))}
        >
          <Link to="notans" onClick={() => handleMenuItemClick(1)}>
            <i className="bx bx-store-alt"></i>Sorular
          </Link>
        </li>
        <li
          className={selectedMenuItem === 2 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Gönderilenler"))}
        >
          <Link to="yesans" onClick={() => handleMenuItemClick(2)}>
            <i className="bx bx-analyse"></i>Gönderilenler
          </Link>
        </li>
        <li
          className={selectedMenuItem === 5 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Yönetim Paneli"))}
         >
          <Link to="panel" onClick={() => handleMenuItemClick(5)}>
            <i className="bx bx-cog"></i>Yönetim Paneli
          </Link>
        </li>
        <li
          className={selectedMenuItem === 6 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("PayTR Ayarları"))}
         >
          <Link to="paytrsetup" onClick={() => handleMenuItemClick(6)}>
            <i className="bx bx-credit-card"></i>PAYTR Ayarları
          </Link>
        </li>
        <li
          className={selectedMenuItem === 7 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("PayTR Ödemeleri"))}
         >
          <Link to="paytrlist" onClick={() => handleMenuItemClick(7)}>
            <i className="bx bx-credit-card"></i>PAYTR Ödemeleri
          </Link>
        </li>
        <li
          className={selectedMenuItem === 8 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Hakkımda HTML"))}
         >
          <Link to="aboutsetup" onClick={() => handleMenuItemClick(8)}>
            <i className="bx bx-code"></i>Hakkımda
          </Link>
        </li>
        <li
          className={selectedMenuItem === 9 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Ceza Hukuk HTML"))}
         >
          <Link to="cezasetup" onClick={() => handleMenuItemClick(9)}>
            <i className="bx bx-code"></i>Ceza Hukuku
          </Link>
        </li>
        <li
          className={selectedMenuItem === 10 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Medeni Hukuk HTML"))}
         >
          <Link to="medenisetup" onClick={() => handleMenuItemClick(10)}>
            <i className="bx bx-code"></i>Medeni Hukuk
          </Link>
        </li>
        <li
          className={selectedMenuItem === 11 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Şifre Değiştir"))}
         >
          <Link to="apassword" onClick={() => handleMenuItemClick(11)}>
            <i className="bx bx-key"></i>Şifre Değiştir
          </Link>
        </li>
        <li
          className={selectedMenuItem === 8 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Üyelik Sorusu"))}
        >
          <Link to="uyeliksoru" onClick={() => handleMenuItemClick(8)}>
            <i className="bx bx-message"></i>Üyelik Sorusu
          </Link>
        </li>
        <li
          className={selectedMenuItem === 12 ? "limenu active" : "limenu"}
          onClick={() => dispatch(sayfaChange("Profil"))}
         >
          <Link to="aprofil" onClick={() => handleMenuItemClick(12)}>
            <i className="bx bx-group"></i>Profil
          </Link>
        </li>
      </ul>
      <ul className="side-menu">
        <li>
          <button
            className="btn btn-outline-danger btn-block"
            onClick={() => cikisYap()}
          >
            <FaSignOutAlt /> {barClose ? "" : "Çıkış"}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
