//import React, { useEffect, useState } from "react";
import iyzicoLogo from "../assets/iyzicoode.png";
import visaLogo from "../assets/visacard.png";
import paytrLogo from "../assets/paytr_guvenli_odeme.jpg"
// import { useGetCounterQuery } from "../redux/web/webForSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { counterDataSet } from "../redux/auth/authSlice";

const Counter = () => {
  // const dispatch = useDispatch();
  // const {
  //   data: counterTable,
  //   isLoading,
  //   error,
  //   refecth,
  // } = useGetCounterQuery();
  // const { counterData } = useSelector((state) => state.sacomAuth);

  // useEffect(() => {
  //   if (
  //     counterTable &&
  //     counterTable.toplamSoru &&
  //     (!counterData || !counterData.toplamSoru)
  //   ) {
  //     dispatch(counterDataSet(counterTable));
  //   }
  // }, [counterTable, counterData, dispatch]);

  // const Counter = ({ id, start, end, duration }) => {
  //   const [current, setCurrent] = useState(start);

  //   useEffect(() => {
  //     const range = end - start;
  //     const increment = end > start ? 3 : -3;
  //     const step = Math.abs(Math.floor(duration / range));

  //     const timer = setInterval(() => {
  //       setCurrent((prevCount) => {
  //         const nextCount = prevCount + increment;
  //         if (
  //           (increment > 0 && nextCount >= end) ||
  //           (increment < 0 && nextCount <= end)
  //         ) {
  //           clearInterval(timer);
  //           return end;
  //         }
  //         return nextCount;
  //       });
  //     }, step);

  //     return () => clearInterval(timer);
  //   }, [start, end, duration]);

  //   return <span id={id}>{current}</span>;
  // };

  return (
    <section id="counter">
      <section className="counter-section">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
              <div className="d-flex flex-column gap-2 visa_iyzico_card">
                <div className="d-flex row">
                <img src={visaLogo} alt="" style={{ width: "130px" }} />
                <img src={iyzicoLogo} alt="" style={{ width: "130px" }} />
                </div>
                <img src={paytrLogo} alt="" style={{ width: "260px" }} />
              </div>
            </div>
            {/* <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
              <h2>
                <span><Counter id="count2" start={0} end={counterData.toplamUser} duration={2000} />+</span>
              </h2>
              <p>BAŞVURU SAYISI</p>
            </div>
            <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
              <h2>
                <span><Counter id="count3" start={0} end={counterData.toplamSoru} duration={2000} />+</span>
              </h2>
              <p>SORU SAYISI</p>
            </div>
            <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
              <h2>
                <span><Counter id="count4" start={0} end={counterData.toplamCevap} duration={2000} />+</span>
              </h2>
              <p>CEVAP SAYISI</p>
            </div> */}
            <div className="col-md-9 mb-lg-0 mb-md-0 mb-5">
              <h4 className="mt-4 text-white">
                <span>
                  Hukuki tüm ihtiyaçlarınız için deneyimli ve güvenilir destek
                  "Ankara Hukuk Bürosu"
                </span>
              </h4>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Counter;
