import React, { useState } from "react";
import IletisimPage from "./IletisimPage";
import SloganLogoPage from "./SloganLogoPage";
import SocialPage from "./SocialPage";
import FiyatlandirmaPage from "./FiyatlandirmaPage";
import FiyatlandirmaTable from "./FiyatlandirmaTable";
import { FaQuestion } from "react-icons/fa";

const tabContent = [
  { id: "tab1", component: <IletisimPage />, label: "İletişim" },
  { id: "tab2", component: <SloganLogoPage />, label: "Slogan & Logo Yazısı" },
  { id: "tab4", component: <SocialPage />, label: "Sosyal Medya" },
  { id: "tab7", component: <FiyatlandirmaPage />, label: "Fiyatlandırma" },
];

const AdminPanel = () => {
  const [activeTab, setActiveTab] = useState(tabContent[0].id);
  const [aramaText, setAramaText] = useState("");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const aramaYap = (e) => {
    setAramaText(e.target.value);
  };

  return (
    <div className="home-container hakkimda-page py-1">
        <ul className="nav nav-tabs">
          {tabContent.map((tab) => (
            <li className="nav-item" key={tab.id}>
              <button
                className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>

        <div className="tab-content mt-2">
          {tabContent.map((tab) => (
            <div
              className={`tab-pane ${
                activeTab === tab.id ? "show active" : ""
              }`}
              key={tab.id}
            >
              {tab.component}
              {tab.id === "tab7" && (
                <>
                  <hr />
                  <div className="mb-2 col-12 col-lg-5 col-md-6 d-flex flex-row gap-1">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Arama..."
                      onChange={aramaYap}
                      value={aramaText}
                    />
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={() => aramaYap()}
                    >
                      <FaQuestion />
                    </button>
                  </div>
                  <FiyatlandirmaTable aramaText={aramaText} />
                </>
              )}
            </div>
          ))}
        </div>
    </div>
  );
};

export default AdminPanel;
