import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FaBackspace, FaFilePdf } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSoruByIdQuery } from "../../redux/web/soruSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import buroLogo from "../../assets/logo.webp";

const SoruPrint = () => {
  const pdfRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const soruID = params.id;
  const { webData, hukukUser } = useSelector((state) => state.sacomAuth);
  const { data: editSoru } = useGetSoruByIdQuery(soruID);

  const generatePDF = () => {
    const element = pdfRef.current;

    html2canvas(element)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(
          `${hukukUser && hukukUser.userCredential}-${
            editSoru && editSoru.id
          }-Ankara_Hukuk_Buro_Cevap.pdf`
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="container">
      <div className="d-flex flex-row gap-1">
        <button
          className="btn btn-outline-danger col-lg-2 col-md-3 col-sm-4 col-6"
          onClick={() => navigate(-1)}
        >
          <FaBackspace /> Geri Dön
        </button>
        <button
          className="btn btn-primary col-lg-2 col-md-3 col-sm-4 col-6"
          onClick={generatePDF}
        >
          <FaFilePdf /> PDF Oluştur
        </button>
      </div>

      <hr />
      <div ref={pdfRef} className="print_page">
        <div className="container">
          <div className="pdfpage_govde">
            {/* Bu kısmı PDF'e dönüştürmek istediğiniz HTML içeriğiyle değiştirin */}
            <div className="d-flex flex-row justify-content-between">
              <div className="text_left">
                <img src={buroLogo} alt="" style={{ width: "50px" }} />
              </div>
              <div className="text_right">
                {editSoru &&
                  moment(editSoru.tarih).format("DD.MM.YYYY / hh:mm")}
              </div>
            </div>
            <div className="text-center">
              <h3>Ankara Hukuk Bürosu</h3>
            </div>
            <hr />
            <div className="d-flex flex-row">
              <div className="col-lg-2 col-md-3 col-4">Hukuksal Kategori :</div>
              <div>
                <span>{editSoru && editSoru.kategori}</span>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="col-lg-2 col-md-3 col-4">Alt Başlık :</div>
              <div>
                <span>{editSoru && editSoru.baslik}</span>
              </div>
            </div>
            <hr />
            <strong>
              <u>Cevap :</u>
            </strong>
            <p className="print_body">
              <span className="print_area">{editSoru && editSoru.detay}</span>
            </p>
            {/* Bu kısmı PDF'e dönüştürmek istediğiniz HTML içeriğiyle değiştirin */}
            <hr />
            <div className="text-center">
              <span>
                {webData && webData.iletisim && webData.iletisim.adres} /{" "}
                {webData &&
                  webData.iletisim &&
                  webData.iletisim.ilce + " / " + webData.iletisim.il}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoruPrint;
