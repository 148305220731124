import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { removeUser } from "../redux/auth/authSlice";
import { toast } from "react-toastify";
import { FaUserCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Spinner from "../web/Spinner";
import { useTempMailOnayMutation } from "../redux/auth/userApiSlice";

const TempMailOnay = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const { user } = useSelector((state) => state.liwaAuth);
  let id = params.id;
  const [tempMailOnay, { isLoading: onayLoading }] = useTempMailOnayMutation();

  const tempOnayCheck = async () => {
    try {
      await tempMailOnay(id).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  tempOnayCheck();

  useEffect(() => {
    if (user && user.isActive && user.message) {
      toast.success(user.message);
      let veri = "bos";
      dispatch(removeUser(veri));
    }
  }, [user, navigate, dispatch]);

  return (
    <div className="container mt-2">
        {onayLoading && <Spinner />}
      <div className="flex-col-3-center">
        <FaUserCheck style={{ fontSize: "40px" }} />
        <br />
        <h2 style={{ color: "darkblue" }}>{t("genelPage.tempMailTebrik")}</h2>
        <p>{t("genelPage.mailOnayKarsilamaText")}</p>
        <a href="/">{t("genelPage.giris")}</a>
      </div>
    </div>
  );
};

export default TempMailOnay;
