import React, { useEffect, useState } from "react";
import {
  useGetUyelikSoruByUserIdQuery,
  useGetUyelikSorusQuery,
  useUpdateUyelikSoruByUserIdMutation,
} from "../redux/web/uyelikSoruSlice";
import Spinner from "../web/Spinner";
import { useSelector } from "react-redux";
import { FaPlus, FaSave, FaSyncAlt } from "react-icons/fa";
import { toast } from "react-toastify";

const UyelikSoruPage = () => {
  const { hukukUser } = useSelector((state) => state.sacomAuth);
  const userID = hukukUser && hukukUser.userID;
  const { data: soruTable, isLoading } = useGetUyelikSorusQuery();
  const { data: editUser, isLoading: userLoading, refetch } =
    useGetUyelikSoruByUserIdQuery(userID);
  const [
    updateUyelikSoruByUserId,
    { data: guncelTable, isLoading: guncelLoading },
  ] = useUpdateUyelikSoruByUserIdMutation();

  const [show, setShow] = useState(false);
  const [uyelikSorusu, setUyelikSorusu] = useState("");
  const [cevap, setCevap] = useState("");
  const [mesajVer, setMesajVer] = useState(false);
  const [newOne, setNewOne] = useState(false);

  const soruGuncelle = async () => {
    try {
      let data = { userID, uyelikSorusu, cevap };
      await updateUyelikSoruByUserId(data).unwrap();
      toast.success("Soru ve Cevabınız Güncelleniyor", { autoClose: 5000 });
      setMesajVer(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (guncelTable && mesajVer) {
      if(guncelTable && guncelTable.isUpdated === "1") {
        toast.success("Gizli Sorunuz ve Cevabınız Başarıyla Güncellenmiştir")
        setShow(!show)
        setTimeout(2000)
        refetch()
        setCevap("")
        setUyelikSorusu("")
      }
    }
  }, [guncelTable, mesajVer, show, refetch]);

  return (
    <div className="uyelik-info">
      {(isLoading || userLoading || guncelLoading) && <Spinner />}
      <div className="d-flex flex-row">
        <label htmlFor="soru">Üyelik Sorunuz : </label>
        <span>
          <strong>{editUser && editUser.uyelikSorusu}</strong>
        </span>
      </div>
      <div className="d-flex flex-row gap-2">
      <button style={{ display: `${editUser && !editUser.uyelikSorusu ? "none" : ""}`}}
        className="btn btn-outline-danger mt-2 col-lg-2 col-md-2 col-6"
        onClick={() => setShow(!show)}
      >
        {!show ? "Değiştir" : "Vazgeç"}
      </button>
      {editUser && !editUser.uyelikSorusu && (
        <button className="btn btn-outline-success mt-2 col-lg-2 col-md-2 col-6" onClick={() => setNewOne(true)}>
       <FaPlus /> Oluştur
        </button>
      )}
      </div>
      {show && (
        <div className="change-soru mt-5 d-flex flex-column col-lg-4 col-md-4 col-12">
          <div className="gizli-soru d-flex flex-column">
            <span>
              <strong>Üyelik Soru Listesi</strong>
            </span>
            <div className="custom-select col-lg-12 mb-2">
              {soruTable && soruTable.length > 0 && (
                <select
                  className="form-control"
                  onChange={(e) => setUyelikSorusu(e.target.value)}
                >
                  <option
                    className="register-input"
                    value="em"
                    style={{ fontWeight: "600" }}
                    required
                  >
                    Gizli Sorunuzu Seçiniz..
                  </option>
                  {soruTable.map((soru, index) => (
                    <option
                      className="register-input"
                      key={index}
                      value={soru.soru}
                      required
                    >
                      {soru.soru}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <input
              className="form-control mb-2"
              type="text"
              name="cevap"
              value={cevap}
              placeholder="Cevabınızı Yazınız"
              onChange={(e) => setCevap(e.target.value)}
            />
          </div>
          <button
            className="btn btn-outline-success"
            onClick={() => soruGuncelle()}
          >
            <FaSyncAlt /> Güncelle
          </button>
        </div>
      )}
      {newOne && (
        <div className="change-soru mt-5 d-flex flex-column col-lg-4 col-md-4 col-12">
          <div className="gizli-soru d-flex flex-column">
            <span>
              <strong>Üyelik Soru Listesi</strong>
            </span>
            <div className="custom-select col-lg-12 mb-2">
              {soruTable && soruTable.length > 0 && (
                <select
                  className="form-control"
                  onChange={(e) => setUyelikSorusu(e.target.value)}
                >
                  <option
                    className="register-input"
                    value="em"
                    style={{ fontWeight: "600" }}
                    required
                  >
                    Gizli Sorunuzu Seçiniz..
                  </option>
                  {soruTable.map((soru, index) => (
                    <option
                      className="register-input"
                      key={index}
                      value={soru.soru}
                      required
                    >
                      {soru.soru}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <input
              className="form-control mb-2"
              type="text"
              name="cevap"
              value={cevap}
              placeholder="Cevabınızı Yazınız"
              onChange={(e) => setCevap(e.target.value)}
            />
          </div>
          <button
            className="btn btn-outline-success"
            onClick={() => soruGuncelle()}
          >
            <FaSave /> Yeni Oluştur
          </button>
        </div>
      )}
    </div>
  );
};

export default UyelikSoruPage;
