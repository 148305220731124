import React from "react";
import {
  useGetCevabiGidenlerQuery,
  useGetCevabiGonderilmeyenlerQuery,
  useGetCevapsizSorularQuery,
  useYirmiDortSaatiKalanlarQuery,
} from "../../redux/web/istatistikSlice";
import Spinner from "../../web/Spinner";
import { useDispatch } from "react-redux";
import { aktifPageChange } from "../../redux/auth/authSlice";

const Reminder = ({ veriTable }) => {
  const dispatch = useDispatch();
  const { data: yirmiDortTable, isLoading: yirmiDortLoading } =
    useYirmiDortSaatiKalanlarQuery();
  const { data: cevapsizTable, isLoading: cevapsizLoading } =
    useGetCevapsizSorularQuery();
  const { data: cevapUnSetTable, isLoading: cevapUnSetLoading } =
    useGetCevabiGonderilmeyenlerQuery();
  const { data: cevapUnSetsTable, isLoading: cevapSetsLoading } =
    useGetCevabiGidenlerQuery();

    return (
    <div className="reminders">
      {(yirmiDortLoading ||
        cevapsizLoading ||
        cevapUnSetLoading ||
        cevapSetsLoading) && <Spinner />}
      <div className="header">
        <i className="bx bx-note"></i>
        <h3>Hatırlatma</h3>
        <i className="bx bx-x" onClick={() => dispatch(aktifPageChange(""))}></i>
      </div>
      <ul className="task-list">
        <li
          onClick={() => dispatch(aktifPageChange({ sayfaAdi: "AcilCevapPage", table: yirmiDortTable}))}
          className={`${
            yirmiDortTable && yirmiDortTable.length > 0
              ? "not-completed"
              : "completed"
          }`}
        >
          <div className="task-title">
            <i className="bx bx-x-circle"></i>
            <span>Acil Cevap Verilmesi Gerekenler</span>
            <strong>
              {" ( "}
              {yirmiDortTable &&
                yirmiDortTable.length > 0 &&
                yirmiDortTable.length}
              {" ) "}
            </strong>
          </div>
          <i className="bx bx-dots-vertical-rounded"></i>
        </li>
        <li
          onClick={() => dispatch(aktifPageChange({ sayfaAdi: "CevapsizPage", table: cevapsizTable}))}
          className={`${
            cevapsizTable && cevapsizTable.length > 0
              ? "not-completed"
              : "completed"
          }`}
        >
          <div className="task-title">
            <i className="bx bx-x-circle"></i>
            <span>Cevaplanmayan Sorular</span>
            <strong>
              {" ( "}
              {cevapsizTable &&
                cevapsizTable.length > 0 &&
                cevapsizTable.length}
              {" ) "}
            </strong>
          </div>
          <i className="bx bx-dots-vertical-rounded"></i>
        </li>
        <li
          onClick={() => dispatch(aktifPageChange({ sayfaAdi: "CevapUnSentPage", table: cevapUnSetTable}))}
          className={`${
            cevapUnSetTable && cevapUnSetTable.length > 0
              ? "not-completed"
              : "completed"
          }`}
        >
          <div className="task-title">
            <i className="bx bx-check-circle"></i>
            <span>Cevaplanan Ama Gönderilmeyenler</span>
            <strong>
              {" ( "}
              {cevapUnSetTable &&
                cevapUnSetTable.length > 0 &&
                cevapUnSetTable.length}
              {" ) "}
            </strong>
          </div>
          <i className="bx bx-dots-vertical-rounded"></i>
        </li>
        <li
          onClick={() => dispatch(aktifPageChange({ sayfaAdi: "CevapSentPage", table: cevapUnSetsTable}))}
          className="completed"
        >
          <div className="task-title">
            <i className="bx bx-check-circle"></i>
            <span>Cevap Gönderilenler</span>
            <strong>
              {" ( "}
              {cevapUnSetsTable &&
                cevapUnSetsTable.length > 0 &&
                cevapUnSetsTable.length}
              {" ) "}
            </strong>
          </div>
          <i className="bx bx-dots-vertical-rounded"></i>
        </li>
        <li
          className={`${
            veriTable && !veriTable.totalTutar > 0
              ? "not-completed"
              : "completed"
          }`}
        >
          <div className="task-title">
            <i className="bx bx-check-circle"></i>
            <span>Gelirler Toplamı</span>
            <strong>
              {" ( "}
              {veriTable && veriTable.totalTutar ? veriTable.totalTutar : 0}
              {" ) "}
            </strong>
          </div>
          <i className="bx bx-dots-vertical-rounded"></i>
        </li>
        {/* <li
          onClick={() => dispatch(aktifPageChange({ sayfaAdi: "GiderCevapPage", table: veriTable}))}
          className={`${danisanCevapClass}`}
        >
          <div className="task-title">
            <i className="bx bx-check-circle"></i>
            <span>Danışana Ulaşan Cevap</span>
            <strong>
              {" ( "}
              {veriTable && veriTable.aylikDeliveredOK}
              {" ) "}
            </strong>
          </div>
          <i className="bx bx-dots-vertical-rounded"></i>
        </li> */}
      </ul>
    </div>
  );
};

export default Reminder;
