import React from "react";
import Spinner from "../../web/Spinner";
import moment from "moment";
import { truncateWord } from "../Utility";
import { useSelector } from "react-redux";
import { useGetOdemelerimByUserIDQuery } from "../../redux/web/soruSlice";

const Odemelerim = () => {
const {hukukUser} = useSelector(state => state.sacomAuth)
let userID = hukukUser.userID
const {data: odemeTable, isLoading} = useGetOdemelerimByUserIDQuery(userID)

  return (
    <div className="bottom-data">
      <div className="orders">
        {isLoading && <Spinner />}
        <div className="header">
          <i className="bx bx-receipt"></i>
          <h3>Ödemelerim</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>Tarih</th>
              <th>Tutar</th>
              <th>Kategori</th>
              <th>Başlık</th>
            </tr>
          </thead>
          <tbody>
            {odemeTable &&
              odemeTable.length > 0 &&
              odemeTable.map((odeme, index) => {
                return (
                  <tr key={index}>
                    <td>{moment(odeme.tarih).format("DD.MM.YYYY")}</td>
                    <td>{Number(odeme.tutar).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY"
                    })}</td>
                    <td>{odeme.kategori}</td>
                    <td title={odeme.baslik}>
                    {truncateWord(odeme.baslik, 50)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Odemelerim;
